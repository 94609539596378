<div class="container-fluid h-100 d-flex flex-column">
    <div class="row justify-content-center px-4">
        <form class="login-form d-flex align-items-center justify-content-center my-4" (ngSubmit)="login(loginForm)"
            (keydown.enter)="login(loginForm)"
            [formGroup]="loginForm" novalidate>
            <mat-card class="col-12 col-md-8 col-lg-6 col-xl-5 p-5">
                <mat-card-content class="d-flex flex-column">
                    <h2 class="mb-5">TotalEnergies</h2>

                    <ng-container *ngIf="(getOAuth2Providers() | async).length">
                        <!-- <hr class="mb-2 mt-3" />
                <a *ngFor="let provider of getOAuth2Providers() | async" (click)="loginWithOAuth2(provider.url)"
                class="d-flex align-items-center justify-content-center ff-link">
                <mat-icon>arrow_right</mat-icon>
                Connect via {{provider.label}}
                </a> -->
                        <button mat-raised-button color="primary" *ngFor="let provider of getOAuth2Providers() | async"
                            (click)="loginWithOAuth2(provider.url)" class="d-flex mx-auto px-3 py-1 dpbutton">
                            Connect via {{provider.label}}
                        </button>
                        <div class="container">
                            <div class="line">
                                <mat-divider></mat-divider>
                            </div>
                            <div class="text mat-typography">Or</div>
                            <div class="line">
                                <mat-divider></mat-divider>
                            </div>
                        </div>
                        <!-- <hr class="mb-2 mt-3" /> -->
                    </ng-container>
                    <mat-form-field class="mb-3" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" formControlName="login" placeholder="Email" required />
                        <div class="icon-override size-24 user-icon" matSuffix style="margin-right: 11px;"></div>
                    </mat-form-field>
                    <mat-form-field class="mb-3" appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password" placeholder="Password" required />
                        <div (mousedown)="displayPassword()" (mouseup)="displayPassword()" class="icon-override size-24 eye-icon pointer" matSuffix style="margin-right: 11px;"></div>
                    </mat-form-field>
                    <div class="d-flex flex-row">
                        <button mat-raised-button color="primary"
                            (click)="login(loginForm)" 
                            class="login-button d-flex align-items-center pointer mb-4"
                        >
                            Connect
                        </button>
                        
                        <p class="forgotten-password ml-auto d-flex align-items-center pointer mb-4"
                            (click)="showResetPasswordLinkDialog()">
                            <mat-icon>arrow_right</mat-icon>
                            Forgotten password?
                        </p>
                    </div>                

                    <p class="mt-5 text-center">
                        <span class="cgu-link">
                            <span>
                                By continuing you agree to our
                                <i class="pointer" (click)="showCGUDialog()"><u>Terms and Conditions and Privacy
                                        Policy</u></i>
                            </span>
                        </span>
                    </p>
                </mat-card-content>
            </mat-card>
        </form>
    </div>
</div>