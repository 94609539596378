import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ProjectService} from '../../../_services/project.service';

@Component({
  selector: 'app-ag-clickable',
  templateUrl: './ag-clickable.component.html',
  styleUrls: ['./ag-clickable.component.scss']
})
export class AgClickableComponent implements OnInit {
  @Input() type: any;
  @Input() projectId;
  @Input() step = 'dev';
  @Input() canEdit;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClicked = new EventEmitter<boolean>();
  @Input() isBookmarked;
  @Input() showFav;

  constructor(
    private _router: Router,
    private _projectService: ProjectService
  ) {

  }

  ngOnInit(): void {

  }

  edit(): void {
    this._router.navigate(['/project/monthly-progress/' + this.projectId + '/edit']);
  }

  synthese() {
   //  console.log('CLIcked');
    this._router.navigate(['/synthese/' + this.step + '/' + this.projectId + '/view']);
  }

  export() {

  }
}
