import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import * as moment from 'moment';
import { isNil, isArray } from 'lodash';
import {getExeDateRendererFromStep} from '../../../project/components/project-list/project-list.component';

@Component({
  selector: 'app-indicator-parent',
  templateUrl: './indicator-parent.component.html',
  styleUrls: ['./indicator-parent.component.scss']
})
export class IndicatorParentComponent implements ICellRendererAngularComp {

  params;
  type;
  step;
  project;
  percentage = null;
  class = '';

  agInit(params: any): void {
    this.params = params;
    this.type = params.colDef.indicatorType;
    this.step = params.colDef.step;
    this.project = params.data;

    if (params.colDef.styleFunction !== undefined) {
      this.class = params.colDef.styleFunction(params);
    }

    if (this.step === 'exe' && this.type === 'cost') {
      this.percentage = this.project.cost_perf;
    }

    if(this.type === 'schedule') {
      this.percentage = this.project.schedule_perf;
    }
  }

  refresh(): boolean {
    return false;
  }

}
