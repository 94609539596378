/**
 * NOT USED Service But it can be used to check Rights for Auth user
 */
import { Injectable } from '@angular/core';
import { UserService } from '@ff/user';
import { User, Role } from 'src/app/modules/model/user';
import { CrudService } from 'src/app/modules/shared/services/crud.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private _user: User;
  private hasRight: boolean;
  constructor(private _userService: UserService, private _crudService: CrudService) {
    this._userService.getUser().subscribe((user: User) => {
      this._user = user;
    });

  }

   isAdmin() {
    return this._user && this._user.role === Role.ADMIN;
   }
   isSPAdmin() {
    return this._user && this._user.role === Role.SPADMIN;
   }
  isMaster() {
    return this._user && this._user.role && (this._user.role === Role.ADMIN || this._user.role === Role.SPADMIN);
  }

  hasRightfor(id) {

     this._crudService.getResource('admin/user/check', id).subscribe((response: any) => {
       this.hasRight = response;
       });
     return this.hasRight;
  }

}
