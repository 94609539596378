<header>
  <div class="d-flex flex-row header-row">
    <img src="assets/new-logo.svg" title="Total" alt="Total" class="logo" />
    <div class="d-flex flex-column w-100" style="margin-left: 40px;">
      <div class="d-flex align-items-center">
        <!-- <mat-icon *ngIf="userService.getUser() | async" class="burger pointer" (click)="nav.toggle()">menu</mat-icon> -->
        <div class="app-name">
          <div class="app-title">Be on Board {{environment.type}}</div>
          <div class="app-subtitle">M&S industrial projects visual management</div>
        </div>
        <ng-container *ngIf="userService.getUser() | async">
          <button [matMenuTriggerFor]="account" class="secondary-btn ml-auto" mat-raised-button (click)="expand()">
            <div class="icon-override user-icon size-20" style="margin-right: 12px"></div>
            <span>{{userService.getUser().getValue().first_name}}</span>
            <div class="icon-override arrow-{{expanded ? 'top' : 'down'}}-icon size-20" style="margin-left: 12px"></div>
          </button>
          <mat-menu class="mt-2" #account="matMenu" [overlapTrigger]="false" (closed)="expand()">
            <button mat-menu-item class="pr-5" routerLink="/user/my-account">{{ 'my_account' | translate }}</button>
            <button mat-menu-item routerLink="/auth/logout">Logout</button>
          </mat-menu>
        </ng-container>
        <div *ngIf="userService.getUser() | async" (click)="nav.toggle()" class="burger-container">
          <mat-icon class="burger menu" [ngClass]="{'active': !nav.opened}">menu</mat-icon>
          <mat-icon class="burger close" [ngClass]="{'active': nav.opened}">close</mat-icon>
        </div>
      </div>
      <div class="nav-bar mt-auto">
        <app-navigation *ngIf="userService.getUser() | async"></app-navigation>
        <!-- <ff-navigation *ngIf="userService.getUser() | async"></!-->
        <div class="create-project mobile" *ngIf="userService.getUser() | async">
          <button *ngIf="userService.getUser().getValue().role != 'User'" mat-raised-button routerLink="/project">
            <div class="icon-override plus-icon size-20" style="margin-right: 12px"></div>
            <span>{{ 'new_project' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</header>
