<div class="content">
    <div class="d-flex flex-wrap align-items-center">
        <div class="hstyle align-self-baseline">{{ project?.title }} |</div>
        <div class="hstylecontent align-self-center">
            {{project?.period | date:'MMM yyyy' | uppercase}}
        </div>
        <app-project-bookmark style="margin-left: 15px;" [fontSize]="35" [projectId]="projectId"></app-project-bookmark>
    </div>
    <div class="d-flex justify-content-end">
       
        <button mat-raised-button class="button-preview ml-auto" (click)="synthese()">
            <mat-icon>arrow_right</mat-icon>
            <span>See Monthly Report</span>
             <mat-icon class="ml-1">remove_red_eye</mat-icon>
        </button>
         <button mat-icon-button (click)="edit()" class="icon-button" *ngIf="project?.canEdit && !isMobile">
            <mat-icon>edit</mat-icon>
        </button>
    </div>
    <!-- <span>{{project.title ? project.title : ''}}</span> -->
    <div class="project-container">
        <mat-card>
            <mat-expansion-panel [expanded]="expandIntro" (opened)="getIntro()">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'project.introduction.title' | translate | uppercase }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-content">
                    <div class="intro-container">
                        <div class="author-date">
                            <div class="data">
                                <div class="label">
                                    {{ 'by' | translate }}
                                </div>
                                <div class="info">
                                    {{ project?.author?.first_name + ' ' + project?.author?.last_name }}
                                </div>
                            </div>
                            <div class="data update-date" style="margin-left: 10px">
                                <div class="label">
                                    {{ 'last_updated' | translate }}
                                </div>
                                <div class="info">
                                    {{ project?.updated_at }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="intro-container mobile">
                        <div class="secondary-title">{{ 'project.introduction.general_data.title' | translate | uppercase }}</div>
                        <div class="row-data">
                            <div class="intro-element">
                                <div class="element-label">{{ 'project.introduction.general_data.location' | translate }}</div>
                                <div class="element-data">{{ project?.location }}</div>
                            </div>
                            <div class="intro-element">
                                <div class="element-label">{{ 'project.introduction.general_data.affiliate' | translate }}</div>
                                <div class="element-data">{{ project?.affiliate }}</div>
                            </div>
                        </div>
                        <div class="row-data">
                            <div class="intro-element">
                                <div class="element-label">{{ 'project.introduction.general_data.longitude' | translate }}</div>
                                <div class="element-data">{{ project?.longitude }}</div>
                            </div>
                            <div class="intro-element">
                                <div class="element-label">{{ 'project.introduction.general_data.latitude' | translate }}</div>
                                <div class="element-data">{{ project?.latitude }}</div>
                            </div>
                        </div>
                        <div class="row-data">
                            <div class="intro-element">
                                <div class="element-label">{{ 'project.introduction.general_data.zone' | translate }}</div>
                                <div class="element-data">{{ project?.zone?.label }}</div>
                            </div>
                            <div class="intro-element">
                                <div class="element-label">{{ 'project.introduction.general_data.operational_direction' | translate }}</div>
                                <div class="element-data">{{ project?.operational_direction?.label_en }}</div>
                            </div>
                        </div>
                        <div class="row-data">
                            <div class="intro-element">
                                <div class="element-label">{{ 'project.introduction.general_data.country' | translate }}</div>
                                <div class="element-data">{{ project?.country?.label_en }}</div>
                            </div>
                            <div class="intro-element">
                                <div class="element-label">{{ 'project.introduction.general_data.business_line' | translate }}</div>
                                <div class="element-data">{{ project?.business_line?.label_en }}</div>
                            </div>
                        </div>
                        <div class="row-data">
                            <div class="intro-element">
                                <div class="element-label">{{ 'project.introduction.general_data.jv' | translate }}</div>
                                <div class="element-data">{{ project?.jv ? 'Yes' : 'No' }}</div>
                            </div>
                            <div class="intro-element">
                                <div class="element-label">{{ 'project.introduction.general_data.total_share' | translate }}</div>
                                <div class="element-data">{{ project?.total_share }}</div>
                            </div>
                        </div>
                        <div class="row-data">
                            <div class="intro-element">
                                <div class="element-label">{{ 'project.introduction.general_data.jv_partners' | translate }}</div>
                                <div class="element-data">{{ project?.jv_partners }}</div>
                            </div>
                            <div class="intro-element">
                                <div class="element-label">{{ 'project.introduction.general_data.total_role' | translate }}</div>
                                <div class="element-data">{{ project?.total_role }}</div>
                            </div>
                        </div>
                         <div class="row-data">
                              <div class="intro-element">
                                  <div class="element-label">
                                     Project number (Design office)</div>
                                  <div class="element-data">{{ project?.projectnumber_designoffice }}</div>
                              </div>
                         </div>
                    </div>
                    <div class="intro-container mobile">
                        <div class="secondary-title">{{ 'project.introduction.contacts.title' | translate | uppercase}}</div>
                        <div class="row-data" *ngFor="let contactRow of contacts">
                            <div class="intro-element">
                                <div class="element-label">{{'project.introduction.contacts.' + contactRow[0].id | translate}}</div>
                                <div class="element-data">
                                    {{ project && project[contactRow[0].id] ? (project[contactRow[0].id].first_name + '  ' + project[contactRow[0].id].last_name) : '' }}
                                </div>
                            </div>
                            <div class="intro-element">
                                <div class="element-label">{{'project.introduction.contacts.' + contactRow[1].id | translate}}</div>
                                <div class="element-data">
                                    {{ project && project[contactRow[1].id] ? (project[contactRow[1].id].first_name + '  ' + project[contactRow[1].id].last_name) : '' }}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </mat-expansion-panel>
        </mat-card>

        <mat-card>
            <mat-expansion-panel [expanded]="expandDesc" (opened)="getDescription()">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'project.description.title' | translate | uppercase }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-content">
                    <div class="intro-container">
                        <div class="row-data">
                            <div class="col-data">
                                <div class="element-label">{{'project.description.short_description' | translate}}</div>
                                <div class="element-data">{{ project?.short_description }}</div>
                            </div>
                        </div>
                        <div class="row-data">
                            <div class="col-data">
                                <div class="element-label">Detailed {{'project.description.label' | translate}}</div>
                                <div class="element-data" [innerHTML]="project?.description"></div>
                            </div>
                        </div>
                        <div *ngIf="links" class="row-data">
                            <div class="col-data">
                                <div class="element-label">Links</div>
                                <li *ngFor="let link of links">
                                    <a target="_blank" style="color: blue ; font-weight: normal; text-decoration: underline;" href="{{link}}">{{link}}</a>
                                </li>
                            </div>
                        </div>
                    </div>
                    <app-pictures-bloc
                        [allowedTypes]="documentInfos[1].allowedMimeTypes" [helpText]="documentInfos[1].helpText"
                        [acceptedFiles]="documentInfos[1].acceptedFiles" [uploadedFiles]="project?.pictures"
                        [limit]="3" [canUpload]="false"
                    >
                    </app-pictures-bloc>
                    <div class="intro-container">
                        <div class="secondary-title">{{ 'project.description.contractors.title' | translate | uppercase }}</div>
                        <div class="row-data mobile">
                            <div class="col-data">
                                <div class="tertiary-title">{{ 'project.description.contractors.strategy' | translate }}</div>
                                <div class="element-label">{{'project.description.label' | translate}}</div>
                                <div class="element-data">{{ project?.contractual_strategy }}</div>
                            </div>
                        </div>
                        <div class="row-data mobile">
                            <div class="col-data">
                                <div class="tertiary-title">{{ 'project.description.contractors.engineering' | translate }}</div>
                                <div class="row-data">
                                    <div class="intro-element" style="margin-right: 7px;">
                                        <div class="element-label">{{ 'project.description.contractors.tendering' | translate }}</div>
                                        <div class="element-data">{{ project?.eng_contract_tendering }}</div>
                                    </div>
                                    <div class="intro-element" style="margin-left: 7px">
                                        <div class="element-label">{{ 'project.description.contractors.awarded' | translate }}</div>
                                        <div class="element-data">{{ project?.eng_contract_awarded }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row-data mobile">
                            <div class="col-data">
                                <div class="tertiary-title">{{ 'project.description.contractors.execution' | translate }}</div>
                                <div class="row-data">
                                    <div class="intro-element" style="margin-right: 7px;">
                                        <div class="element-label">{{ 'project.description.contractors.tendering' | translate }}</div>
                                        <div class="element-data">{{ project?.exe_contract_tendering }}</div>
                                    </div>
                                    <div class="intro-element" style="margin-left: 7px;">
                                        <div class="element-label">{{ 'project.description.contractors.awarded' | translate }}</div>
                                        <div class="element-data">{{ project?.exe_contract_awarded }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row-data mobile">
                            <div class="col-data">
                                <div class="tertiary-title">{{ 'project.description.contractors.key' | translate }}</div>
                                <div class="element-label">{{'project.description.label' | translate}}</div>
                                <div class="element-data">{{ project?.key_subcontract }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-card>
        <mat-card>
            <mat-expansion-panel [expanded]="expandGallery" (opened)="getGallery()">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'project.gallery_documents.title' | translate | uppercase }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div style="padding: 0 20px;">
                    <mat-card *ngIf="uploadedFiles['activities'] !== false"> <!-- false is sent when project has no MPs-->
                        <mat-card-title style="text-transform: unset !important;"
                                class="sub-card">{{ ("project.gallery_documents.photos_of" | translate) + ' ' + (project?.period | date:'MMMM yyyy')}}</mat-card-title>
                        <mat-card-content>
                            <div style="padding: 0 20px;">
                                <app-pictures-bloc
                                        [uploadedFiles]="currentActivityFiles"
                                        [canUpload]="false"
                                ></app-pictures-bloc>
                            </div>
                            <app-monthly-progresses
                                    [(monthlyProgressesFiles)]="historyActivitiesFiles"
                                    [monthlyIds]="monthlyIds"
                                    [canUpload]="false"
                                    *ngIf="monthlyIds"
                            ></app-monthly-progresses>

                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-expansion-panel [expanded]="false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>{{ ("project.gallery_documents." + documentInfos[2].key) | translate | uppercase}}</mat-panel-title>
                            </mat-expansion-panel-header>
                            <app-documents-bloc
                                    [uploadedFiles]="uploadedFiles[documentInfos[2].key]"
                                    [canUpload]="false"
                            ></app-documents-bloc>
                        </mat-expansion-panel>
                    </mat-card>
                    <!-- DOCUMENTS --> <!-- TODO FOREACH -->
                    <mat-card>
                        <mat-card-title class="sub-card">{{"documents" | translate | uppercase}}</mat-card-title>
                        <mat-expansion-panel [expanded]="false" *ngFor="let i of [3, 4, 5, 6, 7, 8, 9]">
                            <mat-expansion-panel-header>
                                <mat-card-subtitle>{{ ("project.gallery_documents." + documentInfos[i].key) | translate }}
                                </mat-card-subtitle>
                            </mat-expansion-panel-header>
                            <app-documents-bloc
                                    [uploadedFiles]="uploadedFiles[documentInfos[i].key]"
                                    [canUpload]="false"
                            ></app-documents-bloc>
                        </mat-expansion-panel>
                    </mat-card>
                </div>
            </mat-expansion-panel>
        </mat-card>
    </div>
</div>
