import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-percentage-parent',
  templateUrl: './percentage-parent.component.html',
  styleUrls: ['./percentage-parent.component.scss']
})
export class PercentageParentComponent implements ICellRendererAngularComp {

  params;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

}
