import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoaderService } from '@ff/loader';
import { UserService } from '@ff/user';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ProjectService } from '../../_services/project.service';

@Injectable()
export class DatavizProjectListResolver implements Resolve<any> {
    constructor(private _projectService: ProjectService, private _userService: UserService, private _loaderService: LoaderService) {
        this._loaderService.show();
     }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const authUser = this._userService.getUser().getValue();

        if (sessionStorage.getItem('datavizlist_' + authUser?.id) != null) {
            const response = JSON.parse(sessionStorage.getItem('datavizlist_' + authUser?.id));
            this._loaderService.hide();
            return response;
        } else {
             return this._projectService.datavizlist()
            .pipe(
                tap((resp) => {
                    sessionStorage.setItem('datavizlist_' + authUser?.id, JSON.stringify(resp));
                    this._loaderService.hide();
                }),
                map((response: any) => response));
        }

    }
}
