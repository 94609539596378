import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CrudService } from '../../shared/services/crud.service';

@Injectable()
export class CountriesResolver implements Resolve<any> {
    constructor(private _crudService: CrudService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this._crudService.getAll('countries')
            .pipe(map((response: any) => response));
    }
}
