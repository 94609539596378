<mat-expansion-panel [expanded]="false" *ngFor="let monthData of monthsData; index as month" (opened)="getMonthlyDocuments(month, monthData)">
    <mat-expansion-panel-header>
        <mat-panel-title>{{ ("project.gallery_documents.photos_of" | translate) + ' ' + monthData.period }}</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="uploaded-photos" *ngIf="monthData.files && monthData.files.length > 0">
        <div class="photo" *ngFor="let picture of monthData.files; index as i">
            <div class="img">
                <img (click)="viewFile(picture.path)" src="{{picture.path}}" class="img-activity" style="cursor: pointer;"/>
                <button class="delete-picture" mat-icon-button (click)="removeAttachment(month, i)" *ngIf="canUpload">
                    <mat-icon mat-list-icon>delete</mat-icon>
                </button>
            </div>
            <div class="label-photo" *ngIf="!canUpload">{{picture.title}}</div>
        </div>
    </div>
</mat-expansion-panel>
