import {Component, OnInit, ViewChild} from '@angular/core';
import {LoaderService} from '@ff/loader';
import {ProjectService} from '../../../_services/project.service';
import {isEmpty} from 'lodash';
import {compare, getContactFromField, loaderHide, loaderShow} from '../../../shared/models/project';
import {MatSort, Sort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-project-contacts',
  templateUrl: './project-contacts.component.html',
  styleUrls: ['./project-contacts.component.scss']
})
export class ProjectContactsComponent {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'project_name', 'zone', 'country', 'business_line', 'architect',
    'manager', 'fp_affiliate', 'fp_slp_tp', 'fp_business_line',
    'fp_hse', 'fp_zone', 'fp_purchasing'
  ];

  defaultColDef;

  projects = [];
  filteredProjects = [];
  projectsLoaded = false;

  constructor(
      private _loaderService: LoaderService,
      private _projectService: ProjectService
  ) {
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true,
      autoHeight: true,
      skipHeaderOnResize: true
    };

    if (!this.projectsLoaded) {
      loaderShow(this._loaderService);
      this._projectService.list().subscribe(
          (projects: { dev: [], exe: [] }) => {
            loaderHide(this._loaderService);
            if (!isEmpty(projects.dev)) {
              this.filteredProjects.push(...projects.dev);
              this.projects.push(...projects.dev);
            }
            if (!isEmpty(projects.exe)) {
              this.filteredProjects.push(...projects.exe);
              this.projects.push(...projects.exe);
            }
            this.projectsLoaded = true;
            this.dataSource = new MatTableDataSource(this.filteredProjects);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          }
      );
    }
  }

  filterProjects(projects) {
    this.dataSource.data = projects;
  }

  getZone(zone) {
    return zone.split('-', 1)[0];
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'project_name': return compare(a.title, b.title, isAsc);
        case 'zone': return compare(a.zone.label, b.zone.label, isAsc);
        case 'country': return compare(a.country.label_en, b.country.label_en, isAsc);
        case 'business_line': return compare(a.businessLine.label_en, b.businessLine.label_en, isAsc);
        case 'manager': return compare(a.manager?.first_name , b.manager?.first_name, isAsc);
        case 'fp_affiliate': return compare(a.fp_affiliate?.first_name , b.fp_affiliate?.first_name, isAsc);
        case 'fp_slp_tp': return compare(a.fp_slp_tp?.first_name , b.fp_slp_tp?.first_name, isAsc);
        case 'fp_business_line': return compare(a.fp_business_line?.first_name , b.fp_business_line?.first_name, isAsc);
        case 'fp_zone': return compare(a.fp_zone?.first_name , b.fp_zone?.first_name, isAsc);
        case 'fp_purchasing': return compare(a.fp_purchasing?.first_name , b.fp_purchasing?.first_name, isAsc);
        case 'fp_hse': return compare(a.fp_hse?.first_name , b.fp_hse?.first_name, isAsc);
        case 'architect': return compare(a.architect?.first_name , b.architect?.first_name, isAsc);
        default: return 0;
      }
    });
  }

  getArchitect(params) {
    return getContactFromField(params, 'architect');
  }

  getManager(params) {
    return getContactFromField(params, 'manager');
  }

  getFpAffiliate(params) {
    return getContactFromField(params, 'fp_affiliate');
  }

  getFpSlpTp(params) {
    return getContactFromField(params, 'fp_slp_tp');
  }

  getFpBusinessLine(params) {
    return getContactFromField(params, 'fp_business_line');
  }
}
