export enum DocumentTypes {
    ACTIVITIES = 'Activities',
    DESCRIPTION = 'Description',
    SCHEDULE = 'Schedule',
    SOR = 'SOR',
    PR1 = 'PR1',
    PR2 = 'PR2',
    FID = 'FID',
    STEERING_COMMITTEES = 'Steering committees',
    REX = 'REX',
    OTHER = 'Other',
}

export const DOCUMENTS_INFOS = [
    {
        type: DocumentTypes.ACTIVITIES,
        allowedMimeTypes: ['89504e47', 'ffd8ffdb', 'ffd8ffee', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'],
        acceptedFiles: 'image/*',
        helpText: 'Supported documents : JPEG, JPG, PNG',
        key: 'activities',
        maxSize: 0,
        max: 3
    },
    {
        type: DocumentTypes.DESCRIPTION,
        allowedMimeTypes: ['89504e47', 'ffd8ffdb', 'ffd8ffee', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'],
        acceptedFiles: 'image/*',
        helpText: 'Supported documents : JPEG, JPG, PNG',
        key: 'description',
        maxSize: 0,
        max: 5
    },
    {
        type: DocumentTypes.SCHEDULE,
        allowedMimeTypes: ['25504446', '89504e47', 'ffd8ffdb', 'ffd8ffee', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'],
        acceptedFiles: 'application/pdf,image/*',
        helpText: 'Supported documents : PDF,JPEG, JPG, PNG',
        key: 'project_schedule',
        maxSize: 0
    },
    {
        type: DocumentTypes.SOR,
        allowedMimeTypes: ['25504446', 'd0cf11e0', '504B0304', '504B0506',
            '504B0708', '504b34', '89504e47', 'ffd8ffdb', 'ffd8ffee', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'],
        acceptedFiles: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
            'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/*',
        helpText: 'Supported documents : PPT, PPTX, DOCX, PDF, JPEG, JPG, PNG - Limited size 20Mo',
        key: 'sor',
        maxSize: 20000000
    },
    {
        type: DocumentTypes.PR1,
        allowedMimeTypes: ['25504446', 'd0cf11e0', '504B0304', '504B0506',
            '504B0708', '504b34', '89504e47', 'ffd8ffdb', 'ffd8ffee', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'],
        acceptedFiles: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
            'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/*',
        helpText: 'Supported documents : PPT, PPTX, DOCX, PDF, JPEG, JPG, PNG - Limited size 20Mo',
        key: 'pr1',
        maxSize: 20000000
    },
    {
        type: DocumentTypes.PR2,
        allowedMimeTypes: ['25504446', 'd0cf11e0', '504B0304', '504B0506',
            '504B0708', '504b34', '89504e47', 'ffd8ffdb', 'ffd8ffee', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'],
        acceptedFiles: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
            'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/*',
        helpText: 'Supported documents : PPT, PPTX, DOCX, PDF, JPEG, JPG, PNG - Limited size 20Mo',
        key: 'pr2',
        maxSize: 20000000
    },
    {
        type: DocumentTypes.FID,
        allowedMimeTypes: ['25504446', 'd0cf11e0', '504B0304', '504B0506',
            '504B0708', '504b34', '89504e47', 'ffd8ffdb', 'ffd8ffee', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'],
        acceptedFiles: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
            'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/*',
        helpText: 'Supported documents : PPT, PPTX, DOCX, PDF, JPEG, JPG, PNG - Limited size 20Mo',
        key: 'fid',
        maxSize: 20000000
    },
    {
        type: DocumentTypes.STEERING_COMMITTEES,
        allowedMimeTypes: ['25504446', 'd0cf11e0', '504B0304', '504B0506',
            '504B0708', '504b34', '89504e47', 'ffd8ffdb', 'ffd8ffee', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'],
        acceptedFiles: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
            'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/*',
        helpText: 'Supported documents : PPT, PPTX, DOCX, PDF, JPEG, JPG, PNG - Limited size 20Mo',
        key: 'steering_committees',
        maxSize: 20000000
    },
    {
        type: DocumentTypes.REX,
        allowedMimeTypes: ['25504446', 'd0cf11e0', '504B0304', '504B0506', '504B0708',
            '504b34', '89504e47', 'ffd8ffdb', 'ffd8ffee', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'],
        acceptedFiles: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
            'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/*',
        helpText: 'Supported documents : PPT, PPTX, DOCX, PDF, JPEG, JPG, PNG - Limited size 20Mo',
        key: 'rex',
        maxSize: 20000000
    },
    {
        type: DocumentTypes.OTHER,
        allowedMimeTypes: ['25504446', 'd0cf11e0', '504B0304', '504B0506', '504B0708', '504b34',
            '89504e47', 'ffd8ffdb', 'ffd8ffee', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'],
        acceptedFiles: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
            'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/*',
        helpText: 'Supported documents : PPT, PPTX, DOCX, PDF, JPEG, JPG, PNG - Limited size 20Mo',
        key: 'other',
        maxSize: 20000000
    }
];
/*
            //"image/png"
            case "89504e47":
            //"image/jpeg"
            case "ffd8ffdb":
            case "ffd8ffee":
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
            //pdf
            case '25504446':
            //doc/ppt
            case 'd0cf11e0':
            //docx/pptx
            case '504B0304':
            case '504B0506':
            case '504B0708':
            case '504b34':
 */
