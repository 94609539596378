<div class="upload-doc-container" (click)="fileInput.click()" *ngIf="canUpload">
    <div class="start-text">
        <mat-icon>arrow_right</mat-icon>
        {{ "add_document" | translate }}
    </div>
    <div class="end-text">
        {{ helpText }}
    </div>
    <input
        #fileInput
        type="file"
        (change)="onFileInput($event.target.files)"
        class="d-none"
        [accept]="acceptedFiles"
        multiple
    />
</div>

<table class="mat-table" *ngIf="files || uploadedFiles">
    <thead *ngIf="files?.length + uploadedFiles?.length > 0">
    <tr class="mat-header-row">
        <th class="mat-header-cell col-width-70">{{ "documents" | translate }}</th>
        <th class="col-width-30"></th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row file" *ngFor="let file of files; index as i;">
        <td class="label">
            <span class="attachment-label">{{ file.name }}</span>
        </td>
        <td class="buttons">
            <button mat-icon-button (click)="removeAttachment(i, 'files')">
                <mat-icon mat-list-icon>delete</mat-icon>
            </button>
        </td>
    </tr>
    <tr class="mat-row file" *ngFor="let file of uploadedFiles; index as i;">
        <td class="label">
            <span class="attachment-label">{{ file.title }}</span>
        </td>
        <td class="buttons">
            <button mat-icon-button (click)="viewFile(file.path)">
                <mat-icon mat-list-icon>remove_red_eye</mat-icon>
            </button>
            <button mat-icon-button (click)="download(file)">
                <mat-icon class="download-icon" mat-list-icon>publish</mat-icon>
            </button>
            <button mat-icon-button (click)="removeAttachment(i, 'uploadedFiles')" *ngIf="canUpload">
                <mat-icon mat-list-icon>delete</mat-icon>
            </button>
        </td>
    </tr>
    </tbody>
</table>

