<div class="projects-container">
    <div class="d-flex flex-wrap">
        <div class="hstyle align-self-baseline">Projects list |</div>
        <div class="hstylecontent align-self-center" *ngIf="projectsInfo"> {{projectsInfo.totalProjects}} 
            {{showCanceledProjects ? 'CANCELED' : (showCompletedProjects ? 'COMPLETED' : 'ACTIVE')}} PROJECTS - TOTAL :
            {{projectsInfo.totalCost / 1000 | number:'1.0-2'}}M$
        </div>
    </div>

    <div class="toggle-button-row" *ngIf="!showCompletedProjects && !showCanceledProjects">
        ALL PROJECTS
        <mat-slide-toggle [(ngModel)]="showMy" color="primary" style="margin-left: 15px; margin-right: 15px;">
        </mat-slide-toggle>
        MY FAVORITES
        <button (click)="exportAll()" mat-raised-button class="ml-auto" *ngIf="!showCompletedProjects && !showCanceledProjects && !isMobile">
            <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
            <span>{{ 'Export All XLS' | translate }}</span>
        </button>
    </div>
    <div class="d-flex" *ngIf="showCompletedProjects && !showCanceledProjects && !isMobile">
        <button (click)="exportCompleted()" mat-raised-button class="ml-auto">
            <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
            <span>{{ 'Export Completed Projects XLS' | translate }}</span>
        </button>
    </div>
    <div class="d-flex" *ngIf="!showCompletedProjects && showCanceledProjects">
        <button (click)="exportCanceled()" mat-raised-button class="ml-auto">
            <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
            <span>{{ 'Export Canceled Projects XLS' | translate }}</span>
        </button>
    </div>
    <app-project-filter [projects]="getAllProjects()" (projectsChange)="filterProjects($event)"
                                [expanded]="false" [periodFilter]="false" [nextMilFilter]="true"
                                *ngIf="devProjectsLoaded && exeProjectsLoaded && !showCompletedProjects && !showCanceledProjects">
    </app-project-filter>

    <div class="grid-container my-projects" *ngIf="!showCompletedProjects && !showCanceledProjects">
        <div class="d-flex align-items-center">
            <div class="title-container pt-2">
                <div class="secondary-title d-flex align-items-center" (mouseenter)="exeTooltip.show()"
         (mouseleave)="exeTooltip.hide()">{{ 'exe_project_progress' | translate | uppercase }}
                     <mat-icon class="infobulle" #exeTooltip="matTooltip" matTooltipClass="allow-cr" matTooltip="Total project CAPEX performance : 

&nbsp;- Green smiley :  EFC ≤  Approved FID 
&nbsp;- Orange smiley : Approved FID <   EFC ≤  Approved at FID +10% 
&nbsp;- Red smiley : EFC >  Approved FID +10% 

Schedule performance, from FID to RFSU: 

&nbsp;- Green smiley :  Actual duration  ≤  Initial duration 
&nbsp;- Orange smiley : Initial duration <   Actual duration ≤  Initial duration +10% 
&nbsp;- Red smiley : Actual duration >  Initial duration +10% 

% progress from FID to RFSU

FID: Final Investment Decision 

RFSU: Ready For Start Up

EFC : Estimated Final Cost " matTooltipPosition="right">
             info
         </mat-icon>
                </div>
            </div>
            <button (click)="exportExeAsCsv()" mat-raised-button class="ml-auto" *ngIf="!isMobile">
                <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
                <span>{{ 'export_xls' | translate }}</span>
            </button>
        </div>

        <div class="scroll-container">
            <ag-grid-angular #agGridExeAll style="width: 100%;" id="exeGridAll" class="ag-theme-material"
                             [columnDefs]="exeProjectsColumnDefs" [defaultColDef]="defaultColDef"
                             [rowData]="exefilteredProjects"
                             (gridReady)="onExeGridReady($event)" [pagination]="true"
                             [paginationPageSize]="10"
                             [icons]="icons" [getRowHeight]="getRowHeight" *ngIf="!showMy">
            </ag-grid-angular>
            <ag-grid-angular #agGridExeMy style="width: 100%;" id="exeGridMy" class="ag-theme-material"
                             [columnDefs]="exeProjectsColumnDefs" [defaultColDef]="defaultColDef"
                             [rowData]="exefilteredProjects"
                             (gridReady)="onExeGridReady($event)" [pagination]="true"
                             [paginationPageSize]="10"
                             [icons]="icons" [getRowHeight]="getRowHeight" *ngIf="showMy">
            </ag-grid-angular>
        </div>
        <div class="page-size">
            Projects per page:
            <select (change)="onPageSizeChanged($event, 'exeProjectsApi')">
                <option value="10" selected="">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </div>
    </div>

    <div class="grid-container my-projects" *ngIf="!showCompletedProjects && !showCanceledProjects">
        <div class="d-flex align-items-center">
            <div class="title-container pt-2">
                <div class="secondary-title d-flex align-items-center" (mouseenter)="devTooltip.show()"
         (mouseleave)="devTooltip.hide()">{{ 'dev_project_progress' | translate | uppercase }}
        <mat-icon class="infobulle" #devTooltip="matTooltip" matTooltipClass="allow-cr" matTooltip="Schedule performance, from Gate FEASIBILITY to FID:  

&nbsp;- Green smiley :  Actual duration  ≤  Initial duration 
&nbsp;- Orange smiley : Initial duration <   Actual duration ≤  Initial duration +20% 
&nbsp;- Red smiley : Actual duration >  Initial duration +20% 

% progress from  Gate FEASIBILITY to FID
FID: Final Investment Decision" matTooltipPosition="right">
             info
         </mat-icon></div>
            </div>
            <button (click)="exportDevAsCsv()" mat-raised-button class="ml-auto" *ngIf="!isMobile">
                <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
                <span>{{ 'export_xls' | translate }}</span>
            </button>
        </div>
        <div class="scroll-container">
            <ag-grid-angular #agGridDevAll style="width: 100%;" id="devGridAll" class="ag-theme-material"
                             [columnDefs]="devProjectsColumnDefs" [defaultColDef]="defaultColDef"
                             [rowData]="devfilteredProjects"
                             (gridReady)="onDevProjectsReady($event)" [pagination]="true"
                             [paginationPageSize]="10" [icons]="icons" [getRowHeight]="getRowHeight" *ngIf="!showMy">
            </ag-grid-angular>
            <ag-grid-angular #agGridDevMy style="width: 100%;" id="devGridMy" class="ag-theme-material"
                             [columnDefs]="devProjectsColumnDefs" [defaultColDef]="defaultColDef"
                             [rowData]="devfilteredProjects"
                             (gridReady)="onDevProjectsReady($event)" [pagination]="true"
                             [paginationPageSize]="10" [icons]="icons" [getRowHeight]="getRowHeight" *ngIf="showMy">
            </ag-grid-angular>
        </div>
        <div class="page-size">
            Projects per page:
            <select (change)="onPageSizeChanged($event, 'devProjectsApi')">
                <option value="10" selected="">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </div>
    </div>
    <div class="grid-container my-projects" *ngIf="showCompletedProjects">
        <div class="d-flex align-items-center">
            <div class="title-container">
                <div class="secondary-title">{{ 'COMPLETED PROJECTS' | translate | uppercase }}</div>
            </div>
            <button (click)="exportCompleteAsCsv()" mat-raised-button class="ml-auto" *ngIf="!isMobile">
                <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
                <span>{{ 'export_xls' | translate }}</span>
            </button>
        </div>
        <div class="scroll-container">
            <ag-grid-angular #agCompletedProjects style="width: 100%;" id="completedProjects" class="ag-theme-material"
                             [columnDefs]="completedProjectsDefs" [defaultColDef]="defaultColDef"
                             [gridOptions]="gridOptions"
                             [rowData]="completedProjects.exe"
                             (gridReady)="onCompletedProjectsReady($event)"
                             [pagination]="true" [paginationPageSize]="10" [icons]="icons">
            </ag-grid-angular>
        </div>
        <div class="page-size">
            Projects per page:
            <select (change)="onPageSizeChanged($event, 'completedProjectsApi')">
                <option value="10" selected="">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </div>
    </div>

    <div class="grid-container my-projects" *ngIf="showCanceledProjects">
        <div class="d-flex align-items-center">
            <div class="title-container">
                <div class="secondary-title">{{ 'CANCELED PROJECTS' | translate | uppercase }}</div>
            </div>
            <button (click)="exportCanceledAsCsv()" mat-raised-button class="ml-auto" *ngIf="!isMobile">
                <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
                <span>{{ 'export_xls' | translate }}</span>
            </button>
        </div>
        <div class="scroll-container">
            <ag-grid-angular #agCanceledProjects style="width: 100%;" id="canceledProjects" class="ag-theme-material"
                             [columnDefs]="canceledProjectsDefs" [defaultColDef]="defaultColDef"
                             [rowData]="canceledProjects"
                             (gridReady)="onCanceledProjectsReady($event)" [pagination]="true"
                             [paginationPageSize]="10" [icons]="icons">
            </ag-grid-angular>
        </div>
        <div class="page-size">
            Projects per page:
            <select (change)="onPageSizeChanged($event, 'canceledProjectsApi')">
                <option value="10" selected="">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </div>
    </div>

    <div style="padding-bottom: 30px;" class="d-flex flex-row-reverse flex-wrap ">
    <button mat-raised-button style="margin-left: 1em;" class="secondary-btn" (click)="showCanceled()">
        <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
        <span>See {{showCanceledProjects ? 'active' : 'canceled'}} projects</span>
    </button>

    <button mat-raised-button [ngStyle]="{'margin-top':issmallMobile ? '1em' : 'unset' }" class="secondary-btn" (click)="showCompleted()">
        <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
        <span>See {{showCompletedProjects ? 'active' : 'completed'}} projects</span>
    </button>

</div>
</div>
