import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { LoaderService } from '@ff/loader';
import { map } from 'rxjs/operators';
import { CrudService } from '../../services/crud.service';
import { MsgService } from '../../services/msg.service';
import {isNil} from 'lodash';
@Component({
  selector: 'app-project-bookmark',
  templateUrl: './project-bookmark.component.html',
  styleUrls: ['./project-bookmark.component.scss']
})
export class ProjectBookmarkComponent implements OnInit, OnChanges {

  @Input() projectId;
  @Input() projectBookmarked;
  @Input() fontSize = 24;
  @Input() useOnChange = true;

  constructor(
    private _crudService: CrudService,
    private _loaderService: LoaderService,
    private _msgService: MsgService,
  ) {

  }

  ngOnInit(): void {
    if (isNil(this.projectBookmarked) && !isNil(this.projectId)) {
      this.isBookmarked();
    }
  }

  ngOnChanges(): void {
    if (this.useOnChange) {
      this.isBookmarked();
    }
  }

  bookmark() {
    let formData = new FormData();
    formData.append('projectId', this.projectId);

    this._crudService.createResource('project/bookmark', formData).pipe(map((res: any) => res.data)).subscribe(
      (response) => {
        this.projectBookmarked = response;
      }
    )
  }

  isBookmarked() {
    this._crudService.getResource('project/bookmark', this.projectId).pipe(map((res: any) => res.data)).subscribe(
      (isBookmarked) => {
        this.projectBookmarked = isBookmarked;
      }
    )
  }

}
