import {Component, HostListener} from '@angular/core';
import {ProjectService} from '../../../_services/project.service';
import {AgClickableParentComponent} from '../../../shared/components/ag-clickable-parent/ag-clickable-parent.component';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import {isArray, isNil, isEmpty} from 'lodash';
import * as moment from 'moment';
import {PercentageParentComponent} from '../../../shared/components/percentage-parent/percentage-parent.component';
import {IMilistones} from '../../../model/Milistones';
import {IndicatorParentComponent} from '../../../shared/components/indicator-parent/indicator-parent.component';
import {PROJECT_STEP_COLOR, PROJECT_STEP_ITEM} from '../../../model/Monthly';
import {LoaderService} from '@ff/loader';
import {Subject} from 'rxjs';
import * as XLSX from 'xlsx';
import { globaldata, loaderHide, loaderShow, compare } from '../../../shared/models/project';
import { UserService } from '@ff/user';
import { User } from 'src/app/modules/model/user';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent {
  authUser: User;
  constructor(
      private _projectService: ProjectService,
      private _loaderService: LoaderService,
      private _userService: UserService
  ) {
    loaderShow(this._loaderService);
    this.authUser = this._userService.getUser().getValue();
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true,
      autoHeight: true,
      skipHeaderOnResize: true,
    };
    this.allProjectLoadedListenner.next(false);
    this.allProjectLoadedListenner.subscribe(
        (allLoaded) => {
          if (allLoaded) {
            this.allProjects = [];
            this.allProjects.push(...this.devProjects);
            this.allProjects.push(...this.exeProjects);
            this.projectsInfo = globaldata(this.allProjects);
            loaderHide(this._loaderService);
          }
          loaderHide(this._loaderService);
      },
      err => {
         loaderHide(this._loaderService);
      }
    );
    this.isMobile = window.innerWidth < 700;
    this.issmallMobile = window.innerWidth < 427;
  }
  isMobile = false;
  issmallMobile: boolean;
  projectsInfo: {totalCost: any, totalProjects: any};

  escapedColumns = ['updated', 'period', 'highlight', 'progress', 'cost', 'title', 'country',
    'step', 'fid', 'rfsu', 'zone', 'next-mil', 'next-steerco'];

  gridOptions = {
    suppressColumnVirtualisation: true,
    autoSizePadding: 8,
    suppressMovableColumns: true
  };

  defaultColDef;
  exeProjectsApi;
  exeProjectsColumnApi;

  icons = {
    columnGroupOpened: '<',
    columnGroupClosed: '>'
  };

  devProjects = [];

  exeProjects = [];

  allProjects = [];

  devfilteredProjects = [];

  exefilteredProjects = [];

  devProjectsApi;
  devProjectsColumnApi;

  devProjectsLoaded = false;
  exeProjectsLoaded = false;
  allProjectLoadedListenner = new Subject();

  completedProjectsApi;
  completedProjectsColumnApi;

  modules = AllCommunityModules;
  headers;

  showMy = false;

  step = 'my';
  stepAll = 'my';

  devProjectsColumnDefs = [
    {
      headerName: 'General data',
      headerClass: 'general',
      colId: 'general',
      children: [
        {
          headerName: 'Project name',
          colId: 'title',
          field: 'title',
          pinned: 'left',
          width: 130,
          minWidth: 130,
          cellStyle: {borderLeft: '1px solid #e2e2e2', whiteSpace: 'normal', textTransform: 'uppercase'}
        }
      ]
    },
    {
      headerName: '',
      colId: 'generalBis',
      children: [
        {
          headerName: 'Zone',
          colId: 'zone',
          width: 60,
          minWidth: 60,
          valueGetter: this.getZone
        },
        {
          headerName: 'Country',
          colId: 'country',
          headerClass: 'country',
          field: 'country.label_en',
          width: 110,
          minWidth: 110,
          cellStyle: {whiteSpace: 'normal', borderRight: '1px solid #e2e2e2'}
        },
      ]
    },
    {
      headerName: 'Cost',
      colId: 'cost',
      children: [
        {
          headerName: 'CAPEX estimated',
          colId: 'cost',
          headerClass: 'cost',
          width: 130,
          valueGetter: this.getCost,
          comparator: this.compareExeCost,
          cellStyle: {borderLeft: 'none', textAlign: 'end', paddingRight: '10px'}
        }
      ]
    },
    {
      headerName: 'Progress & schedule',
      colId: 'progress-group',
      children: [
        {
          headerName: '% progress',
          colId: 'progress',
          field: 'progress',
          cellRendererFramework: PercentageParentComponent,
          cellStyle: {width: '200px', borderLeft: '1px solid #e2e2e2'}
        },
        {
          headerName: 'Step',
          colId: 'step',
          field: 'project_step',
          minWidth: 115,
          width: 115,
          cellStyle: this.getStepStyle,
          comparator: this.compareSteps
        },
        {
          headerName: 'FID Forecast',
          colId: 'fid',
          minWidth: 135,
          width: 135,
          valueGetter: this.getFIDForecast,
          comparator: this.compareDevFID,
          styleFunction: this.getFIDForecastRenderer,
          cellRendererFramework: IndicatorParentComponent,
          indicatorType: 'schedule',
          step: 'dev'
        },
      ]
    },
    {
      headerName: 'Events',
      colId: 'events',
      children: [
        {
          headerName: 'Next milestone',
          colId: 'next-mil',
          minWidth: 200,
          width: 200,
          comparator: this.compareNextMilestone,
          valueGetter: this.getNextMilestone,
          cellStyle: {whiteSpace: 'break-spaces', borderLeft: '1px solid #e2e2e2'}
        },
        {
          headerName: 'Next \n steerco',
          colId: 'next-steerco',
          minWidth: 90,
          width: 90,
          comparator: this.compareNextSteerCo,
          valueGetter: this.getNextSteerCo,
        },
        {
          headerName: 'Main events',
          colId: 'highlight',
          minWidth: 400,
          width: 400,
          valueGetter: this.getHighlight,
          cellStyle: {whiteSpace: 'normal'}
        },
      ]
    },
    {
      headerName: 'Contacts',
      colId: 'contacts',
      children: [
        {
          headerName: 'Project Architect',
          colId: 'architect',
          minWidth: 200,
          width: 200,
          valueGetter: this.getArchitect,
          cellStyle: {whiteSpace: 'break-spaces', borderLeft: '1px solid #e2e2e2'}
        },
        {
          headerName: 'Project Manager',
          colId: 'manager',
          minWidth: 200,
          width: 200,
          valueGetter: this.getManager,
        }
      ]
    },
    {
      headerName: '',
      colId: 'period',
      children: [
        {
          headerName: 'Last \n report',
          pinned: 'right',
          colId: 'updated',
          valueGetter: this.getUpdatedAt,
          comparator: this.compareLastPeriod,
          cellStyle: this.getDateStyle,
          minWidth: 80,
          width: 80
        }
      ]
    },
    {
      headerName: '',
      colId: 'actions-group',
      cellStyle: {borderRight: '1px solid #e2e2e2'},
      children: [
        {
          headerName: '', cellRendererFramework: AgClickableParentComponent,
          type: 'edit', pinned: 'right', colId: 'action', minWidth: 130,
          cellStyle: {borderRight: '1px solid #e2e2e2', borderLeft: '1px solid #e2e2e2'}
        },
        // {headerName: '', cellRendererFramework: AgClickableParentComponent, type: 'export'},
      ]
    }
  ];

  exeProjectsColumnDefs = [
    {
      headerName: 'General data',
      headerClass: 'general',
      colId: 'general-data',
      children: [
        {
          headerName: 'Project name',
          colId: 'title',
          field: 'title',
          pinned: 'left',
          width: 130,
          minWidth: 130,
          cellStyle: {borderLeft: '1px solid #e2e2e2', whiteSpace: 'normal', textTransform: 'uppercase'}
        },
      ]
    },
    {
      headerName: '',
      colId: 'generalBis',
      children: [
        {
          headerName: 'Zone',
          colId: 'zone',
          width: 60,
          minWidth: 60,
          valueGetter: this.getZone
        },
        {
          headerName: 'Country',
          field: 'country.label_en',
          colId: 'country',
          headerClass: 'country',
          width: 110,
          minWidth: 110,
          cellStyle: {whiteSpace: 'normal', borderRight: '1px solid #e2e2e2'}
        },
      ]
    },
    {
      headerName: 'Cost',
      colId: 'cost',
      children: [
        {
          headerName: 'EFC',
          colId: 'cost',
          headerClass: 'cost',
          indicatorType: 'cost',
          step: 'exe',
          width: 130,
          comparator: this.compareExeCost,
          valueGetter: this.getCost,
          cellRendererFramework: IndicatorParentComponent,
          cellStyle: {textAlign: 'end', borderLeft: 'none'}
        }
      ]
    },
    {
      headerName: 'Progress & schedule',
      colId: 'progress-group',
      children: [
        {
          headerName: '% progress',
          field: 'progress',
          cellRendererFramework: PercentageParentComponent,
          cellClass: 'progress-cell',
          colId: 'progress',
          cellStyle: {width: '200px', borderLeft: '1px solid #e2e2e2'}
        },
        {
          headerName: 'FID actual',
          colId: 'fid',
          minWidth: 115,
          width: 115,
          comparator: this.compareFIDActual,
          valueGetter: this.getFIDActual,
        },
        {
          headerName: 'RFSU',
          colId: 'rfsu',
          comparator: this.compareRFSUDates,
          valueGetter: this.getExeRFSU,
          cellRendererFramework: IndicatorParentComponent,
          indicatorType: 'schedule',
          step: 'exe',
          styleFunction: this.getExeRFSURenderer,
          minWidth: 135,
          width: 135
        },
      ]
    },
    {
      headerName: 'Events',
      children: [
        {
          headerName: 'Next milestone',
          colId: 'nex-mil',
          minWidth: 200,
          width: 200,
          comparator: this.compareNextMilestone,
          valueGetter: this.getNextMilestone,
          cellStyle: {whiteSpace: 'break-spaces', borderLeft: '1px solid #e2e2e2'}
        },
        {
          headerName: 'Next \n steerco',
          colId: 'next-steerco',
          minWidth: 90,
          width: 90,
          comparator: this.compareNextSteerCo,
          valueGetter: this.getNextSteerCo,
        },
        {
          headerName: 'Main events',
          headerClass: 'highlight-header',
          colId: 'highlight',
          minWidth: 400,
          width: 400,
          valueGetter: this.getHighlight,
          cellStyle: {whiteSpace: 'normal'}
        },
      ]
    },
    {
      headerName: 'Contacts',
      colId: 'contacts',
      children: [
        {
          headerName: 'Project Architect',
          colId: 'architect',
          minWidth: 200,
          width: 200,
          valueGetter: this.getArchitect,
          cellStyle: {whiteSpace: 'break-spaces', borderLeft: '1px solid #e2e2e2'}
        },
        {
          headerName: 'Project Manager',
          colId: 'manager',
          minWidth: 200,
          width: 200,
          valueGetter: this.getManager,
        }
      ]
    },
    {
      headerName: '',
      pinned: 'right',
      colId: 'period',
      children: [
        {
          headerName: 'Last \n report',
          colId: 'updated',
          valueGetter: this.getUpdatedAt,
          comparator: this.compareLastPeriod,
          cellStyle: this.getDateStyle,
          minWidth: 80,
          width: 80,
          pinned: 'right'
        }
      ]
    },
    {
      headerName: '',
      pinned: 'right',
      colId: 'actions',
      cellStyle: {border: 'unset'},
      children: [
        {
          headerName: '', cellRendererFramework: AgClickableParentComponent,
          type: 'edit', pinned: 'right', colId: 'action', minWidth: 130,
          cellStyle: {borderLeft: '1px solid #e2e2e2', borderRight: '1px solid #e2e2e2'}
        },
        // {headerName: '', cellRendererFramework: AgClickableParentComponent, type: 'export'},
      ]
    }
  ];

  completedProjects = {
    dev: [], exe: []
  };

  showCompletedProjects = false;
  completedProjectsDefs = [
    {
      headerName: 'General data',
      headerClass: 'general',
      colId: 'general-data',
      children: [
        {
          headerName: 'Project name',
          colId: 'title',
          field: 'title',
          pinned: 'left',
          width: 130,
          minWidth: 130,
          cellStyle: {borderLeft: '1px solid #e2e2e2', whiteSpace: 'normal', textTransform: 'uppercase'}
        },
      ]
    },
    {
      headerName: '',
      colId: 'generalBis',
      children: [
        {
          headerName: 'Zone',
          colId: 'zone',
          width: 60,
          minWidth: 60,
          valueGetter: this.getZone
        },
        {
          headerName: 'Country',
          field: 'country.label_en',
          colId: 'country',
          headerClass: 'country',
          width: 110,
          minWidth: 110,
          cellStyle: {whiteSpace: 'normal', borderRight: '1px solid #e2e2e2'}
        },
      ]
    },
    {
      headerName: 'Cost',
      colId: 'cost',
      children: [
        // total_dev_cost_performed
        {
          headerName: 'Cost approved at FID',
          headerClass: 'cost',
          valueGetter: this.getExeCostAtFID,
          comparator: this.compareExeFID,
          colId: 'cost',
          cellRendererFramework: IndicatorParentComponent,
          type: 'cost',
          step: 'exe',
          width: 160,
          cellStyle: {borderRight: '1px solid #e2e2e2'}
        },
        {
          headerName: 'EFC',
          valueGetter: this.getExeCost,
          comparator: this.compareExeEFC,
          colId: 'cost',
          cellRendererFramework: IndicatorParentComponent,
          type: 'cost',
          step: 'exe',
          minWidth: 160,
          cellStyle: {borderRight: '1px solid #e2e2e2'}
        }
      ]
    },
    {
      headerName: 'Progress & schedule',
      children: [
        {
          headerName: 'Gate Feasability',
          minWidth: 115,
          valueGetter: this.getExeFeasability,
          comparator: this.compareExeFeasibility
        },
        {
          headerName: 'Gate BASIC',
          minWidth: 115,
          valueGetter: this.getExeBasic,
          comparator: this.compareExeBasic
        },
        {
          headerName: 'Gate FEED',
          minWidth: 115,
          valueGetter: this.getExeFeed,
          comparator: this.compareExeFeed
        },
        {
          headerName: 'Gate CFT',
          minWidth: 115,
          valueGetter: this.getExeCFT,
          comparator: this.comparatorExeCFT
        },
        {
          headerName: 'FID',
          minWidth: 115,
          valueGetter: this.getFIDActual,
          comparator: this.compareFIDActual
        },
        {
          headerName: 'Contracts award',
          minWidth: 115,
          valueGetter: this.getExeContractDate,
          comparator: this.compareExeContract
        },
        {
          headerName: 'RFSU',
          colId: 'rfsu',
          comparator: this.compareRFSUDates,
          valueGetter: this.getExeRFSU,
          cellRendererFramework: IndicatorParentComponent,
          indicatorType: 'schedule',
          step: 'exe',
          styleFunction: this.getExeRFSURenderer,
          minWidth: 135,
          cellStyle: {borderRight: '1px solid #e2e2e2'}
        },
        {
          headerName: 'REX',
          minWidth: 115,
          valueGetter: this.getExeRex,
          comparator: this.compareExeRex,
          cellStyle: {borderRight: '1px solid #e2e2e2'}
        },
      ]
    },
    {
      headerName: '',
      pinned: 'right',
      colId: 'period',
      children: [
        {
          headerName: 'Last \n report',
          colId: 'updated-at',
          valueGetter: this.getUpdatedAt,
          comparator: this.compareLastPeriod,
          cellStyle: this.getDateStyle,
          minWidth: 90,
          width: 90,
          pinned: 'right'
        }
      ]
    },
    {
      headerName: '',
      pinned: 'right',
      cellStyle: {borderRight: '1px solid #e2e2e2'},
      children: [
        {
          headerName: '', cellRendererFramework: AgClickableParentComponent, minWidth: 85,
          type: 'edit', pinned: 'right', cellStyle: {borderRight: '1px solid #e2e2e2'}
        },
        // {headerName: '', cellRendererFramework: AgClickableParentComponent, type: 'export'},
      ]
    }
  ];

  canceledProjectsColumnApi;
  canceledProjectsApi;
  canceledProjects = [];
  showCanceledProjects = false;
  canceledProjectsDefs = [
    {
      headerName: 'General data',
      colId: 'general-data',
      children: [
        {
          headerName: 'Project name',
          colId: 'title',
          field: 'title',
          pinned: 'left',
          cellStyle: {borderLeft: '1px solid #e2e2e2', whiteSpace: 'normal'}
        },
        {
          headerName: 'Zone',
          colId: 'zone',
          pinned: 'left',
          valueGetter: this.getZone
        },
        {
          headerName: 'Country',
          field: 'country.label_en',
          colId: 'country',
          pinned: 'left',
          cellStyle: {borderRight: '1px solid #e2e2e2', whiteSpace: 'normal'}
        },
      ]
    },
    {
      headerName: 'Cost',
      children: [
        {
          headerName: 'CAPEX estimated',
          valueGetter: this.getCost,
          cellStyle: {borderRight: '1px solid #e2e2e2'}
        },
      ]
    },
    {
      headerName: 'Step',
      colId: 'step',
      field: 'project_step',
      minWidth: 100,
      cellStyle: this.getStepStyle,
      comparator: this.compareSteps
    },
    {
      headerName: 'Status',
      field: 'status',
      cellStyle: {borderRight: '1px solid #e2e2e2'}
    },
    {
      headerName: '',
      children: [
        {
          headerName: 'Last \n report',
          colId: 'updated',
          valueGetter: this.getUpdatedAt,
          cellStyle: {borderRight: '1px solid #e2e2e2'}
        }
      ]
    },
    {
      headerName: '',
      pinned: 'right',
      cellStyle: {borderRight: '1px solid #e2e2e2'},
      children: [
        {
          headerName: '', cellRendererFramework: AgClickableParentComponent, minWidth: 85,
          type: 'edit', pinned: 'right', cellStyle: {borderRight: '1px solid #e2e2e2'}
        },
        // {headerName: '', cellRendererFramework: AgClickableParentComponent, type: 'export'},
      ]
    }
  ];

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth < 700;
    this.issmallMobile = window.innerWidth < 427;
  }

  getTargetDate(params) {
    const mp = params.data ? params.data : null;
    if (!isNil(mp)) {
      const milestones = mp.milistones;
      if (!isNil(milestones) && isArray(milestones)) {
        switch (mp.project_step) {
          case 'Needs':
          case 'Feasibility':
            return milestones[0].initial ? moment.utc(milestones[0].initial).format('DD/MM/YYYY') : '';
          case 'Basic':
          case 'CFT':
          case 'Feed':
            return milestones[13].initial ? moment.utc(milestones[13].initial).format('DD/MM/YYYY') : '';
          default:
            break;
        }
      }
    }
    return '';
  }

  getRowHeight(params) {
    if (isNil(params.data.highlights?.main_events)) {
      return 54;
    }
    return (
        Math.round(params.data.highlights?.main_events?.length / 70) === 0 ||
        Math.round(params.data.highlights?.main_events?.length / 70) === 1 ? 54 :
            35 * (Math.round(params.data.highlights?.main_events?.length / 70))
    );
  }

  onPageSizeChanged(event, gridApi) {
    const value = event.target.value;
    this[gridApi].paginationSetPageSize(Number(value));
  }

  getAllProjects() {
    return this.allProjects;
  }

  getDateStyle(params) {
    const period = isNil(params.data.period) ? moment.utc(params.data.updated_at) : moment.utc(params.data.period);
    // add 1 day to start of month coz otherwise diff is <2 between 2 months
    const now = moment().startOf('month').add(1, 'days');
    const months = now.diff(period.startOf('month'), 'months');
    return {borderLeft: '1px solid #e2e2e2', color: (months < 2 ? '#00817A' : (months === 2 ? '#F59600' : '#ED0000'))};
  }

  getZone(params) {
    const project = params?.data;
    return project?.zone?.label?.split('-', 1)[0];
  }

  getStepStyle(params) {
    const step = params.value;
    let color = '';
    switch (step) {
      case PROJECT_STEP_ITEM.NEEDS:
        color = PROJECT_STEP_COLOR.NEEDS;
        break;
      case PROJECT_STEP_ITEM.FEASIBILITY:
        color = PROJECT_STEP_COLOR.FEASIBILITY;
        break;
      case PROJECT_STEP_ITEM.BASIC:
        color = PROJECT_STEP_COLOR.BASIC;
        break;
      case PROJECT_STEP_ITEM.FEED:
        color = PROJECT_STEP_COLOR.FEED;
        break;
      case PROJECT_STEP_ITEM.CFT:
        color = PROJECT_STEP_COLOR.CFT;
        break;
      default:
        break;
    }
    return {backgroundColor: color, color: 'white', fontStyle: 'bold', textTransform: 'uppercase', textAlign: 'center'};
  }

  getFIDForecast(params) {
    return getDateFromStep(params, 13);
  }

  getFIDForecastRenderer(params) {
    return getDateRendererFromStep(params, 13);
  }

  getAlert(params) {
    const milestones = params.data ? params.data.milestones : null;
    const costs = params.data ? params.data.cost : null;
    const step = params.data ? params.data.project_step : null;
    if (!isNil(milestones) && isArray(milestones) && !isNil(costs)) {
      // 3 GATE BASIC 13 FID
      let date = null;
      let nbPr = 0;
      if (!isNil(milestones[13].revised)) {
        date = moment.utc(milestones[13].revised);
        nbPr = 2;
      } else if (!isNil(milestones[3].revised)) {
        date = moment.utc(milestones[3].revised);
        nbPr = 1;
      }
      if (!isNil(date) && date.diff(moment(), 'months') <= 2) {
        if (step === PROJECT_STEP_ITEM.NEEDS) {
          switch (costs.cost_range_at_need_step) {
            case '1M$':
            case '1-4M$':
              return 'PR' + nbPr + ' to be prepared';
            case '4-20M$':
            case '20M$':
              return 'PR' + nbPr + ' and CDMS note to be prepared';
          }
        } else {
          const total = costs.total_project_capex_estimated_amount;
          return 'PR' + nbPr + (total < 4000 ? '' : ' and CDMS note') + ' to be prepared';
        }
      }
    }
    return '';
  }

  getFIDActual(params) {
    return getExeDateFromStep(params, 'fid');
  }

  getExeFeasability(params) {
    return getExeDateFromStep(params, 'gate_feasability');
  }

  getExeBasic(params) {
    return getExeDateFromStep(params, 'gate_basic');
  }

  getExeFeed(params) {
    return getExeDateFromStep(params, 'gate_feed');
  }

  getExeCFT(params) {
    return getExeDateFromStep(params, 'gate_cft');
  }

  getFIDActualRenderer(params) {
    return '<b>' + params.value + '</b>';
  }

  getConstructionStart(params) {
    return getExeDateFromStep(params, 'start_construction');
  }

  getConstructionStartRenderer(params) {
    return getExeDateRendererFromStep(params, 'start_construction');
  }

  getMechanicalCompletion(params) {
    return getExeDateFromStep(params, 'mechanical_completition');
  }

  getMechanicalCompletionRenderer(params) {
    return getExeDateRendererFromStep(params, 'mechanical_completition');
  }

  getExeRFSUInitial(params) {
    return getExeDateFromStep(params, 'rfsu_at_fid');
  }

  getExeContractDate(params) {
    return getExeDateFromStep(params, 'effective_date');
  }

  getExeContractRenderer(params) {
    return getExeDateRendererFromStep(params, 'effective_date');
  }

  getExeRFSU(params) {
    return getExeDateFromStep(params, 'rfsu');
  }

  getExeRex(params) {
    return getExeDateFromStep(params, 'closure_rex');
  }

  getExeRFSURenderer(params) {
    return getExeDateRendererFromStep(params, 'rfsu');
  }

  getUpdatedAt(params) {
    const mp = params.data ? params.data : null;
    if (!isNil(mp.period)) {
      return moment.utc(mp.period).format('MMM YYYY');
    }
    return null;
  }

  getHighlight(params) {
    const highlight = params.data.highlights;
    if (!isNil(highlight)) {
      return highlight.main_events;
    }
    return '';
  }

  getNextMilestone(params) {
    const highlight = params.data.highlights;
    if (!isNil(highlight)) {
      return (!isNil(highlight.next_milistone_date) ? moment.utc(highlight.next_milistone_date).format('DD/MM/YYYY') : '')
          + '\n' + (!isNil(highlight.next_milistone_type) ? highlight.next_milistone_type : '')
          ;
    }
    return '';
  }

  getNextSteerCo(params) {
    const highlight = params.data.highlights;
    if (!isNil(highlight)) {
      return highlight.next_steering_commitee ? moment.utc(highlight.next_steering_commitee).format('DD/MM/YYYY') : '';
    }
    return '';
  }

  getCost(params) {
    if (params.data.project_step === PROJECT_STEP_ITEM.NEEDS) {
       switch (params.data.costs.cost_range_at_need_step) {
          case '1M$':
            return '<' + params.data.costs.cost_range_at_need_step;
            break;
          case '1-4M$':
             return params.data.costs.cost_range_at_need_step;
             break;
          case '4-20M$':
            return params.data.costs.cost_range_at_need_step;
            break;
          case '20M$':
             return '>' + params.data.costs.cost_range_at_need_step;
             break;
         default: return 0;
          }
          // return (params.data.costs ? params.data.cost.toLocaleString('en-US') : 0) + ' k$';

    } else {
          return (params.data.cost ? Math.round(params.data.cost).toLocaleString('en-US') : 0) + ' k$';

    }
  }

  getExeCost(params) {
    const costs = params.data.costs;
    if (!isNil(costs)) {
      return (costs.efc_this_month ? Math.round(costs.efc_this_month).toLocaleString('en-US') : 0) + ' k$';
    }
    return 0 + ' k$';
  }

  getExeCostAtFID(params) {
    const costs = params.data.costs;
    if (!isNil(costs)) {
      return (costs.total_dev_cost_performed ? Math.round(costs.total_dev_cost_performed).toLocaleString('en-US') : 0) + ' k$';
    }
    return 0 + ' k$';
  }

  compareExeEFC(stringA, stringB, paramA, paramB, isInverted) {
    const costA = paramA.data.costs?.efc_this_month;
    const costB = paramB.data.costs?.efc_this_month;
    if (isNil(costA) && isNil(costB)) {
      return 0;
    }
    if (isNil(costA)) {
      return -1;
    }
    if (isNil(costB)) {
      return 1;
    }
    return costA - costB;
  }

  compareExeFID(stringA, stringB, paramA, paramB, isInverted) {
    const costA = paramA.data.costs?.total_dev_cost_performed;
    const costB = paramB.data.costs?.total_dev_cost_performed;
    if (isNil(costA) && isNil(costB)) {
      return 0;
    }
    if (isNil(costA)) {
      return -1;
    }
    if (isNil(costB)) {
      return 1;
    }
    return costA - costB;
  }

  compareExeCost(stringA, stringB, paramA, paramB, isInverted) {
    const costA = paramA.data.cost;
    const costB = paramB.data.cost;
    if (isNil(costA) && isNil(costB)) {
      return 0;
    }
    if (isNil(costA)) {
      return -1;
    }
    if (isNil(costB)) {
      return 1;
    }
    return costA - costB;
  }

  compareRFSUDates(stringA, stringB, paramA, paramB, isInverted) {
    const RFSU_A = getExeDateFromStep(paramA, 'rfsu');
    const RFSU_B = getExeDateFromStep(paramB, 'rfsu');

    if ((RFSU_A === '' && RFSU_B === '') ||
        (isNil(RFSU_A) && isNil(RFSU_B))) {
      return 0;
    }
    if (RFSU_A === '' || isNil(RFSU_A)) {
      return -1;
    }
    if (RFSU_B === '' || isNil(RFSU_B)) {
      return 1;
    }
    return moment.utc(RFSU_A, 'DD/MM/YYYY').diff(moment.utc(RFSU_B, 'DD/MM/YYYY'));
  }

  compareSteps(stringA, stringB, paramA, paramB, isInverted) {
    const stepsWeight = {
      needs: 0,
      feasibility: 1,
      basic: 2,
      feed: 3,
      cft: 4
    };

    const stepA = paramA.data.project_step;
    const weightA = !isNil(stepA) ? stepsWeight[stepA.toLowerCase()] : null;
    const stepB = paramB.data.project_step;
    const weightB = !isNil(stepB) ? stepsWeight[stepB.toLowerCase()] : null;

    if (isNil(weightA)) {
      return -1;
    }
    if (isNil(weightB)) {
      return 1;
    }
    if (isNil(weightA) && isNil(weightB)) {
      return 0;
    }

    return weightA - weightB;
  }

  compareDevFID(stringA, stringB, paramA, paramB, isInverted) {
    const FID_A = getDateFromStep(paramA, 13);
    const FID_B = getDateFromStep(paramB, 13);

    if ((FID_A === '' && FID_B === '') ||
        (isNil(FID_A) && isNil(FID_B))) {
      return 0;
    }
    if (FID_A === '' || isNil(FID_A)) {
      return -1;
    }
    if (FID_B === '' || isNil(FID_B)) {
      return 1;
    }
    return moment.utc(FID_A, 'DD/MM/YYYY').diff(moment.utc(FID_B, 'DD/MM/YYYY'));
  }

  compareNextMilestone(stringA, stringB, paramA, paramB, isInverted) {
    const highlightA = paramA.data.highlights?.next_milistone_date;
    const highlightB = paramB.data.highlights?.next_milistone_date;
    if ((highlightA === '' && highlightB === '') ||
        (isNil(highlightA) && isNil(highlightB))) {
      return 0;
    }
    if (highlightA === '' || isNil(highlightA)) {
      return -1;
    }
    if (highlightB === '' || isNil(highlightB)) {
      return 1;
    }
    return moment.utc(highlightA).diff(moment.utc(highlightB));
  }

  compareNextSteerCo(stringA, stringB, paramA, paramB, isInverted) {
    const steerCoA = paramA.data.highlights?.next_steering_commitee;
    const steerCoB = paramB.data.highlights?.next_steering_commitee;

    if ((steerCoA === '' && steerCoB === '') ||
        (isNil(steerCoA) && isNil(steerCoB))) {
      return 0;
    }
    if (steerCoA === '' || isNil(steerCoA)) {
      return -1;
    }
    if (steerCoB === '' || isNil(steerCoB)) {
      return 1;
    }
    return moment.utc(steerCoA).diff(moment.utc(steerCoB));
  }

  compareExeFeasibility(stringA, stringB, paramA, paramB, isInverted) {
    return compareExeDates(paramA, paramB, 'gate_feasability');
  }

  compareExeBasic(stringA, stringB, paramA, paramB, isInverted) {
    return compareExeDates(paramA, paramB, 'gate_basic');
  }

  compareExeFeed(stringA, stringB, paramA, paramB, isInverted) {
    return compareExeDates(paramA, paramB, 'gate_feed');
  }

  comparatorExeCFT(stringA, stringB, paramA, paramB, isInverted) {
    return compareExeDates(paramA, paramB, 'gate_cft');
  }

  compareExeContract(stringA, stringB, paramA, paramB, isInverted) {
    return compareExeDates(paramA, paramB, 'effective_date');
  }

  compareExeRex(stringA, stringB, paramA, paramB, isInverted) {
    return compareExeDates(paramA, paramB, 'closure_rex');
  }

  compareFIDActual(stringA, stringB, paramA, paramB, isInverted) {
    const FID_A = getExeDateFromStep(paramA, 'fid');
    const FID_B = getExeDateFromStep(paramB, 'fid');

    if ((FID_A === '' && FID_B === '') ||
        (isNil(FID_A) && isNil(FID_B))) {
      return 0;
    }
    if (FID_A === '' || isNil(FID_A)) {
      return -1;
    }
    if (FID_B === '' || isNil(FID_B)) {
      return 1;
    }
    return moment.utc(FID_A, 'DD/MM/YYYY').diff(moment.utc(FID_B, 'DD/MM/YYYY'));
  }

  compareLastPeriod(stringA, stringB, paramA, paramB, isInverted) {
    const mpA = paramA.data ? paramA.data : null;
    const mpB = paramB.data ? paramB.data : null;

    if (isNil(mpA.period)) {
      return -1;
    }
    if (isNil(mpB.period)) {
      return 1;
    }
    if (isNil(mpA.period) && isNil(mpB.period)) {
      return 0;
    }
    return moment.utc(mpA.period).diff(moment.utc(mpB.period));
  }

  onExeGridReady(params) {
    this.exeProjectsApi = params.api;
    this.exeProjectsColumnApi = params.columnApi;
    this.exeProjects = [];
    this.exeProjectsLoaded = false;

    loaderShow(this._loaderService);
    if (this.showMy) {
       if (sessionStorage.getItem('projects_exe_my_' + this.authUser?.id) != null) {
      const projects = JSON.parse(sessionStorage.getItem('projects_exe_my_' + this.authUser?.id));
      this.exeProjects = projects;
      this.exefilteredProjects = projects.sort((a: any, b: any) => compare(a.progress, b.progress, false));
      this.exeProjectsApi.setRowData(this.exefilteredProjects);
      this.exeProjectsLoaded = true;
      this.allProjectLoadedListenner.next(this.devProjectsLoaded && this.exeProjectsLoaded);
      loaderHide(this._loaderService);
      this.exeProjectsApi.setDomLayout('autoHeight');
      this.exeProjectsApi.resetRowHeights();
      const allColIds = params.columnApi.getAllColumns()
              .map(column => {
                if (!this.escapedColumns.includes(column.colId)) {
                  return column.colId;
                }
              });

      params.columnApi.autoSizeColumns(allColIds.filter(item => item !== undefined));
      this.exeProjectsApi.refreshCells();
       } else {
    this._projectService.projectsByType('exe', this.showMy ? 'my' : 'all').subscribe(
      (projects: []) => {

        sessionStorage.setItem('projects_exe_my_' + this.authUser?.id, JSON.stringify(projects));
        this.exeProjects = projects;
        this.exefilteredProjects = projects.sort((a: any, b: any) => compare(a.progress, b.progress, false));
        this.exeProjectsApi.setRowData(this.exefilteredProjects);
        this.exeProjectsLoaded = true;
        this.allProjectLoadedListenner.next(this.devProjectsLoaded && this.exeProjectsLoaded);
        loaderHide(this._loaderService);
        this.exeProjectsApi.setDomLayout('autoHeight');
        this.exeProjectsApi.resetRowHeights();
        const allColIds = params.columnApi.getAllColumns()
              .map(column => {
                if (!this.escapedColumns.includes(column.colId)) {
                  return column.colId;
                }
              });

        params.columnApi.autoSizeColumns(allColIds.filter(item => item !== undefined));
        this.exeProjectsApi.refreshCells();
        }
    );
        }
    } else {
       if (sessionStorage.getItem('projects_exe_' + this.authUser?.id) != null) {
      const projects = JSON.parse(sessionStorage.getItem('projects_exe_' + this.authUser?.id));
      this.exeProjects = projects;
      this.exefilteredProjects = projects.sort((a: any, b: any) => compare(a.progress, b.progress, false));
      this.exeProjectsApi.setRowData(this.exefilteredProjects);
      this.exeProjectsLoaded = true;
      this.allProjectLoadedListenner.next(this.devProjectsLoaded && this.exeProjectsLoaded);
      loaderHide(this._loaderService);
      this.exeProjectsApi.setDomLayout('autoHeight');
      this.exeProjectsApi.resetRowHeights();
      const allColIds = params.columnApi.getAllColumns()
              .map(column => {
                if (!this.escapedColumns.includes(column.colId)) {
                  return column.colId;
                }
              });

      params.columnApi.autoSizeColumns(allColIds.filter(item => item !== undefined));
      this.exeProjectsApi.refreshCells();
       } else {
    this._projectService.projectsByType('exe', this.showMy ? 'my' : 'all').subscribe(
      (projects: []) => {

        sessionStorage.setItem('projects_exe_' + this.authUser?.id, JSON.stringify(projects));
        this.exeProjects = projects;
        this.exefilteredProjects = projects.sort((a: any, b: any) => compare(a.progress, b.progress, false));
        this.exeProjectsApi.setRowData(this.exefilteredProjects);
        this.exeProjectsLoaded = true;
        this.allProjectLoadedListenner.next(this.devProjectsLoaded && this.exeProjectsLoaded);
        loaderHide(this._loaderService);
        this.exeProjectsApi.setDomLayout('autoHeight');
        this.exeProjectsApi.resetRowHeights();
        const allColIds = params.columnApi.getAllColumns()
              .map(column => {
                if (!this.escapedColumns.includes(column.colId)) {
                  return column.colId;
                }
              });

        params.columnApi.autoSizeColumns(allColIds.filter(item => item !== undefined));
        this.exeProjectsApi.refreshCells();
        }
    );
        }
    }

  }

  onDevProjectsReady(params) {
    this.devProjectsApi = params.api;
    this.devProjectsColumnApi = params.columnApi;
    this.devProjects = [];
    this.devProjectsLoaded = false;

    loaderShow(this._loaderService);

    if (this.showMy) {
      if (sessionStorage.getItem('projects_dev_my_' + this.authUser?.id) != null) {
      const projects = JSON.parse(sessionStorage.getItem('projects_dev_my_' + this.authUser?.id));
      this.devProjects = projects;
      this.devfilteredProjects = projects.sort((a: any, b: any) => compare(a.progress, b.progress, false));
      loaderHide(this._loaderService);
      this.devProjectsApi.setRowData(this.devfilteredProjects);
      this.devProjectsApi.setDomLayout('autoHeight');
      this.devProjectsApi.resetRowHeights();
      const allColIds = params.columnApi.getAllColumns()
              .map(column => {
                if (!this.escapedColumns.includes(column.colId)) {
                  return column.colId;
                }
              });
      params.columnApi.autoSizeColumns(allColIds.filter(item => item !== undefined));
      this.devProjectsLoaded = true;
      this.allProjectLoadedListenner.next(this.devProjectsLoaded && this.exeProjectsLoaded);
    } else {
          this._projectService.projectsByType('dev', this.showMy ? 'my' : 'all').subscribe(
        (projects: []) => {
          sessionStorage.setItem('projects_dev_my_' + this.authUser?.id, JSON.stringify(projects));
          this.devProjects = projects;
          this.devfilteredProjects = projects.sort((a: any, b: any) => compare(a.progress, b.progress, false));
          loaderHide(this._loaderService);
          this.devProjectsApi.setRowData(this.devfilteredProjects);
          this.devProjectsApi.setDomLayout('autoHeight');
          this.devProjectsApi.resetRowHeights();
          const allColIds = params.columnApi.getAllColumns()
              .map(column => {
                if (!this.escapedColumns.includes(column.colId)) {
                  return column.colId;
                }
              });
          params.columnApi.autoSizeColumns(allColIds.filter(item => item !== undefined));
          this.devProjectsLoaded = true;
          this.allProjectLoadedListenner.next(this.devProjectsLoaded && this.exeProjectsLoaded);
        }
    );
    }
    } else {
      if (sessionStorage.getItem('projects_dev_' + this.authUser?.id) != null) {
      const projects = JSON.parse(sessionStorage.getItem('projects_dev_' + this.authUser?.id));
      this.devProjects = projects;
      this.devfilteredProjects = projects.sort((a: any, b: any) => compare(a.progress, b.progress, false));
      loaderHide(this._loaderService);
      this.devProjectsApi.setRowData(this.devfilteredProjects);
      this.devProjectsApi.setDomLayout('autoHeight');
      this.devProjectsApi.resetRowHeights();
      const allColIds = params.columnApi.getAllColumns()
              .map(column => {
                if (!this.escapedColumns.includes(column.colId)) {
                  return column.colId;
                }
              });
      params.columnApi.autoSizeColumns(allColIds.filter(item => item !== undefined));
      this.devProjectsLoaded = true;
      this.allProjectLoadedListenner.next(this.devProjectsLoaded && this.exeProjectsLoaded);
    } else {
          this._projectService.projectsByType('dev', this.showMy ? 'my' : 'all').subscribe(
        (projects: []) => {
          sessionStorage.setItem('projects_dev_' + this.authUser?.id, JSON.stringify(projects));
          this.devProjects = projects;
          this.devfilteredProjects = projects.sort((a: any, b: any) => compare(a.progress, b.progress, false));
          loaderHide(this._loaderService);
          this.devProjectsApi.setRowData(this.devfilteredProjects);
          this.devProjectsApi.setDomLayout('autoHeight');
          this.devProjectsApi.resetRowHeights();
          const allColIds = params.columnApi.getAllColumns()
              .map(column => {
                if (!this.escapedColumns.includes(column.colId)) {
                  return column.colId;
                }
              });
          params.columnApi.autoSizeColumns(allColIds.filter(item => item !== undefined));
          this.devProjectsLoaded = true;
          this.allProjectLoadedListenner.next(this.devProjectsLoaded && this.exeProjectsLoaded);
        }
    );
    }
    }

  }

  onCompletedProjectsReady(params) {
    this.completedProjectsApi = params.api;
    this.completedProjectsColumnApi = params.columnApi;
    this.completedProjects = {
      dev: [], exe: []
    };
    loaderShow(this._loaderService);
    this._projectService.projectsByStatus('completed').subscribe(
        (projects: { dev: [], exe: [] }) => {
          this.completedProjects = projects;
          loaderHide(this._loaderService);
          this.completedProjectsApi.setRowData(this.completedProjects.exe);
          this.completedProjectsApi.setDomLayout('autoHeight');
          const allColIds = params.columnApi.getAllColumns()
              .map(column => {
                if (!this.escapedColumns.includes(column.colId)) {
                  return column.colId;
                }
              });
          params.columnApi.autoSizeColumns(allColIds);
          this.projectsInfo = globaldata(this.completedProjects.exe, 'completed');
        }
    );
  }

  onCanceledProjectsReady(params) {
    this.canceledProjectsApi = params.api;
    this.canceledProjectsColumnApi = params.columnApi;
    this.canceledProjects = [];
    loaderShow(this._loaderService);
    this._projectService.projectsByStatus('canceled').subscribe(
        (projects: { dev: [], exe: [] }) => {
          if (!isEmpty(projects.dev)) {
            this.canceledProjects.push(...projects.dev);
          }
          if (!isEmpty(projects.exe)) {
            this.canceledProjects.push(...projects.exe);
          }
          this.canceledProjectsApi.setRowData(this.canceledProjects);
          loaderHide(this._loaderService);
          this.canceledProjectsApi.setDomLayout('autoHeight');
          this.canceledProjectsApi.sizeColumnsToFit();
          this.projectsInfo = globaldata(this.canceledProjects, 'canceled');
        }
    );
  }

  public nextColumns(columnApi, api): void {
    const allCols = this[columnApi].getAllDisplayedVirtualColumns();
    this[api].ensureColumnVisible(this[columnApi].getDisplayedColAfter(allCols[allCols.length - 3]));
  }

  public previousColumns(columnApi, api): void {
    const allCols = this[columnApi].getAllDisplayedVirtualColumns();
    this[api].ensureColumnVisible(this[columnApi].getDisplayedColBefore(allCols[1]));
  }

  exportExeAsCsv() {
    this.exeProjectsApi.exportDataAsCsv({skipHeader: false, columnGroups: true, allColumns: false, fileName: 'project_list_exe.csv'});
  }

  exportDevAsCsv() {
    this.devProjectsApi.exportDataAsCsv({columnGroups: true, allColumns: true, fileName: 'project_list_dev.csv'});
  }

  exportCompleteAsCsv() {
    this.completedProjectsApi.exportDataAsCsv({
      skipHeader: false,
      columnGroups: true,
      allColumns: false,
      fileName: 'project_list_completed.csv'
    });
  }

  exportCanceledAsCsv() {
    this.canceledProjectsApi.exportDataAsCsv({
      skipHeader: false,
      columnGroups: true,
      allColumns: false,
      fileName: 'project_list_canceled.csv'
    });
  }

  exportAll() {
    const exeSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.prepareExeSheet(this.exefilteredProjects));
    const devSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.prepareDevSheet(this.devfilteredProjects));

    const excelWorkBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(excelWorkBook, exeSheet, 'EXE');
    XLSX.utils.book_append_sheet(excelWorkBook, devSheet, 'DEV');

    XLSX.writeFile(excelWorkBook, 'ProjectsList.xlsx');
  }

  exportCompleted() {
    const exeSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.prepareExeSheet(this.completedProjects.exe));

    const excelWorkBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(excelWorkBook, exeSheet, 'COMPLETED');

    XLSX.writeFile(excelWorkBook, 'CompletedProjects.xlsx');
  }

  exportCanceled() {
    const canceledSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.prepareDevSheet(this.canceledProjects));

    const canceledWorkBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(canceledWorkBook, canceledSheet, 'CANCELED');

    XLSX.writeFile(canceledWorkBook, 'CanceledProjects.xlsx');
  }

  prepareExeSheet(projects) {
    const rows = [
      ['MS INDUSTRIAL PROJECTS VISUAL REPORTING - REPORT'],
      ['EXECUTION PHASE'],
      [''],
      ['Export Date', moment().format('DD/MM/YYY')],
      [

        'INTRODUCTION', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        'DESCRIPTION', '', '', '', '', '', '',
        'MONTHLY REPORT'
      ],
      [

        'PROJECT GENERAL DATA', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        'CONTACTS', '', '', '', '', '', '', '',
        'DESCRIPTION', 'CONTRACTUAL STRATEGY/CONTRACTORS', '', '', '', '', '',
        '', '', '', 'HSE', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        'COST k$', '', '', '', '', '', '', '', '', '', '', '', '', '',
        'PROGRESS & SCHEDULE_MILESTONES', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        '', '', '', '',
        'PROGRESS & SCHEDULE_PROGRESS UP TO RFSU', '', '', '',
        'QUALITY', '', '', '', '', '', '',
        'HIGHLIGHTS OF THE MONTH'
      ],
      [
        'Project name', 'Status', 'Zone', 'Operational Direction', 'Country', 'Location', 'Affiliate', 'Longitude', 'Latitude',
        'Business Line', 'JV', 'TOTAL share', 'JV Partners', 'TOTAL role', 'Project number (Design office)',
        // CONTACTS
        'Project Architect', 'Project Manager', 'Focal point Affiliate', 'Focal point zone',
        'Focal point DTP', 'Focal point Business Line', 'Focal point HSE', 'Focal point purchasing',
        // DESCRIPTION & CONTRACTUAL STRATEGY/CONTRACTORS
        'Short description', 'Contractual strategy', 'Engineering contractors_Tendering',
        'Engineerings contractors_Awarded', 'Execution contractors_Tendering', 'Execution contractors_Awarded',
        'Key Subcontractors',
        // MONTHLY PROGRESS & HSE
        'Month', 'Step', 'Cut off date', 'Current month_FAT', 'Current month_PDC+LTI',
        'Current month_RWC+MT', 'Current month_FAC', 'Current month_HIPO', 'Current month_Near misses', 'Current month_Anomalies',
        'Current month_Number of safety meetings', 'Current month_Total manhours', 'Current month_Manpower on site (daily average)',
        'Since project start_FAT', 'Since project start_PDC+LTI', 'Since project start_RWC+MT', 'Since project start_FAC',
        'Since project start_HIPO', 'Since project start_Near misses', 'Since project start_Anomalies',
        'Since project start_Number of safety meetings', 'Since project start_Total manhours', 'Since project start_LTIR',
        'Since project start_TRIR', 'Date of last LTI', 'Comments',
        // COSTS
        'Total project CAPEX approved at FID', 'Total development cost pre-FID', 'Current month (cumulative)_EFC',
        'Current month (cumulative)_Committed', 'Current month (cumulative)_Performed', 'Previous month (cumulative)_EFC',
        'Previous month (cumulative)_Committed', 'Previous month (cumulative)_Performed', 'Current month_EFC',
        'Current month_Committed', 'Current month_Performed', 'Change orders_requested by contractors',
        'Change orders_approved by Company', 'Comments',
        // PROGRESS & SCHEDULE MILESTONES
        'Initial at FID_Effective date of contracts', 'Initial at FID_Start construction', 'Initial at FID_Mechanical completion',
        'Initial at FID_RFSU', 'As per contracts_Effective date of contracts', 'As per contracts_Start construction',
        'As per contracts_Mechanical completion', 'As per contracts_RFSU', 'Revised/ Forecast_Effective date of contracts',
        'Revised/ Forecast_Start construction', 'Revised/ Forecast_Mechanical completion', 'Revised/ Forecast_RFSU',
        'Revised/ Forecast_Closure REX', 'Actual_Gate FEASIBILITY', 'Actual_PR1', 'Actual_Gate BASIC', 'Actual_Gate FEED',
        'Actual_Gate CFT', 'Actual_PR2', 'Actual_FID', 'Actual_Effective date of contracts', 'Actual_Start construction',
        'Actual_Mechanical completion', 'Actual_RFSU', 'Actual_Closure REX',
        // PROGRESS UP TO RFSU
        'Initial_progress %', 'Revised/ Forecast_Progress %', 'Actual_progress %', 'Comments',
        // QUALITY
        'Number of derogations (cumulative)_issued', 'Number of derogations (cumulative)_accepted',
        'Number of derogations (cumulative)_rejected', 'Number of derogations (cumulative)_pending',
        'Number of NCRs (cumulative)_raised', 'Number of NCRs (cumulative)_closed', 'Comments',
        // HIGHLIGHTS
        'Main events', 'Next Steering committe', 'Next milestone', 'Next milestone_date',
        'Project ID', 'Monthly Report ID'
      ]
    ];

    projects.forEach(
        (project) => {
          rows.push(this.getExeRowOfProject(project));
        }
    );
    return rows;
  }

  prepareDevSheet(projects) {
    const rows = [
      ['MS INDUSTRIAL PROJECTS VISUAL REPORTING - REPORT'],
      ['EXECUTION PHASE'],
      [''],
      ['Export Date', moment().format('DD/MM/YYY')],
      [
        'INTRODUCTION', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        'DESCRIPTION', '', '', '', '', '', '',
        'MONTHLY REPORT'
      ],
      [

        'PROJECT GENERAL DATA', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        'CONTACTS', '', '', '', '', '', '', '',
        'DESCRIPTION', 'CONTRACTUAL STRATEGY/CONTRACTORS', '', '', '', '', '',
        '', '', '', 'HSE', '', '', '', '', '', '',
        'COST k$', '', '', '', '', '', '', '', '', '', '', '',
        'PROGRESS & SCHEDULE_MILESTONES', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        'PROGRESS & SCHEDULE_PROGRESS UP TO FID', '', '', '',
        'QUALITY', '', '', '', '',
        'HIGHLIGHTS OF THE MONTH'
      ],
      [
        'Project name', 'Status', 'Zone', 'Operational Direction', 'Country', 'Location', 'Affiliate', 'Longitude', 'Latitude',
        'Business Line', 'JV', 'TOTAL share', 'JV Partners', 'TOTAL role', 'Project number (Design office)',
        // CONTACTS
        'Project Architect', 'Project Manager', 'Focal point Affiliate', 'Focal point zone',
        'Focal point DTP', 'Focal point Business Line', 'Focal point HSE', 'Focal point purchasing',
        // DESCRIPTION & CONTRACTUAL STRATEGY/CONTRACTORS
        'Short description', 'Contractual strategy', 'Engineering contractors_Tendering',
        'Engineerings contractors_Awarded', 'Execution contractors_Tendering', 'Execution contractors_Awarded',
        'Key Subcontractors',
        // MONTHLY PROGRESS & HSE
        'Month', 'Step', 'Cut off date',
        'Issued_HAZAN', 'Issued_HAZID', 'Issued_HAZOP', 'Issued_TRA', 'Issued_EBS', 'Issued_EIA', 'Comments',
        // COSTS
        'Approved_development studies cost', 'Approved_land plot acquizition cost', 'Approved_total development cost',
        'Estimated_development studies cost', 'Estimated_land plot acquizition cost', 'Estimated_total development cost',
        'Performed_development studies cost', 'Performed_land plot acquizition cost', 'Performed_total development cost',
        'Total project CAPEX estimated', 'Accuracy %', 'Comments',
        // PROGRESS & SCHEDULE MILESTONES
        'Initial_Gate FEASIBILITY', 'Initial_PR1', 'Initial_CDMS/ CODIR (post PR1)', 'Initial_Gate BASIC',
        'Initial_BASIC 30%', 'Initial_BASIC 60%', 'Initial_Gate FEED', 'Initial_FEED 30%', 'Initial_FEED 60%',
        'Initial_Gate CFT', 'Initial_Offers receipt', 'Initial_PR2', 'Initial_CDMS/ CODIR (post PR2)', 'Initial_FID',
        'Revised/ Forecast_Gate FEASIBILITY', 'Revised/ Forecast_PR1', 'Revised_CDMS/ CODIR (post PR1)', 'Revised/ Forecast_Gate BASIC',
        'Revised/ Forecast_BASIC 30%', 'Revised/ Forecast_BASIC 60%', 'Revised/ Forecast_Gate FEED', 'Revised/ Forecast_FEED 30%',
        'Revised/ Forecast_FEED 60%', 'Revised/ Forecast_Gate CFT', 'Revised/ Forecast_Offers receipt', 'Revised/ Forecast_PR2',
        'Revised_CDMS/ CODIR (post PR2)', 'Revised/ Forecast_FID', 'Revised/ Forecast_RFSU', 'Actual_Gate FEASIBILITY', 'Actual_PR1',
        'Actual_CDMS/ CODIR (post PR1)', 'Actual_Gate BASIC', 'Actual_BASIC 30%', 'Actual_BASIC 60%', 'Actual_Gate FEED', 'Actual_FEED 30%',
        'Actual_FEED 60%', 'Actual_Gate CFT', 'Actual_Offers receipt', 'Actual_PR2',
        'Actual_CDMS/ CODIR (post PR2)', 'Actual_FID',
        // PROGRESS UP TO FID
        'Initial_progress %', 'Revised/ Forecast_Progress %', 'Actual_progress %', 'Comments',
        // QUALITY
        'Number of derogations (cumulative)_issued', 'Number of derogations (cumulative)_accepted',
        'Number of derogations (cumulative)_rejected', 'Number of derogations (cumulative)_pending',
        'Comments',
        // HIGHLIGHTS
        'Main events', 'Next Steering committe', 'Next milestone', 'Next milestone_date',
        'Project ID', 'Monthly Report ID'
      ]
    ];

    projects.forEach(
      (project) => {
       // console.log(project);
        rows.push(this.getDevRowOfProject(project));
        }
    );
    return rows;
  }

  getArchitect(params) {
    const project = params.data;
    return getContact(project, 'architect');
  }

  getManager(params) {
    const project = params.data;
    return getContact(project, 'manager');
  }

  getDevRowOfProject(project) {
    return [
      project.title, project.status, this.getZone({data: project}), project.operationalDirection.label_en, project.country.label_en,
      project.location, project.affiliate, project.longitude, project.latitude, project.businessLine.label_en, this.getJV(project),
      project.total_share, project.jv_partners, project.total_role, project.projectnumber_designoffice,
      this.getUserName(project.architect), this.getUserName(project.manager),
      this.getUserName(project.fp_affiliate), this.getUserName(project.fp_zone), this.getUserName(project.fp_slp_tp),
      this.getUserName(project.fp_business_line), this.getUserName(project.fp_hse), this.getUserName(project.fp_purchasing),
      project.short_description, project.contractual_strategy, project.eng_contract_tendering, project.eng_contract_awarded,
      project.exe_contract_tendering, project.exe_contract_awarded, project.key_subcontract, this.getUpdatedAt({data: project}),
      this.getProjectStep(project), (!isNil(project.cut_off_date) ? moment.utc(project.cut_off_date).format('DD/MM/YYYY') : ''),
      project.hse?.hazan, project.hse?.hazid, project.hse?.hazop, project.hse?.tra, project.hse?.ebs, project.hse?.eia,
      project.hse?.description?.replace(/<[^>]*>/g, ''),
      project.costs?.dsc_approved ? 1000 * project.costs?.dsc_approved : null,
      project.costs?.lpac_approved ? 1000 * project.costs?.lpac_approved : null,
      project.costs?.total_dev_cost_approved ? 1000 * project.costs?.total_dev_cost_approved : null,
      project.costs?.dsc_estimated ? 1000 * project.costs?.dsc_estimated : null,
      project.costs?.lpac_estimated ? 1000 * project.costs?.lpac_estimated : null,
      project.costs?.total_dev_cost_estimated ? 1000 * project.costs?.total_dev_cost_estimated : null,
      project.costs?.dsc_performed ? 1000 * project.costs?.dsc_performed : null,
      project.costs?.lpac_performed ? 1000 * project.costs?.lpac_performed : null,
      project.costs?.total_dev_cost_performed ? 1000 * project.costs?.total_dev_cost_performed : null,
      project.project_step === PROJECT_STEP_ITEM.NEEDS ? project.cost * 1000 :
      project.costs?.total_project_capex_estimated_amount ? 1000 * project.costs?.total_project_capex_estimated_amount : null,
      project.costs?.total_project_capex_estimated_accuracy,
      project.costs?.description?.replace(/<[^>]*>/g, ''),
      this.getMilestoneOfProjectDev(project, 0, 'initial'), this.getMilestoneOfProjectDev(project, 1, 'initial'),
      this.getMilestoneOfProjectDev(project, 2, 'initial'), this.getMilestoneOfProjectDev(project, 3, 'initial'),
      this.getMilestoneOfProjectDev(project, 4, 'initial'), this.getMilestoneOfProjectDev(project, 5, 'initial'),
      this.getMilestoneOfProjectDev(project, 6, 'initial'), this.getMilestoneOfProjectDev(project, 7, 'initial'),
      this.getMilestoneOfProjectDev(project, 8, 'initial'), this.getMilestoneOfProjectDev(project, 9, 'initial'),
      this.getMilestoneOfProjectDev(project, 10, 'initial'), this.getMilestoneOfProjectDev(project, 11, 'initial'),
      this.getMilestoneOfProjectDev(project, 12, 'initial'), this.getMilestoneOfProjectDev(project, 13, 'initial'),
      this.getMilestoneOfProjectDev(project, 0, 'revised'), this.getMilestoneOfProjectDev(project, 1, 'revised'),
      this.getMilestoneOfProjectDev(project, 2, 'revised'), this.getMilestoneOfProjectDev(project, 3, 'revised'),
      this.getMilestoneOfProjectDev(project, 4, 'revised'), this.getMilestoneOfProjectDev(project, 5, 'revised'),
      this.getMilestoneOfProjectDev(project, 6, 'revised'), this.getMilestoneOfProjectDev(project, 7, 'revised'),
      this.getMilestoneOfProjectDev(project, 8, 'revised'), this.getMilestoneOfProjectDev(project, 9, 'revised'),
      this.getMilestoneOfProjectDev(project, 10, 'revised'), this.getMilestoneOfProjectDev(project, 11, 'revised'),
      this.getMilestoneOfProjectDev(project, 12, 'revised'), this.getMilestoneOfProjectDev(project, 13, 'revised'),
      this.getMilestoneOfProjectDev(project, 14, 'revised'),
      this.getMilestoneOfProjectDev(project, 0, 'actual'), this.getMilestoneOfProjectDev(project, 1, 'actual'),
      this.getMilestoneOfProjectDev(project, 2, 'actual'), this.getMilestoneOfProjectDev(project, 3, 'actual'),
      this.getMilestoneOfProjectDev(project, 4, 'actual'), this.getMilestoneOfProjectDev(project, 5, 'actual'),
      this.getMilestoneOfProjectDev(project, 6, 'actual'), this.getMilestoneOfProjectDev(project, 7, 'actual'),
      this.getMilestoneOfProjectDev(project, 8, 'actual'), this.getMilestoneOfProjectDev(project, 9, 'actual'),
      this.getMilestoneOfProjectDev(project, 10, 'actual'), this.getMilestoneOfProjectDev(project, 11, 'actual'),
      this.getMilestoneOfProjectDev(project, 12, 'actual'), this.getMilestoneOfProjectDev(project, 13, 'actual'),
      this.getDevPercentage(project, 'initial'), this.getDevPercentage(project, 'revised'),
      this.getDevPercentage(project, 'actual'), project.progress_schedule_description?.replace(/<[^>]*>/g, ''),
      project.quality?.issued, project.quality?.accepted, project.quality?.rejected, project.quality?.pending,
      project.quality?.description?.replace(/<[^>]*>/g, ''),
      project.highlights?.main_events, this.getDateOfProjectExe(project, 'highlights', 'next_steering_commitee'),
      project.highlights?.next_milistone_type, this.getDateOfProjectExe(project, 'highlights', 'next_milistone_date'),
      project.id, project.mp_id
    ];
  }

  getExeRowOfProject(project) {
    return [
      project.title, project.status, this.getZone({data: project}), project.operationalDirection.label_en, project.country.label_en,
      project.location, project.affiliate, project.longitude, project.latitude, project.businessLine.label_en, this.getJV(project),
      project.total_share, project.jv_partners, project.total_role, project.projectnumber_designoffice,
      this.getUserName(project.architect), this.getUserName(project.manager),
      this.getUserName(project.fp_affiliate), this.getUserName(project.fp_zone), this.getUserName(project.fp_slp_tp),
      this.getUserName(project.fp_business_line), this.getUserName(project.fp_hse), this.getUserName(project.fp_purchasing),
      project.short_description, project.contractual_strategy, project.eng_contract_tendering, project.eng_contract_awarded,
      project.exe_contract_tendering, project.exe_contract_awarded, project.key_subcontract, this.getUpdatedAt({data: project}),
      'EXECUTION', (!isNil(project.cut_off_date) ? moment.utc(project.cut_off_date).format('DD/MM/YYYY') : ''),
      project.hse?.fat_this_month, project.hse?.pdclti_this_month, project.hse?.rwcmt_this_month, project.hse?.fac_this_month,
      project.hse?.hipo_this_month, project.hse?.nearmisses_this_month, project.hse?.anomalies_this_month, project.hse?.nostl_this_month,
      project.hse?.totalmanhours_this_month, project.hse?.man_power_on_site, project.hse?.fat_since_project_start,
      project.hse?.pdclti_since_project_start, project.hse?.rwcmt_since_project_start, project.hse?.fac_since_project_start,
      project.hse?.hipo_since_project_start, project.hse?.nearmisses_since_project_start, project.hse?.anomalies_since_project_start,
      project.hse?.nostl_since_project_start, project.hse?.totalmanhours_since_project_start,
      this.getProjectLTIR(project), this.getProjectTRIR(project),
      this.getDateOfProjectExe(project, 'hse', 'dateoflti_this_month'), project.hse?.description?.replace(/<[^>]*>/g, ''),
      project.costs?.total_dev_cost_performed ? 1000 * project.costs?.total_dev_cost_performed : null,
      project.costs?.total_dev_cost_prefid ? 1000 * project.costs?.total_dev_cost_prefid : null,
      project.costs?.efc_this_month ? 1000 * project.costs?.efc_this_month : null,
      project.costs?.commited_this_month ? 1000 * project.costs?.commited_this_month : null,
      project.costs?.performed_this_month ? 1000 * project.costs?.performed_this_month : null,
      project.costs?.efc_previous_month ? 1000 * project.costs?.efc_previous_month : null,
      project.costs?.commited_previous_month ? 1000 * project.costs?.commited_previous_month : null,
      project.costs?.performed_previous_month ? 1000 * project.costs?.performed_previous_month : null,
      project.costs?.efc_current_month ? 1000 * project.costs?.efc_current_month : null,
      project.costs?.commited_current_month ? 1000 * project.costs?.commited_current_month : null,
      project.costs?.performed_current_month ? 1000 * project.costs?.performed_current_month : null,
      project.costs?.total_amount_requested ? 1000 * project.costs?.total_amount_requested : null,
      project.costs?.total_amount_requested ? 1000 * project.costs?.total_amount_requested : null,
      project.costs?.description?.replace(/<[^>]*>/g, ''),
      this.getDateOfProjectExe(project, 'milestones', 'effective_date_at_fid'),
      this.getDateOfProjectExe(project, 'milestones', 'start_construction_at_fid'),
      this.getDateOfProjectExe(project, 'milestones', 'mechanical_completition_at_fid'),
      this.getDateOfProjectExe(project, 'milestones', 'rfsu_at_fid'),
      this.getDateOfProjectExe(project, 'milestones', 'effective_date_as_per_contracts'),
      this.getDateOfProjectExe(project, 'milestones', 'start_construction_as_per_contracts'),
      this.getDateOfProjectExe(project, 'milestones', 'mechanical_completition_as_per_contracts'),
      this.getDateOfProjectExe(project, 'milestones', 'rfsu_as_per_contracts'),
      this.getDateOfProjectExe(project, 'milestones', 'effective_date_forecast'),
      this.getDateOfProjectExe(project, 'milestones', 'start_construction_forecast'),
      this.getDateOfProjectExe(project, 'milestones', 'mechanical_completion_forecast'),
      this.getDateOfProjectExe(project, 'milestones', 'rfsu_forecast'),
      this.getDateOfProjectExe(project, 'milestones', 'closure_rex_forecast'),
      this.getDateOfProjectExe(project, 'milestones', 'gate_feasibility'),
      this.getDateOfProjectExe(project, 'milestones', 'pr1'),
      this.getDateOfProjectExe(project, 'milestones', 'gate_basic'),
      this.getDateOfProjectExe(project, 'milestones', 'gate_feed'),
      this.getDateOfProjectExe(project, 'milestones', 'gate_cft'),
      this.getDateOfProjectExe(project, 'milestones', 'pr2'),
      this.getDateOfProjectExe(project, 'milestones', 'fid'),
      this.getDateOfProjectExe(project, 'milestones', 'effective_date_actual'),
      this.getDateOfProjectExe(project, 'milestones', 'start_construction_actual'),
      this.getDateOfProjectExe(project, 'milestones', 'mechanical_completition_actual'),
      this.getDateOfProjectExe(project, 'milestones', 'rfsu_actual'),
      this.getDateOfProjectExe(project, 'milestones', 'closure_rex_actual'),
      this.getExePercentage(project, 'initials'), this.getExePercentage(project, 'forecasts'),
      this.getExePercentage(project, 'actuals'), project.progress_schedule_description?.replace(/<[^>]*>/g, ''),
      project.quality?.issued, project.quality?.accepted, project.quality?.rejected, project.quality?.pending,
      project.quality?.ncr_raised, project.quality?.ncr_rejected, project.quality?.description?.replace(/<[^>]*>/g, ''),
      project.highlights?.main_events, this.getDateOfProjectExe(project, 'highlights', 'next_steering_commitee'),
      project.highlights?.next_milistone_type, this.getDateOfProjectExe(project, 'highlights', 'next_milistone_date'),
      project.id, project.mp_id
    ];
  }

  getProjectStep(project) {
    if (!isNil(project.period)) {
      return project.project_step;
    } else {
      return 'Draft';
    }
  }

  getProjectLTIR(project) {
    return !isNil(project.hse) ? (project.hse?.totalmanhours_since_project_start && project.hse?.totalmanhours_since_project_start !== 0 ?
        ((project.hse?.fat_since_project_start + project.hse?.pdclti_since_project_start)
    / project.hse?.totalmanhours_since_project_start as number) * 1000000 : null) : null;
  }

  getProjectTRIR(project) {
    return !isNil(project.hse) ? (project.hse?.totalmanhours_since_project_start && project.hse?.totalmanhours_since_project_start !== 0 ?
        ((project.hse?.fat_since_project_start + project.hse?.pdclti_since_project_start + project.hse?.rwcmt_since_project_start)
    / project.hse?.totalmanhours_since_project_start as number) * 1000000 : null) : null;
  }

  getExePercentage(project, type) {
    let res = 0;
    if (!isNil(project.milestones)) {
      const percentages = project.milestones[type];
      if (!isNil(percentages)) {
        percentages.forEach(
            (percentage) => {
              if (!isNil(percentage.value)) {
                res = percentage.value;
              }
            }
        );
      }
    }
    return res;
  }

  getDevPercentage(project, type) {
    let result = 0;
    const milestones = project.milestones;
    if (!isNil(milestones)) {
      milestones.forEach(
          (milestone: IMilistones, index) => {
            switch (index) {
              case 0: // GATE FEASABILITY
                result = !isNil(milestone[type]) ? 2 : result;
                break;
              case 1: // PR1
              case 2: // CDMS File (FEASABILITY)
                result = !isNil(milestone[type]) ? 8 : result;
                break;
              case 3: // GATE BASIC
                result = !isNil(milestone[type]) ? 10 : result;
                break;
              case 4: // BASIC 30%
                result = !isNil(milestone[type]) ? 18 : result;
                break;
              case 5: // BASIC 60%
                result = !isNil(milestone[type]) ? 26 : result;
                break;
              case 6: // GATE FEED
                result = !isNil(milestone[type]) ? 35 : result;
                break;
              case 7: // FEED 30%
                result = !isNil(milestone[type]) ? 51 : result;
                break;
              case 8: // FEED 60%
                result = !isNil(milestone[type]) ? 67 : result;
                break;
              case 9: // GATE CFT
                result = !isNil(milestone[type]) ? 85 : result;
                break;
              case 10: // Offers receipt
                result = !isNil(milestone[type]) ? 95 : result;
                break;
              case 11: // PR2
              case 12: // CDMS File (CFT)
                result = !isNil(milestone[type]) ? 99 : result;
                break;
              case 13: // FID
                result = !isNil(milestone[type]) ? 100 : result;
                break;
              default: // RFSU
                break;
            }
          }
      );
    }

    return result;
  }

  getDateOfProjectExe(project, type, date) {
    const dataType = project[type] ? project[type] : null;
    if (!isNil(dataType)) {
      const dateOfHse = dataType[date];
      if (!isNil(dateOfHse)) {
        return moment.utc(dateOfHse).format('DD/MM/YYYY');
      }
    }
    return '';
  }

  getMilestoneOfProjectDev(project, index, type) {
    const milestones = project.milestones;
    if (!isNil(milestones)) {
      const date = milestones[index][type];
      if (!isNil(date)) {
        return moment.utc(date).format('DD/MM/YYYY');
      }
    }
    return '';
  }

  showCanceled() {
    this.showCanceledProjects = !this.showCanceledProjects;
    this.showCompletedProjects = false;
  }

  showCompleted() {
    this.showCompletedProjects = !this.showCompletedProjects;
    this.showCanceledProjects = false;
  }

  filterProjects(data) {
    this.exefilteredProjects = data.filter(project => project.phase === 'EXE');
    this.exeProjectsApi.setRowData(data.filter(project => project.phase === 'EXE'));
    this.devfilteredProjects = data.filter(project => (project.phase === 'DEV' || !project.phase));
    this.devProjectsApi.setRowData(data.filter(project => (project.phase === 'DEV' || !project.phase)));
    this.projectsInfo = globaldata(data);
  }

  getUserName(user) {
    return user ? (user.first_name + ' ' + user.last_name) : '';
  }

  getJV(project) {
    return isNil(project.jv) ? '' : (project.jv === 1 ? 'Yes' : 'No');
  }

}

export function getDateFromStep(params, step) {
  const milestones = params.data ? params.data.milestones : null;
  if (!isNil(milestones) && isArray(milestones)) {
    return milestones[step].actual ? moment.utc(milestones[step].actual).format('DD/MM/YYYY') :
        (milestones[step].revised ? moment.utc(milestones[step].revised).format('DD/MM/YYYY') : '');
  }
  return '';
}

export function getDateRendererFromStep(params, step) {
  const milestones = params.data ? params.data.milestones : null;
  if (!isNil(milestones) && isArray(milestones)) {
    return milestones[step].actual ? 'bold' :
        (milestones[step].revised ? 'italic' : '');
  }
  return params.value;
}

export function getExeDateFromStep(params, step) {
  const milestones = params.data ? params.data.milestones : null;
  if (!isNil(milestones) && !isArray(milestones)) {
    return !isNil(milestones[step + '_actual']) ? moment.utc(milestones[step + '_actual']).format('DD/MM/YYYY') :
        (!isNil(milestones[step + '_forecast']) ? moment.utc(milestones[step + '_forecast']).format('DD/MM/YYYY') :
            (!isNil(milestones[step]) ? moment.utc(milestones[step]).format('DD/MM/YYYY') : ''));
  }
  return '';
}

export function getExeDateRendererFromStep(params, step) {
  const milestones = params.data ? params.data.milestones : null;
  if (!isNil(milestones) && !isArray(milestones)) {
    return !isNil(milestones[step + '_actual']) ? 'bold' :
        (!isNil(milestones[step + '_forecast']) ? 'italic' : '');
  }
  return params.value;
}

export function compareExeDates(paramA, paramB, date) {
  const dateA = getExeDateFromStep(paramA, date);
  const dateB = getExeDateFromStep(paramB, date);

  if ((dateA === '' && dateB === '') ||
      (isNil(dateA) && isNil(dateB))) {
    return 0;
  }
  if (dateA === '' || isNil(dateA)) {
    return -1;
  }
  if (dateB === '' || isNil(dateB)) {
    return 1;
  }
  return moment.utc(dateA, 'DD/MM/YYYY').diff(moment.utc(dateB, 'DD/MM/YYYY'));
}

export function getContact(project, type) {
  return !isNil(project[type]) ? (project[type].first_name + ' ' + project[type].last_name) : '';
}
