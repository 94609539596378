import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DialogComponent} from '../components/dialog/dialog.component';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MsgService {

    modalClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar
    ) {
    }

    showPrompt(title: string, text: string, value: string) {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: {title, text, value, type: 'prompt'}
        });

        return dialogRef;
    }

    showConfirm(title: string, text: string) {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: {title, text, value: true, type: 'confirm'}
        });

        return dialogRef;
    }

    showAlert(title: string, text: string) {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: {title, text, type: 'alert'}
        });

        dialogRef.afterClosed().subscribe( _ => this.modalClicked.next(true));

        return dialogRef;
    }

    showErrorMsg(text: string, error: any) {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: {title: 'Application Error', text, type: 'alert'}
        });

        return dialogRef;
    }

    showSnackBar(text: string) {
        this.snackBar.open(text, 'Close', {
            duration: 5000,
        });
    }
}
