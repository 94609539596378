import {Routes} from '@angular/router';
import {LoggedInGuard} from '@ff/core';
import {HomeComponent} from './components/home/home.component';
import {ProjectListComponent} from '../project/components/project-list/project-list.component';
import { IsAdminGuard } from '../_guards/is-admin.guard';
import {ProjectContactsComponent} from '../project/components/project-contacts/project-contacts.component';
// import {MapComponent} from '../project-map/components/map/map.component';
// import {MeetingsComponent} from '../meeting-calendar/components/meetings/meetings.component';
import {ProjectConsultationComponent} from '../project/components/project-consultation/project-consultation.component';
// import { ZonesResolver } from '../dataviz/resolvers/zones.resolvers';
// import { OperationalsResolver } from '../dataviz/resolvers/operations.resolvers';
// import { CountriesResolver } from '../dataviz/resolvers/countries.resolvers';
// import { BusineslinesResolver } from '../dataviz/resolvers/businesslines.resolvers';
import { DatavizProjectListResolver } from '../dataviz/resolvers/projectlists.resolvers';

export const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: { title: 'Home' },
     resolve: {
                    projects: DatavizProjectListResolver
                }
  },

  {
    path: 'admin',
    loadChildren: () => import('../administration/administration.module').then(m => m.AdministrationModule),
    canActivate: [LoggedInGuard, IsAdminGuard],
    data: {title: 'ADMIN'}
  },
  {
    path: 'project',
    loadChildren: () => import('../project/project.module').then(m => m.ProjectModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'project/list',
    component: ProjectListComponent,
    canActivate: [LoggedInGuard],
    data: {title: 'Project list'}
  },
  {
    path: 'project/contacts',
    component: ProjectContactsComponent,
    canActivate: [LoggedInGuard],
    data: {title: 'Project contacts'}
  },
  {
    path: 'project/consultation/:id',
    canActivate: [LoggedInGuard],
    component: ProjectConsultationComponent,
    data : {title: 'Consultation'}
  },
  {
    path: 'project/map',
    loadChildren: () => import('../project-map/project-map.module').then(m => m.ProjectMapModule),
    canActivate: [LoggedInGuard],
    data: {title: 'Project map'}
  },
   {
     path: 'dataviz',
     loadChildren: () => import('../dataviz/dataviz.module').then(m => m.DatavizModule),
    canActivate: [LoggedInGuard],
    data: { title: 'Dataviz' },
   },
  {
    path: 'synthese',
    loadChildren: () => import('../synthese/synthese.module').then(m => m.SyntheseModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'calendar',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('../meeting-calendar/meeting-calendar.module').then(m => m.MeetingCalendarModule),
  },

  // otherwise redirect to home
  {path: '**', redirectTo: '/'}
];
