<!-- ff-theme is an invisible component that applies "Total" CSS theme from @ff/angular-core -->

<mat-sidenav-container class="h-100">
  <mat-sidenav #nav position="end" mode="over">
    <app-navigation></app-navigation>
  </mat-sidenav>
  <mat-sidenav-content class="d-flex flex-column">
    <app-header [nav]="nav"></app-header>
    <main>
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
