import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {FormsModule} from '@angular/forms';
import {LoaderModule} from '@ff/loader';
import {CoreModule} from '@ff/core';
import {DialogComponent} from './components/dialog/dialog.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatSelectModule} from '@angular/material/select';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {HttpClient} from '@angular/common/http';
import {ZonesDoCountriesBlocComponent} from './components/zones-do-countries-bloc/zones-do-countries-bloc.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatDatepickerModule} from '@angular/material/datepicker';
// import { MatMomentDateModule, MomentDateModule } from '@angular/material-moment-adapter';
import {MonthYearPickerComponent} from './components/month-year-picker/month-year-picker.component';
import {MonthYearTableComponent} from './components/month-year-table/month-year-table.component';
import {AgClickableComponent} from './components/ag-clickable/ag-clickable.component';
import {AgClickableParentComponent} from './components/ag-clickable-parent/ag-clickable-parent.component';
import {FormatNumberDisplayDirective} from './directives/format-number-display.directive';

import {NgxEchartsModule} from 'ngx-echarts';
import {FormatPercentageDisplayDirective} from './directives/format-percentage-display.directive';
import { PercentageParentComponent } from './components/percentage-parent/percentage-parent.component';
import { PercentageComponent } from './components/percentage/percentage.component';
import { IndicatorParentComponent } from './components/indicator-parent/indicator-parent.component';
import { IndicatorComponent } from './components/indicator/indicator.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {ProjectFilterComponent} from './components/project-filter/project-filter.component';
import { ProjectModalComponent } from './components/project-modal/project-modal.component';
import { InputMaxLengthDirective } from './directives/input-max-length.directive';
import { ProjectBookmarkComponent } from './components/project-bookmark/project-bookmark.component';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    DialogComponent,
    ZonesDoCountriesBlocComponent,
    MonthYearPickerComponent,
    MonthYearTableComponent,
    AgClickableComponent,
    AgClickableParentComponent,
    FormatNumberDisplayDirective,
    FormatPercentageDisplayDirective,
    InputMaxLengthDirective,
    PercentageParentComponent,
    PercentageComponent,
    ProjectFilterComponent,
    IndicatorParentComponent,
    IndicatorComponent,
    ProjectModalComponent,
    ProjectBookmarkComponent,
  ],
  providers: [],
  imports: [

    // Angular

    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    // FF Packages
    LoaderModule,
    CoreModule,

    // Others

    // Angular Material
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    NgSelectModule,
    MatDatepickerModule,
    MatDividerModule,
    MatCheckboxModule,
    // MatMomentDateModule,
    // MomentDateModule
    NgxEchartsModule,

    TranslateModule,
    MatExpansionModule
  ],
  exports: [

    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    // FF Packages
    LoaderModule,
    CoreModule,

    // Others
    TranslateModule,

    // Angular Material
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    ZonesDoCountriesBlocComponent,
    MatDatepickerModule,
    MatDividerModule,
    MatCheckboxModule,
    // MomentDateModule,

    // Component
    MonthYearPickerComponent,
    MonthYearTableComponent,
    AgClickableParentComponent,
    PercentageParentComponent,

    FormatNumberDisplayDirective,
    FormatPercentageDisplayDirective,
    InputMaxLengthDirective,
    ProjectFilterComponent,
    ProjectBookmarkComponent
  ]
})
export class SharedModule {

  public constructor() {

  }
}
