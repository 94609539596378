import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from '../dialog/dialog.component';
import {Router} from '@angular/router';
import {PROJECT_STEP_ITEM} from '../../../model/Monthly';
import * as moment from 'moment';
import {isNil, isArray} from 'lodash';

@Component({
  selector: 'app-project-modal',
  templateUrl: './project-modal.component.html',
  styleUrls: ['./project-modal.component.scss']
})
export class ProjectModalComponent implements OnInit {

  index = 0;
  selectedProject;
  onPointProjects = [];

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any[],
      private _router: Router
  ) {
    this.selectedProject = getModalData(data[0]);
    this.onPointProjects = data;
  }

  prev() {
    if (this.index - 1 < 0) {
      this.index = this.selectedProject.onPointProjects?.length - 1;
    } else {
      this.index -= 1;
    }
    this.selectedProject = getModalData(this.onPointProjects[this.index]);
  }

  next() {
    if (this.index + 1 >= this.onPointProjects.length) {
      this.index = 0;
    } else {
      this.index += 1;
    }
    this.selectedProject = getModalData(this.onPointProjects[this.index]);
  }

  ngOnInit(): void {

  }

  synthese(id) {
    this._router.navigate(['/synthese/dev/' + id + '/view']);
  }

  contacts(id) {
    this._router.navigate(['/project/consultation/' + id]);
  }

}

export interface ProjectModalData {
  projects: ProjectData[];
}

export interface ProjectData {
  id: any;
  title: string;
  countryLabel: string;
  project_step: string;
  cost: any;
  phase: string;
  smileyCost: string;
  smileySchedule: string;
  fid: any;
  rfsu: any;
  progress: any;
  last_update: any;
}

export function getModalData(project) {
  return {
    id: project.id,
    title: project.title,
    countryLabel: project.country.label_en,
    project_step: project.project_step,
    cost: getCost(project),
    phase: project.phase,
    smileyCost: getSmiley(project.cost_perf, project.phase),
    smileySchedule: getSmiley(project.schedule_perf, project.phase),
    fid: getFID(project),
    rfsu: getRFSU(project),
    last_update: getLastUpdate(project),
    progress: project.progress,
  };
}

export function  getSmiley(percentage, phase) {
  return !isNil(percentage) ? (
      (percentage <= 0 ? 'good' :
              (0 < percentage && percentage <= (phase === 'EXE' ? 10 : 20) ? 'medium' : 'bad')
      )
  ) : '';
}

export function getCost(project) {
  if (!isNil(project)) {
    const costs = project ? project.costs : null;
    if (!isNil(costs)) {
      if (project.project_step === PROJECT_STEP_ITEM.NEEDS) {
           switch (costs.cost_range_at_need_step) {
          case '1M$':
            return '<' + costs.cost_range_at_need_step;
            break;
          case '1-4M$':
             return costs.cost_range_at_need_step;
             break;
          case '4-20M$':
            return costs.cost_range_at_need_step;
            break;
          case '20M$':
             return '>' + costs.cost_range_at_need_step;
             break;
         default: return 0;
          }
      }
      return (!isNil(costs.efc_this_month) ? costs.efc_this_month :
          (!isNil(costs.total_project_capex_estimated_amount) ? costs.total_project_capex_estimated_amount : 0))
          .toLocaleString('en-US') + ' k$';
    }
    return '';
  }
  return '';
}

export function getFID(project) {
  if (!isNil(project)) {
    const milestones = project ? project.milestones : null;
    if (!isNil(milestones) && isArray(milestones)) {
      return !isNil(milestones[13].revised) ? moment(milestones[13].revised).format('DD/MM/YYYY') : '';
    }
  }
  return '';
}

export function getRFSU(project) {
  if (!isNil(project)) {
    const milestones = project ? project.milestones : null;
    if (!isNil(milestones) && !isArray(milestones)) {
      return !isNil(milestones.rfsu_forecast) ? moment(milestones.rfsu_forecast).format('DD/MM/YYYY') : '';
    }
  }
  return '';
}

export function getLastUpdate(project) {
  if (!isNil(project?.period)) {
    return moment(project.period).format('MMM YYYY');
  }
  return 'Draft';
}
