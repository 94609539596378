import { Component } from '@angular/core';
import { ATInternetService } from '@ff/at-internet';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public constructor(
    public atInternet: ATInternetService,
    public translate: TranslateService
  ) {
    translate.setDefaultLang('en');
  }
}
