import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {isFileTypeAllowed} from '../../../../shared/models/project';
import {FormGroup} from '@angular/forms';
import {saveAs} from 'file-saver';
import {ApiService} from '../../../../shared/services/api.service';

@Component({
    selector: 'app-documents-bloc',
    templateUrl: './documents-bloc.component.html',
    styleUrls: ['./documents-bloc.component.scss']
})
export class DocumentsBlocComponent implements OnInit {
    @Input() allowedTypes = [];
    @Input() helpText = '';
    @Input() files = [];
    @Input() uploadedFiles = [];
    @Input() maxSize = 0;
    @Input() acceptedFiles = '';
    @Input() formGroup: FormGroup = null;

    @Input() canQuit: boolean;
    @Output() canQuitChange = new EventEmitter();
    @Input() canUpload = true;

    fileTypeError = false;

    constructor(
        protected _apiService: ApiService,
    ) {
    }

    ngOnInit(): void {
    }

    onFileInput(files) {
        const res = false;
        let i = 0;
        for (const file of files) {
            isFileTypeAllowed(file, res, this.allowedTypes).then(
                (isAllowed) => {
                    if (isAllowed && (this.maxSize === 0 || (this.maxSize !== 0 && file.size <= this.maxSize))) {
                        this.fileTypeError = false;
                        this.canQuitChange.emit(false);
                        this.files.push(file);
                        if (this.formGroup) {
                            this.formGroup.markAsTouched();
                        }
                        i++;
                        // this.documentsFormControl.patchValue({files: this.files});
                    } else {
                        this.fileTypeError = true;
                    }
                }
            );
        }
    }

    removeAttachment(index, list) {
        this.canQuitChange.emit(false);
        this[list].splice(index, 1);
    }

    download(file) {
        this._apiService.get('project/download-document/' + file.id, {
            responseType: 'blob', observe: 'response'
        }).subscribe((response: any) => {
            saveAs(response.body, file.title);
        });
    }

    viewFile(path) {
        const newWindow = window.open('about:blank');
        const image = new Image();
        image.src = path;

        setTimeout(() => {
            newWindow.document.write(image.outerHTML);
        }, 0);
    }
}
