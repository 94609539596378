<button mat-icon-button (click)="type === 'edit' ? edit() : export()" class="icon-button">
  <div class="icon-override size-24 edit-icon" *ngIf="type === 'edit' && canEdit"></div>
  <mat-icon *ngIf="type === 'export'">save_alt</mat-icon>
</button>
<button mat-icon-button (click)="synthese()" class="icon-button">
  <div class="icon-override size-24 eye-icon"></div>
</button>
<app-project-bookmark 
  [projectId]="projectId"
  [projectBookmarked]="isBookmarked"
  [useOnChange]="false"
  style="margin-top: 5px; margin-left: 5px"
  *ngIf="showFav"
>
</app-project-bookmark>