<div 
    class="pointer icon-override fav-icon size-{{fontSize}}" 
    matTooltip="Add project to favorites" 
    *ngIf="!projectBookmarked && projectId" 
    (click)="bookmark()"
>
</div>
<div 
    class="pointer icon-override unfav-icon size-{{fontSize}}" 
    matTooltip="Remove project from favorites" 
    *ngIf="projectBookmarked && projectId" 
    (click)="bookmark()"
>
</div>