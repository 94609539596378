import { Component, OnInit } from '@angular/core';
import {CguComponent} from '../../../auth/components/cgu/cgu.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  constructor(
    private _matDialog: MatDialog,
  ) { }

  ngOnInit() {

  }

  public showCGUDialog() {
    this._matDialog.open(CguComponent);
  }

  acceptCookies() {
    this.setCookie('slp-cgu', true, 30);
  }
  
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
