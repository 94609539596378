import { Zone } from './zone';

export class User  {

    constructor(
        public id: number,
        public igg: string,
        public first_name: string,
        public last_name: string,
        public email: string,
        public entity: string,
        public title: string,
        public role: Role,
        public zones: Array<Zone>,
        public password: string,
        public remember_token: string

    ) { }

}

export interface IUser extends IEntity {
    id: number;
    igg: string;
    first_name: string;
    last_name: string;
    email: string;
    entity: string;
    title: string;
    role: Role;
    zones: Array<Zone>;
    password: string;
    remember_token: string;

}

export enum Role {
    SPADMIN = 'Super administrator',
    ADMIN = 'Administrator',
    USER = 'User',
    EDITOR = 'Editor'
}

export interface IEntity {
    id: number;
    first_name: string;
}
