import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from '../_services/permission.service';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements  CanActivate {

  constructor(private _permissionService: PermissionService, private router: Router) {}

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot) {
    return this._permissionService.isMaster() ? true : this.router.parseUrl('/');

  }
}
