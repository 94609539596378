import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { Component, OnInit, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {Role} from 'src/app/modules/model/user';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  environment = environment;
  expanded = false;

  @Input() public nav: MatSidenav;
  private _user: any;
  createdisplay = false;
  constructor(
    public userService: UserService,
    public authService: AuthService
  ) {
    this._user = this.userService.getUser().getValue();

  }

  ngOnInit() {
    this.checkrights();
  }

  expand() {
    this.expanded = !this.expanded;
  }

  checkrights() {
     if (this._user) {
        if (this._user.role !== Role.USER) {
      this.createdisplay = true;
    }
     }

  }

}
