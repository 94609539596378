/**
 * STAND ALONE MONTHLY YEAR DATE PIKER COMPONENT
 * RADHOUANE BADREDDINE
 * SLP PROJECT
 */
import { Component, OnInit, Input, OnDestroy, forwardRef } from '@angular/core';

import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-month-year-table',
  templateUrl: './month-year-table.component.html',
  styleUrls: ['./month-year-table.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthYearTableComponent),
      multi: true
    }
  ],
})
export class MonthYearTableComponent implements OnInit, ControlValueAccessor, OnDestroy {
  dateControl: FormControl;
  disabled: boolean;
  destroyed = new Subject();

  @Input() placeholder: string;
  @Input() startAt = moment();

  onChange = _ => { };

  // Function to call when the input is touched (when a star is clicked).
  onTouched = _ => { };

  constructor() {
    this.dateControl = new FormControl(moment());
  }

  ngOnInit() {

    this.dateControl.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(val => {
      this.onChange(val);
    });

    this.dateControl.setValue([]);
  }

  chosenMonthHandler(normlizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    // console.log(normlizedMonth);
    const ctrlValue = this.dateControl.value ? this.dateControl.value : moment();
   // console.log(ctrlValue);
    ctrlValue.year(normlizedMonth.year());
    ctrlValue.month(normlizedMonth.month());
    this.dateControl.setValue(ctrlValue);
    datepicker.close();
  }

  chosenYearHandler(normlizedYear: Moment) {
    const ctrlValue = this.dateControl.value ? this.dateControl.value : moment();
    ctrlValue.year(normlizedYear.year());
    this.dateControl.setValue(ctrlValue);
  }
  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

 // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateControl.disable();
    } else {
      this.dateControl.enable();
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  // Allows Angular to update the model (rating).
  // Update the model and changes needed for the view here.
  writeValue(obj: any): void {
    if (obj) {
      const momentObj = moment(obj);
      if (momentObj.isValid()) {
       // console.log(momentObj);
        this.dateControl.setValue(momentObj);
      }
    }
  }
}
