import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-ag-clickable-parent',
  templateUrl: './ag-clickable-parent.component.html',
  styleUrls: ['./ag-clickable-parent.component.scss']
})
export class AgClickableParentComponent implements ICellRendererAngularComp {
  params: any;
  type: any;
  project;
  showFav = true;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.type = params.colDef.type;
    this.project = params.data;
    this.showFav = this.project.status == 'Active';
  }

  clicked(cell: any): void {
    console.log('Child Cell Clicked: ' + JSON.stringify(cell));
  }

  refresh(): boolean {
    return false;
  }

}
