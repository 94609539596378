<div class="uploaded-photos" *ngIf="uploadedFiles && uploadedFiles.length > 0">
    <div class="photo {{type}}" *ngFor="let picture of uploadedFiles; index as i">
        <div class="img">
            <img (click)="viewFile(picture.path)" src="{{picture.path}}" class="img-activity" style="cursor: pointer;"/>
            <button class="delete-picture" mat-icon-button (click)="removeAttachment(i, 'uploadedFiles')" *ngIf="canUpload">
                <div class="delete-icon size-20 icon-override pointer"></div>
            </button>
        </div>
        <mat-form-field appearance="outline" class="image-title" *ngIf="canUpload">
            <input type="text" matInput placeholder="" [(ngModel)]="picture.title" (ngModelChange)="change($event)" [disabled]="!canUpload"/>
        </mat-form-field>
        <div class="label-photo" *ngIf="!canUpload">{{picture.title}}</div>
    </div>
</div>
<div class="upload-doc-container" (click)="fileInput.click()" *ngIf="canUpload">
    <div class="start-text">
        <mat-icon>arrow_right</mat-icon>
        {{ "add_document" | translate }}
    </div>
    <div class="end-text">
        {{ helpText }}
    </div>
    <input
        #fileInput
        type="file"
        (change)="onFileInput($event.target.files)"
        class="d-none"
        [accept]="acceptedFiles"
        multiple
        [disabled]="(isArray(uploadedFiles) ? uploadedFiles?.length : 0) + files.length >= limit"
    />
</div>

<table class="mat-table" *ngIf="(files || uploadedFiles) && canUpload">
    <thead *ngIf="files?.length > 0">
    <tr class="mat-header-row">
        <th class="mat-header-cell col-width-70">{{ "documents" | translate }}</th>
        <th class="col-width-30"></th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row file" *ngFor="let file of files; index as i;">
        <td class="label">
            <span class="attachment-label">{{ file.name }}</span>
        </td>
        <td class="buttons">
            <button mat-icon-button (click)="removeAttachment(i, 'files')">
                <mat-icon mat-list-icon>delete</mat-icon>
            </button>
        </td>
    </tr>
    </tbody>
</table>
