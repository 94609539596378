<div class="dialog-wrapper">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <div mat-dialog-content>
    <span class="text">{{ data.text }}</span>
    <mat-form-field appearance="outline" *ngIf="data.type == 'prompt'">
      <input type="text" matInput [(ngModel)]="data.value">
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <div style="width: 100%; text-align: center">
      <div *ngIf="data.type == 'prompt'">
        <button mat-raised-button (click)="onClick()">
          <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
          <span>{{ button_save }}</span>
        </button>
        <button mat-raised-button (click)="onNoClick()">
          <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
          <span>{{ button_cancel }}</span>
        </button>
      </div>
      <div *ngIf="data.type == 'confirm'">
        <br />
        <button mat-raised-button (click)="onClick()">
          <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
          <span>{{ text_yes }}</span>
        </button>
        <button mat-raised-button (click)="onNoClick()">
          <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
          <span>{{ text_no }}</span>
        </button>
      </div>
      <div *ngIf="data.type == 'alert'">
        <br />
        <button mat-raised-button (click)="onClick()">
          <div class="icon-override size-20 arrow-right-icon" style="margin-right: 12px;"></div>
          <span>OK</span>
        </button>
      </div>
    </div>
  </div>
</div>
