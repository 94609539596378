import { NgModule, SkipSelf, Optional } from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { createTranslateLoader, SharedModule } from '../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './components/app/app.component';
import { API_CONFIG } from '@ff/api';
import { environment } from '../../../environments/environment';
import { RouterModule } from '@angular/router';
import { routes } from './core.routes';
import { ATInternetModule, AT_INTERNET_CONFIG } from '@ff/at-internet';
import { UserModule } from '@ff/user';
import { AuthModule } from '@ff/auth';
import { AuthModule as AppAuthModule } from '../auth/auth.module';
import { UserModule as AppUserModule } from '../user/user.module';
import { AUTH_CONFIG } from '@ff/auth';
import { USER_CONFIG } from '@ff/user';
import { OAuth2Module, OAUTH2_CONFIG } from '@ff/oauth2';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NavigationComponent } from './navigation/navigation.component';
import { ErrorHandlerModule } from '@ff/error-handler';
import {CookiesComponent} from './components/cookies/cookies.component';
import { ZonesResolver } from '../dataviz/resolvers/zones.resolvers';
import { OperationalsResolver } from '../dataviz/resolvers/operations.resolvers';
import { CountriesResolver } from '../dataviz/resolvers/countries.resolvers';
import { BusineslinesResolver } from '../dataviz/resolvers/businesslines.resolvers';
import { DatavizProjectListResolver } from '../dataviz/resolvers/projectlists.resolvers';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        NavigationComponent,
        CookiesComponent
    ],
    providers: [
        {
            provide: AT_INTERNET_CONFIG,
            useValue: {
                site: 610955,
                autosend: true,
                secure: true,
                collectDomain: 'logc409.xiti.com',
                collectDomainSSL: 'logs1409.xiti.com',
            }
        },

        {
            provide: API_CONFIG,
            useValue: {
                host: environment.api.host
            }
        },
        {
            provide: AUTH_CONFIG,
            useValue: {
                host: environment.api.host
            }
        },
        {
            provide: USER_CONFIG,
            useValue: {
                host: environment.api.host
            }
        },
        {
            provide: OAUTH2_CONFIG,
            useValue: {
                host: environment.api.host,
                endpoints: {
                    bearer: '/oauth2/token',
                    providers: '/oauth2/providers'
                }
            }
        },
        ZonesResolver, OperationalsResolver, CountriesResolver, BusineslinesResolver, DatavizProjectListResolver

    ],
    imports: [

        // FF packages

        UserModule,
        AuthModule,
        ATInternetModule,
        ErrorHandlerModule,
        // Angular Packages

         RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            }
        }),

        // App packages

        SharedModule,
        AppAuthModule,
        AppUserModule,
        NgSelectModule,
        // Angular
        BrowserModule,
        BrowserAnimationsModule,
        MatTabsModule,
        MatStepperModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatChipsModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule,
        NgxEchartsModule
    ],
    bootstrap: [
        AppComponent
    ]
})
export class CoreModule {

    public constructor(@Optional() @SkipSelf() parent: CoreModule) {
        if (parent) {
            throw new Error('CoreModule should not be exported then imported by another module!');
        }

    }

}
