import {Directive, OnInit, ElementRef, OnDestroy, Renderer2, HostListener, Input} from '@angular/core';
import {NgControl} from '@angular/forms';
import {FocusMonitor} from '@angular/cdk/a11y';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { stripHtml } from '../models/project';
@Directive({
  selector: '[appInputMaxLength]',
})
export class InputMaxLengthDirective implements OnInit, OnDestroy {
    @Input() maxl: number;
    @Input() innertext: string;
    cleanedvalue: string;
      destroyed = new Subject();
   get inputValue() {
    return this._control ? this._control.value : null;
  }

  constructor(
     private _elementRef: ElementRef,
     private _control: NgControl,
     private _renderer: Renderer2,
     fm: FocusMonitor
  ) {
        fm.monitor(_elementRef.nativeElement, true)
        .pipe(takeUntil(this.destroyed))
        .subscribe(origin => {
            const focused = !!origin;
        });
  }

  ngOnInit() {
   // this.switchInputFormat(false);
  }

    @HostListener('keydown', ['$event'])
    //  @HostListener('valueChanged', ['$event'])
      onKeyDown(event: any) {
        // console.log(this._elementRef.nativeElement);
        if (event.code === 'Backspace') {
           return;
        } else {
            this.cleanedvalue = stripHtml(this.inputValue !== null ? this.inputValue.concat(event.key) : event.key);
        }
        if (this.cleanedvalue.length >= this.maxl) {
              this._elementRef.nativeElement.querySelector('p').innerText =
                  this.cleanedvalue.substr(0, this.maxl);

              event.preventDefault();
          } else {

              return;
            }
      }
  ngOnDestroy() {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

}
