import { Routes } from '@angular/router';
import { LoggedInGuard, GuestGuard } from '@ff/core';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';

export const routes: Routes = [
   { path: 'auth',
    children: [
      { path: 'login', component: LoginComponent, canActivate: [GuestGuard]  },
      { path: 'logout', component: LogoutComponent, canActivate: [LoggedInGuard]  },
    ]
  },
  {
    path: '' ,
    component: LoginComponent
  }
];
