import {Component, HostListener, OnInit} from '@angular/core';
import {LoaderService} from '@ff/loader';
import {ProjectService} from '../../../_services/project.service';
import {filter, pairwise, startWith, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {CrudService} from '../../../shared/services/crud.service';
import {loaderHide, loaderShow} from '../../../shared/models/project';
import {DOCUMENTS_INFOS} from '../../../shared/enums/document-types.enum';
import {isNil} from 'lodash';

@Component({
  selector: 'app-project-consultation',
  templateUrl: './project-consultation.component.html',
  styleUrls: ['./project-consultation.component.scss']
})
export class ProjectConsultationComponent implements OnInit {

  documentInfos = DOCUMENTS_INFOS;
  historyActivitiesFiles = {};

  destroy = new Subject<any>();
  projectId;

  expandIntro = false;
  expandDesc = false;
  expandGallery = false;

  project;
  links = [];
  uploadedFiles = {};

  currentActivityFiles;
  monthlyIds;

  contacts = [
    [
      {id: 'architect', formControlName: 'architect_id'},
      {id: 'manager', formControlName: 'manager_id'}
    ],
    [
      {id: 'fp_affiliate', formControlName: 'fp_affiliate_id'},
      {id: 'fp_zone', formControlName: 'fp_zone_id'}
    ],
    [
      {id: 'fp_slp_tp', formControlName: 'fp_slp_tp_id'},
      {id: 'fp_business_line', formControlName: 'fp_business_line_id'}
    ],
    [
      {id: 'fp_hse', formControlName: 'fp_hse_id'},
      {id: 'fp_purchasing', formControlName: 'fp_purchasing_id'}
    ]
  ];

  isMobile: boolean;

  @HostListener('window:resize', [])
  private onResize() {
    this.isMobile = window.innerWidth < 700;
  }

  constructor(
    private _loaderService: LoaderService,
    private _projectService: ProjectService,
    private _crudService: CrudService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {

    this.isMobile = window.innerWidth < 700;
    this._router.events.pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroy),
        pairwise(),
        filter((events: RouterEvent[]) => events[0].url === events[1].url),
        startWith('Initial call'),
    ).subscribe(
    () => {
      this.projectId = parseInt(this._route.snapshot.paramMap.get('id'), 10);
      if (!isNil(history.state?.data?.expandDocs)) {
        this.getGallery();
      } else {
        this.getIntro();
      }

    });
  }

  ngOnInit(): void {

  }

  isNil(data) {
    // console.log(isNil(data));
    return isNil(data);
  }

  edit(): void {
    const menu = this.expandIntro ? 'introduction' : (
        this.expandGallery ? 'gallery-documents' : (
          this.expandDesc ? 'description' : 'monthly-progress'
        )
    );
    this._router.navigate(['/project/' + menu + '/' + this.projectId + '/edit']);
  }

  synthese() {
    //  console.log('CLIcked');
    this._router.navigate(['/synthese/' + '/dev/' + this.projectId + '/view']);
  }

  getIntro() {
    this.expandDesc = false;
    this.expandGallery = false;
    loaderShow(this._loaderService);
    if (this.projectId && !Number.isNaN(this.projectId)) {
      this._crudService.getResource('project/introduction', this.projectId).subscribe(
          (projectIntro: any) => {
            this.project = projectIntro;
            this.project.updated_at = moment.utc(projectIntro.updated_at).format('MMMM YYYY');
            loaderHide(this._loaderService);
            this.expandIntro = true;
          }
      );
    }
  }

  getDescription() {
    this.expandIntro = false;
    this.expandGallery = false;
    loaderShow(this._loaderService);
    this._crudService.getResource('project/description', this.projectId).subscribe(
        (desc) => {
          this.project = desc;
          this.links = this.project.link?.split('|');
          this.links = this.links?.filter(function (link) {
            return link != null && link != "";
          });
          loaderHide(this._loaderService);
          this.expandDesc = true;
        }
    );
  }

  getGallery() {
    this.expandIntro = false;
    this.expandDesc = false;
    this._crudService.getResource('project/document-gallery', this.projectId).subscribe(
        (data) => {
          this._projectService.project.next(data);
          this.fillFiles(data);
          this.expandGallery = true;
        }
    );
  }

  fillFiles(data) {
    this.uploadedFiles = {
      activities: [],
      description: [],
      project_schedule: [],
      sor: [],
      pr1: [],
      pr2: [],
      fid: [],
      steering_committees: [],
      rex: [],
      other: []
    };
    ['activities', 'description', 'project_schedule', 'sor',
      'pr1', 'pr2', 'fid', 'steering_committees', 'rex', 'other'].forEach(
        (type) => {
          this.uploadedFiles[type] = data[type];
          if (type === 'activities') {
            //  console.log(data[type]);
            const activities = data[type];
            if (activities) {
              const months = Object.keys(activities);
              const lastKey = months.shift();
              this.currentActivityFiles = activities[lastKey];
              months.forEach(
                  (month) => {
                    if (!this.monthlyIds) {
                      this.monthlyIds = {};
                    }
                    this.monthlyIds[month] = activities[month];
                  }
              );
            }
          }
        }
    );
  }

}
