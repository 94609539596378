import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {CrudService} from '../../../../shared/services/crud.service';
import {LoaderService} from '@ff/loader';
import {loaderHide, loaderShow} from '../../../../shared/models/project';
import {isEmpty} from 'lodash';

@Component({
  selector: 'app-monthly-progresses',
  templateUrl: './monthly-progresses.component.html',
  styleUrls: ['./monthly-progresses.component.scss']
})
export class MonthlyProgressesComponent implements OnInit {

  @Input() monthlyProgressesFiles;
  @Output() monthlyProgressesFilesChange = new EventEmitter();

  @Input() monthlyIds;
  @Input() canUpload = true;

  monthsData: Array<{ period: any, key: any, files: Array<any> }> = [];

  constructor(
      private _crudService: CrudService,
      private _loaderService: LoaderService
  ) {

  }

  getMonthlyDocuments(month, monthData) {
    if (!this.monthsData[month].files || isEmpty(this.monthsData[month].files)) {
      loaderShow(this._loaderService);
      this._crudService.getResource('project/document-gallery/monthly', this.monthlyIds[monthData.key]).subscribe(
          (docs: any) => {
            this.monthsData[month].files = docs;
            this.monthlyProgressesFiles[this.monthsData[month].key] = docs;
            this.monthlyProgressesFilesChange.emit(this.monthlyProgressesFiles);
            loaderHide(this._loaderService);
          }
      );
    }
  }

  ngOnInit(): void {
    if (this.monthlyIds) {
      Object.keys(this.monthlyIds).forEach(
          (month) => {
            this.monthsData.push(
                {
                  period: moment().month(parseInt(month.slice(1, 3), 10) - 1).format('MMMM') + ' ' + month.slice(3, month.length),
                  key: month,
                  files: [],
                }
            );
          }
      );
    }
  }

  viewFile(path) {
    window.open(path, '_blank');
  }

  removeAttachment(month, index) {
    this.monthsData[month].files.splice(index, 1);
  }
}
