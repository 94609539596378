<div class="info-data project-title align-items-center" mat-dialog-title>
    <mat-icon class="pointer d-flex align-items-center" (click)="prev()" *ngIf="onPointProjects.length > 1">chevron_left</mat-icon>
    <label class="label-title">{{selectedProject?.title | uppercase}}</label>
    <mat-icon class="pointer d-flex align-items-center" (click)="next()" *ngIf="onPointProjects.length > 1">chevron_right</mat-icon>
</div>
<div mat-dialog-content>
    <div class="info-block">
        <div class="info-data"><label>{{ 'country' | translate }} : </label>{{selectedProject?.countryLabel}}</div>
        <div class="info-data">
            <label>{{ 'step' | translate }} : </label>{{selectedProject?.project_step}}
        </div>
        <div class="info-data">
            <label>{{ 'cost' | translate}} : </label>
            <!-- <mat-icon style="color: #4A4A4A;cursor: pointer;font-size: medium;" #tipCostExe="matTooltip" matTooltipClass="allow-cr"
                      matTooltip="Total project CAPEX performance for EXE phase only :
  - Green smiley : EFC ≤ Approved FID
  - Orange smiley : Approved FID < EFC ≤ Approved at FID +10%
  - Red smiley : EFC > Approved FID +10%
EFC : Estimated Final Cost
FID : Final Investment Decision"
                      matTooltipPosition="right" *ngIf="selectedProject?.phase === 'EXE'">
                info
            </mat-icon> -->
            {{selectedProject.cost}}
            <div class="smiley {{selectedProject?.smileyCost}}" *ngIf="selectedProject?.phase === 'EXE'"></div>
        </div>
        <div class="info-data" *ngIf="selectedProject?.phase === 'DEV'">
            <label>{{ 'fid' | translate }} : </label>
            <!-- <mat-icon style="color: #4A4A4A;cursor: pointer;font-size: medium;" #tipSchedDev="matTooltip" matTooltipClass="allow-cr"
                      matTooltip="Schedule performance for DEV phase, from Gate FEASIBILITY to FID:
  - Green smiley : Actual duration ≤ Initial duration
  - Orange smiley : Initial duration < Actual duration ≤ Initial duration +20%
  - Red smiley : Actual duration > Initial duration +20%
FID : Final Investment Decision"
                      matTooltipPosition="right">
                info
            </mat-icon> -->
            {{selectedProject?.fid}}
            <div class="smiley {{selectedProject?.smileySchedule}}"></div>
        </div>
        <div class="info-data" *ngIf="selectedProject?.phase === 'EXE'">
            <label>{{ 'rfsu' | translate }} : </label>
            <!-- <mat-icon style="color: #4A4A4A;cursor: pointer;font-size: medium;" #tipSchedExe="matTooltip" matTooltipClass="allow-cr"
                      matTooltip="Schedule performance for EXE phase, from FID to RFSU:
  - Green smiley : Actual duration ≤ Initial duration
  - Orange smiley : Initial duration < Actual duration ≤ Initial duration +10%
  - Red smiley : Actual duration > Initial duration +10%
RFSU : Ready For Start Up"
                      matTooltipPosition="right">
                info
            </mat-icon> -->
            {{selectedProject?.rfsu}}
            <div class="smiley {{selectedProject?.smileySchedule}}"></div>
        </div>
        <div class="info-data">
            <label>{{ ('progress_' + selectedProject?.phase) | translate}} : </label>
            <!-- <mat-icon style="color: #4A4A4A;cursor: pointer;font-size: medium;" #tipProgressDev="matTooltip" matTooltipClass="allow-cr"
                      matTooltip="From NEEDS to FID
FID : Final Investment Decision"
                      matTooltipPosition="right" *ngIf="selectedProject?.phase === 'DEV'">
                info
            </mat-icon> -->
            <!-- <mat-icon style="color: #4A4A4A;cursor: pointer;font-size: medium;" #tipProgressExe="matTooltip" matTooltipClass="allow-cr"
                      matTooltip="From FID to RFSU
RFSU : Ready For Start Up"
                      matTooltipPosition="right" *ngIf="selectedProject?.phase === 'EXE'">
                info
            </mat-icon> -->
            {{selectedProject?.progress}}%
        </div>
        <div class="info-data"><label>{{ 'last_update' | translate}} : </label>{{selectedProject?.last_update}}</div>
    </div>
    <div class="action" (click)="synthese(selectedProject?.id)">
        <mat-icon class="red-eye">remove_red_eye</mat-icon>
        <label><a>{{ "see_project" | translate}}</a></label>
    </div>
    <div class="action" (click)="contacts(selectedProject?.id)">
        <mat-icon class="red-eye">people</mat-icon>
        <label><a>{{ "see_contacts" | translate}}</a></label>
    </div>
</div>
