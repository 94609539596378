export enum ProjectSteps {
  NEEDS = 'Needs',
  FEASIBILITY = 'Feasibility',
  BASIC = 'Basic',
  FEED = 'Feed',
  CFT = 'CFT',
}
export const PROJECT_DEV_STEPS = [
  ProjectSteps.NEEDS, ProjectSteps.FEASIBILITY, ProjectSteps.BASIC, ProjectSteps.FEED, ProjectSteps.CFT
];
