import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Zone} from '../../../model/zone';
import {Businessline, Country, Operation} from '../../../model/rightaccess';
import {CrudService} from '../../services/crud.service';
import {isEmpty, isNil} from 'lodash';
import * as moment from 'moment';
import {ProjectSteps} from '../../enums/project-steps.enum';
import {Subject} from 'rxjs';
import {compare} from '../../models/project';

@Component({
    selector: 'app-project-filter',
    templateUrl: './project-filter.component.html',
    styleUrls: ['./project-filter.component.scss']
})
export class ProjectFilterComponent implements OnInit {
    @Input() projects;
    @Output() projectsChange = new EventEmitter();
    @Input() expanded = false;
    @Input() searchBar = true;
    @Input() mobile = false;
    @Input() periodFilter = true;
    @Output() periodFilterChange = new EventEmitter();
    @Input() costFilter = true;
    @Input() jvFilter = true;
    @Input() roleFilter = true;
    @Input() costPerfFilter = true;
    @Input() schedulePerfFilter = true;
    @Input() stepFilter = true;
    @Input() nextMilFilter = false;
    @Input() applyFilterAlert: Subject<any>;

    isExpanded: boolean;

    isMobile: boolean;

    costs = [
        {id: 0, label: '<1M$'},
        {id: 1, label: '1-4M$'},
        {id: 2, label: '4-20M$'},
        {id: 3, label: '>20M$'},
    ];
    selectedCosts;

    projectSteps = [];
    selectedSteps;

    performances = [
        {id: 0, label: 'good'},
        {id: 1, label: 'medium'},
        {id: 2, label: 'bad'},
        {id: 3, label: 'undefined'}
    ];

    selectedCostsPerf;
    selectedSchedulePerf;

    allProjects;

    filterForm: FormGroup;

    jv_options = [
        {value: 0, label: 'All'},
        {value: 1, label: 'Yes'},
        {value: 2, label: 'No'},
    ];
    selectedJV;

    role_options = ['All', 'Yes', 'No', 'Not Defined'];
    selectedRole;

    next_mil_options = [
        {value: 'pr1', label: 'PR1'},
        {value: 'cdms1', label: 'CDMS/CODIR post PR1'},
        {value: 'pr2', label: 'PR2'},
        {value: 'cdms2', label: 'CDMS/CODIR post PR2'},
    ];

    selectedNextMil;

    zones: Zone[];
    operationals: Operation[];
    countries: Country[];
    businesslines: Businessline[];

    filtredzones: Zone[];
    filtredoperationals: Operation[];
    filtredcountries: Country[];
    filtredbusinesslines: Businessline[];

    selectedZone;
    selectedOpe;
    selectedCountry;
    selectedBusinessline;

    Opeloading = false;
    Countriesloading = false;
    Businessloading = false;
    startAt = moment();

    @HostListener('window:resize', [])
    private onResize() {
        this.isMobile = window.innerWidth < 700;
    }

    constructor(
        private _crudService: CrudService,
        private _fb: FormBuilder
    ) {
        this.isMobile = window.innerWidth < 700;
        this.isExpanded = this.expanded;
    }

    ngOnInit(): void {

        this._crudService.getAll('zones/filtered').subscribe((zones: Zone[]) => {
            this.zones = zones;
            this.filtredzones = zones;
        });

        this._crudService.getAll('operationals').subscribe((operationals: Operation[]) => {
            this.operationals = operationals;
            this.filtredoperationals = operationals;
        });
        this._crudService.getAll('countries').subscribe((countries: Country[]) => {
            this.countries = countries.sort((a, b) => compare(a.label_en, b.label_en, true));
            this.filtredcountries = countries.sort((a, b) => compare(a.label_en, b.label_en, true));
        });
        this._crudService.getAll('businesslines').subscribe((businesslines: Businessline[]) => {
            this.businesslines = businesslines;
            this.filtredbusinesslines = businesslines;
        });
        this.projectSteps = [
            ProjectSteps.NEEDS, ProjectSteps.FEASIBILITY, ProjectSteps.BASIC, ProjectSteps.FEED, ProjectSteps.CFT, 'EXE'
        ];
        this.initForm();
        this.applyFilterAlert?.subscribe(
            (projects) => {
                if (projects) {
                    this.allProjects = projects;
                    this.applyAllFilters();
                }
            }
        );
    }

    initForm() {
        // we save list of all projects
        this.allProjects = this.projects;

        this.filterForm = this._fb.group({
            text: null,
            zones: null,
            operational_directions: [{value: null, disabled: true}],
            countries: null,
            business_lines: [{value: null}],
            period: null,
            costs: null,
            steps: null,
            cost_performances: null,
            schedule_performances: null,
            joint_ventures: null,
            total_role: null,
            next_milestone: null
        });
        // apply listener on search input & period pick
        this.inputFilter();
        this.periodChange();
        this.zoneFormListener();
        // this.countryListner();
        // this.operationalDirectionFormListener();
    }

    countryListner() {
        this.filterForm.get('countries').valueChanges.subscribe(
            (country) => {
                if (!isEmpty(country)) {
                    this.filterForm.get('operational_directions').enable();
                } else {
                    // this.filterForm.get('operational_directions').disable();
                }
            }
        );
    }

    zoneFormListener() {
        this.filterForm.get('zones').valueChanges.subscribe(
            (zone) => {
                if (!isEmpty(zone)) {
                    this.filterForm.get('operational_directions').enable();
                } else {
                    this.filterForm.get('operational_directions').disable();
                }
            }
        );
    }

    operationalDirectionFormListener() {
        this.filterForm.get('operational_directions').valueChanges.subscribe(
            (operational_direction) => {
                if (!isEmpty(operational_direction)) {
                    this.filterForm.get('countries').enable();
                } else {
                    this.filterForm.get('countries').disable();
                }
            }
        );
    }

    isFormInputNull(control) {
        return this.filterForm.get(control).value !== '' && !isNil(this.filterForm.get(control).value);
    }

    applyAllFilters() {
        const filters = [
            'text',
            'zones',
            'operational_directions',
            'countries',
            'business_lines',
            'period',
            'costs',
            'steps',
            'cost_performances',
            'schedule_performances',
            'joint_ventures',
            'total_role',
            'next_milestone'
        ];
        let filteredProjects = this.allProjects;
        filters.forEach(
            (filter) => {
                switch (filter) {
                    case 'text':
                        filteredProjects = this.applyTextFilter(filteredProjects);
                        break;
                    case 'zones':
                        filteredProjects = this.applyZonesFilter(filteredProjects);
                        break;
                    case 'operational_directions':
                        filteredProjects = this.applyOperationalDirectionsFilter(filteredProjects);
                        break;
                    case 'countries':
                        filteredProjects = this.applyCountriesFilter(filteredProjects);
                        break;
                    case 'business_lines':
                        filteredProjects = this.applyBLFilter(filteredProjects);
                        break;
                    case 'period':
                        filteredProjects = this.applyPeriodFilter(filteredProjects);
                        break;
                    case 'costs':
                        filteredProjects = this.applyCostsFilter(filteredProjects);
                        break;
                    case 'steps':
                        filteredProjects = this.applyStepsFilter(filteredProjects);
                        break;
                    case 'cost_performances':
                        filteredProjects = this.applyCostPerfsFilter(filteredProjects);
                        break;
                    case 'schedule_performances':
                        filteredProjects = this.applySchedulePerfsFilter(filteredProjects);
                        break;
                    case 'joint_ventures':
                        filteredProjects = this.applyJVFilter(filteredProjects);
                        break;
                    case 'total_role':
                        filteredProjects = this.applyRoleFilter(filteredProjects);
                        break;
                    case 'next_milestone':
                        filteredProjects = this.applyNextMilestoneFilter(filteredProjects);
                        break;
                    default:
                        break;
                }
            }
        );
        this.projectsChange.emit(filteredProjects);
    }

    applyTextFilter(projects) {
        const text = this.filterForm.get('text').value;
        if (text !== '' && !isNil(text)) {
            return projects?.slice().filter(project => JSON.stringify(project).toLowerCase().includes(text.toLowerCase()));
        }
        return projects;
    }

    applyZonesFilter(projects) {
        if (!isEmpty(this.selectedZone)) {
            return projects?.slice().filter(project => this.selectedZone.includes(project.zone.id));
        }
        return projects;
    }

    applyOperationalDirectionsFilter(projects) {
        if (!isEmpty(this.selectedOpe)) {
            return projects?.slice().filter(project => this.selectedOpe.includes(project.operationalDirection.id));
        }
        return projects;
    }

    applyCountriesFilter(projects) {
        if (!isEmpty(this.selectedCountry)) {
            return projects?.slice().filter(project => this.selectedCountry.includes(project.country.id));
        }
        return projects;
    }

    applyBLFilter(projects) {
        if (!isEmpty(this.selectedBusinessline)) {
            return projects?.slice().filter(project => this.selectedBusinessline.includes(project.businessLine.id));
        }
        return projects;
    }

    applyPeriodFilter(projects) {
        const date = this.filterForm.get('period').value;
        if (!isNil(date)) {
            return projects?.slice().filter(
                project => {
                    return project.period ? moment.utc(project.period).format('MM/YYYY') <= moment.utc(date).format('MM/YYYY') : false;
                }
            );
        }
        return projects;
    }

    applyNextMilestoneFilter(projects) {
        if (!isEmpty(this.selectedNextMil)) {
            return projects?.slice().filter(
                project => {
                    return project.highlights?.next_milistone_type ?
                        this.selectedNextMil.includes(project.highlights?.next_milistone_type) :
                        false;
                });
        }
        return projects;
    }

    applyCostsFilter(projects) {
        if (!isEmpty(this.selectedCosts)) {
            return projects?.slice().filter(
                (project) => {
                    if (project.cost) {
                        let res = false;
                        const cost = parseInt(project.cost, 10);
                        this.selectedCosts.forEach(
                            (costId) => {
                                switch (costId) {
                                    case 0: // <1M$
                                        res = res || cost < 1000;
                                        break;
                                    case 1: // 1-4M$
                                        res = res || (1000 <= cost && cost < 4000);
                                        break;
                                    case 2: // 4-20M$
                                        res = res || (4000 <= cost && cost < 20000);
                                        break;
                                    case 3: // >20M$
                                        res = res || cost >= 20000;
                                        break;
                                }
                            }
                        );
                        return res;
                    }
                    return false;
                }
            );
        }
        return projects;
    }

    applyStepsFilter(projects) {
        if (!isEmpty(this.selectedSteps)) {
            return projects?.slice().filter(
                project => {
                    return project.project_step ? this.selectedSteps.includes(project.project_step) : false;
                }
            );
        }
        return projects;
    }

    applyCostPerfsFilter(projects) {
        if (!isEmpty(this.selectedCostsPerf)) {
            return projects?.slice().filter(project => this.projectCostPerf(project));
        }
        return projects;
    }

    applySchedulePerfsFilter(projects) {
        if (!isEmpty(this.selectedSchedulePerf)) {
            return projects?.slice().filter(project => this.projectSchedulePerf(project));
        }
        return projects;
    }

    applyJVFilter(projects) {
        if (!isNil(this.selectedJV)) {
            return projects?.slice().filter(project => {
                return this.selectedJV === 0 ? true : (project.jv === (this.selectedJV === 1 ? 1 : 0));
            });
        }
        return projects;
    }

    applyRoleFilter(projects) {
        if (!isNil(this.selectedRole)) {
            return projects?.slice().filter(project => {
                switch (this.selectedRole) {
                    case 'Not Defined':
                        return project.total_role === 'Not Defined';
                    case 'Yes':
                        return project.total_role === 'Yes';
                    case 'No':
                        return project.total_role === 'No';
                    case 'All':
                        return true;
                }
            });
        }
        return projects;
    }

    inputFilter() {
        this.filterForm.get('text').valueChanges.subscribe(_ => this.applyAllFilters());
    }

    periodChange() {
        this.filterForm.get('period').valueChanges.subscribe(
            date => {
                if (!isNil(date)) {
                    this.periodFilterChange.emit(date.format('MM-YYYY'));
                }
            }
        );
    }

    isProjectCostInRange(project) {
        let res = false;
        const cost = parseInt(project.cost, 10);
        this.selectedCosts.forEach(
            (costId) => {
                switch (costId) {
                    case 0: // <1M$
                        res = res || cost < 1000;
                        break;
                    case 1: // 1-4M$
                        res = res || (1000 <= cost && cost < 4000);
                        break;
                    case 2: // 4-20M$
                        res = res || (4000 <= cost && cost < 20000);
                        break;
                    case 3: // >20M$
                        res = res || cost >= 20000;
                        break;
                }
            }
        );
        return res;
    }

    projectCostPerf(project) {
        const percentage = project.cost_perf;
        let res = false;

        if (!isNil(percentage)) {
            this.selectedCostsPerf.forEach(
                (costId) => {
                    switch (costId) {
                        case 0: // good
                            res = res || percentage <= 0;
                            break;
                        case 1: // medium
                            res = res || (0 < percentage && percentage <= 10);
                            break;
                        case 2: // bad
                            res = res || 10 < percentage;
                            break;
                    }
                }
            );
        } else {
            if (this.selectedCostsPerf.includes(3)) {
                res = true;
            }
        }
        return res;
    }

    projectSchedulePerf(project) {
        let res = false;
        const percentage = project.schedule_perf;
        if (!isNil(percentage)) {
            this.selectedSchedulePerf.forEach(
                (costId) => {
                    switch (costId) {
                        case 0: // good
                            res = res || percentage <= 0;
                            break;
                        case 1: // medium
                            res = res || (0 < percentage && percentage <= (project.phase === 'DEV' ? 20 : 10));
                            break;
                        case 2: // bad
                            res = res || (project.phase === 'DEV' ? 20 : 10) < percentage;
                            break;
                    }
                }
            );
        } else {
            if (this.selectedSchedulePerf.includes(3)) {
                // undefined selected
                res = true;
            }
        }
        return res;
    }

    selectAll(event) {

        switch (event) {
            case 'zones':
                // console.log('selectAll');
                this.selectedZone = this.filtredzones.map(x => x.id);
                break;
            case 'operationals':
                this.selectedOpe = this.filtredoperationals.map(x => x.id);
                break;
            case 'countries':
                this.selectedCountry = this.filtredcountries.map(x => x.id);
                break;
            case 'businesslines':
                this.selectedBusinessline = this.filtredbusinesslines.map(x => x.id);
                break;
            case 'costs':
                this.selectedCosts = this.costs.map(x => x.id);
                break;
            case 'steps':
                this.selectedSteps = this.projectSteps;
                break;
            case 'cost_performances':
                this.selectedCostsPerf = this.performances.map(x => x.id);
                break;
            case 'schedule_performances':
                this.selectedSchedulePerf = this.performances.map(x => x.id);
                break;
            case 'next_milestone':
                this.selectedNextMil = this.next_mil_options.map(x => x.label);
        }
        this.applyAllFilters();
    }

    unselectAll(event) {
        switch (event) {
            case 'zones':
                this.selectedZone = [];
                break;
            case 'operationals':
                this.selectedOpe = [];
                break;
            case 'countries':
                this.selectedCountry = [];
                break;
            case 'businesslines':
                this.selectedBusinessline = [];
                break;
            case 'costs':
                this.selectedCosts = [];
                break;
            case 'steps':
                this.selectedSteps = [];
                break;
            case 'cost_performances':
                this.selectedCostsPerf = [];
                break;
            case 'schedule_performances':
                this.selectedSchedulePerf = [];
                break;
            case 'next_milestone':
                this.selectedNextMil = [];
                break;

        }
        this.applyAllFilters();
    }

    onClear(event) {
        // console.log('CLEAR');
        switch (event) {
            case 'zones':
                this.filtredoperationals = this.operationals;
                this.filtredcountries = this.countries;
                break;
            case 'operationals':
                this.selectedOpe = [];
                break;
            case 'countries':
                this.selectedCountry = [];
                this.zonechanged();
                break;
            case 'businesslines':
                this.selectedBusinessline = [];
                break;
            case 'costs':
                this.selectedCosts = [];
                break;
            case 'steps':
                this.selectedSteps = [];
                break;
            case 'cost_performances':
                this.selectedCostsPerf = [];
                break;
            case 'schedule_performances':
                this.selectedSchedulePerf = [];
                break;
        }
        this.applyAllFilters();
    }

    zonechanged() {
        if (isEmpty(this.selectedZone)) {
            this.filtredoperationals = this.operationals;
            this.filtredcountries = this.countries;
        } else {
            this.filtredoperationals = this.operationals.filter(
                ope => this.selectedZone.includes(ope.zone_id)
            );
            const opId = this.filtredoperationals.map(x => x.id);
            this.filtredcountries = this.countries.filter(country => opId.includes(country.operational_direction_id));
        }
        this.applyAllFilters();
    }

    operationalchanged() {
        if (!isEmpty(this.selectedOpe)) {
            this.filtredcountries = this.countries.filter(
                country => this.selectedOpe.includes(country.operational_direction_id)
            );
            this.applyAllFilters();
        } else {
            this.zonechanged();
        }
    }

    countrieschanged() {
        let opeFromCountryId;
        if (!isEmpty(this.selectedCountry)) {

            opeFromCountryId = [...new Set(this.countries.filter(
                country => this.selectedCountry.includes(country.id)).map(x => x.operational_direction_id))];

            this.filtredoperationals = this.operationals.filter(ope => opeFromCountryId.includes(ope.id));

            // this.filtredcountries = this.countries.filter(
            //     country => this.selectedOpe.includes(country.operational_direction_id)
            // );
            this.applyAllFilters();
        } else {
            this.zonechanged();
        }
    }

    onClose(event) {
        switch (event) {
            case 'zones':
                this.zonechanged();
                break;
            case 'operationals':
                this.selectedCountry = [];
                this.operationalchanged();
                break;
            // case 'countries':
            //   this.countrieschanged();
            //   break;
            default:
                this.applyAllFilters();
                break;
        }

    }

    onRemove($event, type) {
        // console.log('ONREMOVE');
        switch (type) {
            case 'zones':
                this.zonechanged();
                break;
            case 'operationals':
                this.selectedCountry = [];
                this.operationalchanged();
                break;
            // case 'countries':
            //   this.selectedOpe = [];
            //   break;
            default:
                this.applyAllFilters();
                break;
        }
    }

    onChange($event, type) {
        switch (type) {
            case 'zones':
                this.selectedOpe = [];
                this.selectedCountry = [];
                break;
            case 'operationals':
                this.selectedCountry = [];
                break;
            // case 'countries':
            //   this.selectedOpe = [];
            //   break;

        }

    }
}
