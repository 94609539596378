import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Zone} from '../../../model/zone';
import {Businessline, Country, Operation} from '../../../model/rightaccess';
import {isEmpty} from 'lodash';
import {CrudService} from '../../services/crud.service';
import { compare } from '../../models/project';

@Component({
  selector: 'app-zones-do-countries-bloc',
  templateUrl: './zones-do-countries-bloc.component.html',
  styleUrls: ['./zones-do-countries-bloc.component.scss']
})
export class ZonesDoCountriesBlocComponent implements OnInit {

  @Input() formControlZones: FormControl;
  @Input() formControlOperationals: FormControl;
  @Input() formControlCountries: FormControl;
  @Input() formControlBusinessLines: FormControl;
  @Input() multiple = true;
  @Input() consult?: boolean;
  @Input() fieldsRequired = false;

  zones: Zone[];
  operationals: Operation[];
  countries: Country[];
  businesslines: Businessline[];

  filtredzones: Zone[];
  filtredoperationals: Operation[];
  filtredcountries: Country[];
  filtredbusinesslines: Businessline[];

  selectedZone;
  selectedOpe;
  selectedCountry;
  selectedBusinessline;
  Opeloading = false;
  Countriesloading = false;
  Businessloading = false;

  constructor(
    protected _crudService: CrudService
  ) {
    if (this.multiple) {
      this.selectedZone = [];
      this.selectedOpe = [];
      this.selectedCountry = [];
      this.selectedBusinessline = [];
    }
  }

  ngOnInit(): void {
    if (this.consult) {
          this._crudService.getAll('zones').subscribe((zones: Zone[]) => {
      this.zones = zones;
      this.filtredzones = zones;
    });
    } else {
        this._crudService.getAll('zones/filtered').subscribe((zones: Zone[]) => {
      this.zones = zones;
      this.filtredzones = zones;
    });
    }

    this._crudService.getAll('operationals').subscribe((operationals: Operation[]) => {
      this.operationals = operationals;
      this.filtredoperationals = operationals;
    });
    this._crudService.getAll('countries').subscribe((countries: Country[]) => {
      this.countries = countries.sort((a, b) => compare(a.label_en, b.label_en, true));
      this.filtredcountries = countries.sort((a, b) => compare(a.label_en, b.label_en, true));
    });
    this._crudService.getAll('businesslines').subscribe((businesslines: Businessline[]) => {
      this.businesslines = businesslines;
      this.filtredbusinesslines = businesslines;
    });
  }

  selectAll(event) {

    switch (event) {

      case 'zones':
       //  console.log('selectAll');
        this.selectedZone = this.filtredzones.map(x => x.id);
        break;
      case 'operationals':
        this.selectedOpe = this.filtredoperationals.map(x => x.id);
        break;
      case 'countries':
        this.selectedCountry = this.filtredcountries.map(x => x.id);
        break;
      case 'businesslines':
        this.selectedBusinessline = this.filtredbusinesslines.map(x => x.id);
        break;

    }
  }

  unselectAll(event) {
    switch (event) {

      case 'zones':
        this.selectedZone = [];
        break;
      case 'operationals':
        this.selectedOpe = [];
        break;
      case 'countries':
        this.selectedCountry = [];
        break;
      case 'businesslines':
        this.selectedBusinessline = [];
        break;

    }

  }

  onClear(event) {
   //  console.log('CLEAR');

    switch (event) {

      case 'zones':

        this.filtredoperationals = this.operationals;
        this.filtredcountries = this.countries;
        break;
      case 'operationals':
        this.selectedOpe = [];
        break;
      case 'countries':
        this.selectedCountry = [];
        this.selectedOpe = [];
        this.zonechanged()
        break;
      case 'businesslines':
        this.selectedBusinessline = [];
        break;

    }

  }

  zonechanged() {
    if ((this.multiple && isEmpty(this.selectedZone)) || (!this.selectedZone && !this.multiple)) {
      this.filtredoperationals = this.operationals;
      this.filtredcountries = this.countries;
    } else {
      this.filtredoperationals = this.operationals.filter(
        ope => this.multiple ? this.selectedZone.includes(ope.zone_id) : this.selectedZone === ope.zone_id
      );
      const opId = this.filtredoperationals.map(x => x.id);
      this.filtredcountries = this.countries.filter(country => opId.includes(country.operational_direction_id));
    }
  }

  operationalchanged() {
    if (!(( this.multiple && isEmpty(this.selectedOpe)) || (!this.multiple && !this.selectedOpe))) {
       this.filtredcountries = this.countries.filter(
        country => this.multiple ?
          this.selectedOpe.includes(country.operational_direction_id) :
          country.operational_direction_id === this.selectedOpe
      );
    } else {
        this.zonechanged();
    }
  }

  countrieschanged() {
    let opeFromCountryId;
    if (!((this.multiple && isEmpty(this.selectedCountry)) || (!this.multiple && !this.selectedCountry))) {

        if (this.multiple) {
          opeFromCountryId = [...new Set(this.countries.filter(
            country => this.selectedCountry.includes(country.id)).map(x => x.operational_direction_id))];
        }
        this.filtredoperationals = this.operationals.filter(
          ope => this.multiple ?
            opeFromCountryId.includes(ope.id) :
            ope.id === this.countries.find(country => country.id === this.selectedCountry).operational_direction_id
       );

    } else {
        this.zonechanged();
    }
  }

  onClose(event) {

    switch (event) {

      case 'zones':

        this.zonechanged();

        break;
      case 'operationals':
        if(!this.fieldsRequired) {
          this.operationalchanged();
        }
        break;
      case 'countries':
        if(this.fieldsRequired) {
          this.countrieschanged();
        }
        break;
      case 'businesslines':
        this.selectedBusinessline = [];
        break;

    }

  }

  onRemove($event, type) {
   //  console.log('ONREMOVE');
    switch (type) {

      case 'zones':

        this.zonechanged();
        break;
      case 'operationals':
        if(!this.fieldsRequired) {
          this.operationalchanged();
        }
        break;
      case 'countries':
        if(this.fieldsRequired) {
          this.countrieschanged();
        } else {
          this.selectedCountry = [];
        }
        break;
      case 'businesslines':
        this.selectedBusinessline = [];
        break;

    }
  }

  onChange($event, type) {
    // console.log('ONCHANGE');
    switch (type) {

      case 'zones':

        this.selectedOpe = [];
        this.selectedCountry = [];

        break;
      case 'countries':
        if (this.fieldsRequired) {
          this.selectedOpe = [];
        }
        break;
      case 'operationals':
        if (!this.fieldsRequired) {
          this.selectedCountry = [];
        }
        break;
      //  case 'countries':
      //   this.selectedZone = [];
      //   this.selectedOpe = [];
      //   this.selectedCountry = [];

      //   break;

    }

  }

}
