<div class="contacts-container">
    <div class="hstyle align-self-baseline">Contacts</div>
    <div class="space-before-filter"></div>
    <app-project-filter [projects]="projects" [periodFilter]="false" (projectsChange)="filterProjects($event)" [roleFilter]="false"
                        *ngIf="projectsLoaded">
    </app-project-filter>
    <div class="project-contacts">
        <table mat-table matSort matSortActive="project_name" matSortDirection="asc" [dataSource]="dataSource"
            (matSortChange)="sortData($event)">

            <ng-container matColumnDef="project_name" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> PROJECT NAME</th>
                <td mat-cell *matCellDef="let project"
                ><a routerLink="/synthese/dev/{{project.id}}/view">{{ project.title | uppercase}}</a></td>
            </ng-container>

            <ng-container matColumnDef="zone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ZONE</th>
                <td mat-cell *matCellDef="let project">{{ getZone(project.zone.label) }}</td>
            </ng-container>

            <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> COUNTRY</th>
                <td mat-cell *matCellDef="let project">{{ project.country.label_en }}</td>
            </ng-container>

            <ng-container matColumnDef="business_line">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> BUSINESS LINE</th>
                <td mat-cell *matCellDef="let project">{{ project.businessLine.label_en }}</td>
            </ng-container>

            <ng-container matColumnDef="architect">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> PROJECT ARCHITECT</th>
                <td mat-cell *matCellDef="let project">
                    {{ project.architect ? (project.architect?.first_name + ' ' + project.architect?.last_name) : ''}}
                </td>
            </ng-container>

            <ng-container matColumnDef="manager">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> PROJECT MANAGER</th>
                <td mat-cell *matCellDef="let project">
                    {{ project.manager ? (project.manager?.first_name + ' ' + project.manager?.last_name) : ''}}</td>
            </ng-container>

            <ng-container matColumnDef="fp_affiliate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FOCAL POINT AFFILIATE</th>
                <td mat-cell *matCellDef="let project">
                    {{  project.fp_affiliate ? (project.fp_affiliate?.first_name + ' ' + project.fp_affiliate?.last_name) : ''}}
                </td>
            </ng-container>

            <ng-container matColumnDef="fp_slp_tp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FOCAL POINT DTP</th>
                <td mat-cell *matCellDef="let project">
                    {{ project.fp_slp_tp ? (project.fp_slp_tp?.first_name + ' ' + project.fp_slp_tp?.last_name) : ''}}
                </td>
            </ng-container>

            <ng-container matColumnDef="fp_business_line">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FOCAL POINT BUISINESS LINE</th>
                <td mat-cell *matCellDef="let project">
                    {{ project.fp_business_line ? (project.fp_business_line?.first_name + ' ' + project.fp_business_line?.last_name) : ''}}
                </td>
            </ng-container>

            <ng-container matColumnDef="fp_zone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FOCAL POINT ZONE</th>
                <td mat-cell *matCellDef="let project">
                    {{ project.fp_zone ? (project.fp_zone?.first_name + ' ' + project.fp_zone?.last_name) : ''}}</td>
            </ng-container>

            <ng-container matColumnDef="fp_purchasing">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FOCAL POINT PURCHASING</th>
                <td mat-cell *matCellDef="let project" class="col-ellipsis">
                    {{ project.fp_purchasing ? (project.fp_purchasing?.first_name + ' ' + project.fp_purchasing?.last_name) : ''}}
                </td>
            </ng-container>

            <ng-container matColumnDef="fp_hse">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FOCAL POINT HSE</th>
                <td mat-cell *matCellDef="let project">
                    {{ project.fp_hse ? (project.fp_hse?.first_name + ' ' + project.fp_hse?.last_name) : ''}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="text-right" style="min-width: 1500px;width: auto;">
            <mat-paginator [hidePageSize]="false" [pageSizeOptions]="[10, 30, 50,100]" showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>
</div>
