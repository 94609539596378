import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentsBlocComponent} from '../documents-bloc/documents-bloc.component';
import {isArray} from 'lodash';
import {isFileTypeAllowed} from '../../../../shared/models/project';

@Component({
    selector: 'app-pictures-bloc',
    templateUrl: './pictures-bloc.component.html',
    styleUrls: ['./pictures-bloc.component.scss']
})
export class PicturesBlocComponent extends DocumentsBlocComponent implements OnInit {
    @Input() limit = 0;
    @Input() canQuit = true;
    @Output() canQuitChange = new EventEmitter();
    @Input() consultation = false;
    @Input() type: string;

    constructor() {
        // @ts-ignore
        super();
    }

    ngOnInit(): void {

    }

    onFileInput(files) {
        const res = false;
        for (const file of files) {
            isFileTypeAllowed(file, res, this.allowedTypes).then(
                (isAllowed) => {
                    if (((this.files ? this.files.length : 0) + (this.uploadedFiles ? this.uploadedFiles.length : 0)) < this.limit &&
                        isAllowed && (this.maxSize === 0 || (this.maxSize !== 0 && file.size <= this.maxSize))) {
                        this.fileTypeError = false;
                        this.canQuitChange.emit(false);
                        this.files.push(file);
                        // this.documentsFormControl.patchValue({files: this.files});
                    } else {
                        this.fileTypeError = true;
                    }
                }
            );
        }
    }

    viewFile(path) {
        const newWindow = window.open('about:blank');
        const image = new Image();
        image.src = path;

        setTimeout(() => {
            newWindow.document.write(image.outerHTML);
        }, 0);
    }

    change(event) {
        this.canQuitChange.emit(false);
    }

    isArray(array) {
        return isArray(array);
    }
}
