import { Routes } from '@angular/router';
import { LoggedInGuard } from '@ff/core';
import { MyAccountComponent } from './components/my-account/my-account.component';

export const routes: Routes = [
  {
    path: 'user',
    children: [
      { path: 'my-account', component: MyAccountComponent, canActivate: [LoggedInGuard] }
    ]
  }
];
