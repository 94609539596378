import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
    title: string;
    text: string;
    value: string;
    type: string;
}

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
    text_yes: string;
    text_no: string;
    button_save: string;
    button_cancel: string;

    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
    }

    ngOnInit() {
        this.text_yes = 'Yes';
        this.text_no = 'No';
        this.button_save = 'Next';
        this.button_cancel = 'Cancel';
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onClick(): void {
        this.dialogRef.close(this.data.value);
    }
}
