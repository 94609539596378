import {Component, OnInit, Host, Optional, Input, HostListener, AfterViewInit, ElementRef} from '@angular/core';
import {Route, Router} from '@angular/router';
// import {HomeComponent} from '../components/home/home.component';
import {LoggedInGuard} from '@ff/core';
// import {ProjectListComponent} from '../../project/components/project-list/project-list.component';
import {MatSidenav} from '@angular/material/sidenav';
import {UserService} from '@ff/user';
import {User, Role} from '../../model/user';
import {IsAdminGuard} from '../../_guards/is-admin.guard';
import {isNil} from 'lodash';
// import {DatavizComponent} from '../../dataviz/components/dataviz/dataviz.component';
// import {ProjectContactsComponent} from '../../project/components/project-contacts/project-contacts.component';
// import {MapComponent} from '../../project-map/components/map/map.component';
import {Subject} from 'rxjs';
// import {MeetingsComponent} from '../../meeting-calendar/components/meetings/meetings.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, AfterViewInit {
  private _user: User;
  private _routes: Route[] = [];
  private _mobileRoutes: Route[] = [];

  isMobile = new Subject();

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  // @Input() _user: User;
  constructor(@Host() @Optional() private _sidenav: MatSidenav,
              private _router: Router, public userService: UserService, public _elementRef: ElementRef) {

    this._user = this.userService.getUser().getValue();
  }

  ngOnInit(): void {
    this._routes.push(
        {path: 'home', data: {title: 'Home'}},
        {path: 'project/list',  data: {title: 'Projects list'}},
        {path: 'project/map',  data: {title: 'Map'}},
        {path: 'dataviz/view',  data: {title: 'Dataviz'}},
        {path: 'project/contacts', data: {title: 'Contacts'}},
    );

    this._mobileRoutes.push(
        {path: 'home', data: {title: 'Home'}},
        {path: 'project/list',  data: {title: 'Projects list'}},
        {path: 'project/map',  data: {title: 'Map'}},
        {path: 'dataviz/view',  data: {title: 'Dataviz'}},
        {path: 'calendar', data: {title: 'Calendar'}},
    );

    if (!isNil(this._user)) {
      if (this._user.role === Role.SPADMIN || this._user.role === Role.ADMIN) {
        this._routes.push(
            {
              path: 'admin',
              loadChildren: () => import('src/app/modules/administration/administration.module').then(m => m.AdministrationModule),
              canActivate: [LoggedInGuard, IsAdminGuard],
              data: {title: 'ADMIN'}
            },
        );
      }
    }

  }

  ngAfterViewInit(): void {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    this.isMobile.next(window.innerWidth < 992);
  }

  public getRoutes(mobile = false): Route[] {
    return mobile ? this._mobileRoutes : this._routes;
  }

  public close(): void {
    if (this._sidenav) {
      this._sidenav.close();
    }
  }

}
