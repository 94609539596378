import {Injectable} from '@angular/core';
import {ApiService} from '../shared/services/api.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {

    id: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    project: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(
        private _apiService: ApiService,
    ) {
    }

    canEdit(id): Observable<boolean> {
        // @ts-ignore
        return this._apiService.get<boolean>('project/can-edit/' + id);
    }

    exists(id) {
        return this._apiService.get('project/exists/' + id);
    }

    list(date = '') {
        return this._apiService.get('project/listAll' + (date ? ('/' + date) : ''));
    }

    completed() {
      return this._apiService.get('project/completed');
    }

    projectsByStatus(status) {
      return this._apiService.get('project/status/' + status);
    }

    datavizlist(date = '') {
       return this._apiService.get('project/datavizlist' + (date ? ('/' + date) : ''));

    }
    datavizlistByStatus(status, date = '') {
        return this._apiService.get('project/datavizlistByStatus/' + status + (date ? ('/' + date) : ''));
    }

    myProjects() {
        return this._apiService.get('project/my-projects');
    }

    projectsByType(type: string, whose: string) {
        return this._apiService.get('project/list/' + type + '/' + whose);
    }
}
