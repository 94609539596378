<mat-card [formGroup]="filterForm" class="filters" *ngIf="filterForm">
    <mat-expansion-panel [expanded]="expanded">
        <mat-expansion-panel-header (click)="isExpanded = !isExpanded" class="d-flex flex-row align-content-center align-items-center">
            <mat-panel-title style="flex-grow: unset; min-width: 105px;">{{ "filters.title" | translate }}</mat-panel-title>
            <div class="active-label" *ngIf="!isExpanded && (
                    isFormInputNull('text') || isFormInputNull('period') ||
                    selectedZone?.length > 0 || selectedOpe?.length > 0 || selectedCountry?.length > 0 ||
                    selectedBusinessline?.length > 0 || selectedCosts?.length > 0 || 
                    selectedSteps?.length > 0 || selectedCostsPerf?.length > 0 || selectedSchedulePerf?.length > 0 ||
                    selectedJV || selectedNextMil?.length > 0)
                    "
                >Active filters : </div>
            <div class="d-flex flex-row flex-wrap active-filters" style="margin: 10px 0"
                *ngIf="!isExpanded && (
                    isFormInputNull('text') || isFormInputNull('period') ||
                    selectedZone?.length > 0 || selectedOpe?.length > 0 || selectedCountry?.length > 0 ||
                    selectedBusinessline?.length > 0 || selectedCosts?.length > 0 || 
                    selectedSteps?.length > 0 || selectedCostsPerf?.length > 0 || selectedSchedulePerf?.length > 0 ||
                    selectedJV || selectedNextMil?.length > 0)
                "
            >
                <div *ngIf="isFormInputNull('text')" class="active-filter">
                    Text
                </div>
                <div *ngIf="selectedZone?.length > 0" class="active-filter">
                    Zone ({{selectedZone.length}} selected)
                </div>
                <div *ngIf="selectedOpe?.length > 0" class="active-filter">
                    Operational Direction ({{selectedOpe.length}} selected)
                </div>
                <div *ngIf="selectedCountry?.length > 0" class="active-filter">
                    Country ({{selectedCountry.length}} selected)
                </div>
                <div *ngIf="selectedBusinessline?.length > 0" class="active-filter">
                    Business Line ({{selectedBusinessline.length}} selected)
                </div>
                <div *ngIf="isFormInputNull('period')" class="active-filter">
                    Period
                </div>
                <div *ngIf="selectedCosts?.length > 0" class="active-filter">
                    Cost ({{selectedCosts.length}} selected)
                </div>
                <div *ngIf="selectedSteps?.length > 0" class="active-filter">
                    Step ({{selectedZone.length}} selected)
                </div>
                <div *ngIf="selectedCostsPerf?.length > 0" class="active-filter">
                    Cost performance ({{selectedCostsPerf.length}} selected)
                </div>
                <div *ngIf="selectedSchedulePerf?.length > 0" class="active-filter">
                    Schedule performance ({{selectedSchedulePerf.length}} selected)
                </div>
                <div *ngIf="selectedJV" class="active-filter">
                    Joint Venture
                </div>
                <div *ngIf="selectedNextMil?.length > 0" class="active-filter">
                    CDMS/CODIR ({{selectedZone.length}} selected)
                </div>
            </div>
        </mat-expansion-panel-header>

        <div class="row" *ngIf="searchBar">
            <mat-form-field class="col-12 input-search" appearance="outline">
                <input type="text" matInput placeholder="{{'search' | translate}}" formControlName="text"/>
                <div class="icon-override search-icon size-20" matSuffix></div>
            </mat-form-field>
        </div>
        <div class="d-flex align-items-center justify-content-start flex-wrap">
            <ng-select [items]="filtredzones" placeholder="Zone" [multiple]="true" [closeOnSelect]="false" [searchable]="!isMobile"
                       formControlName="zones"  bindLabel="label" bindValue="id" (change)="onChange($event,'zones')"
                       (remove)="onRemove($event,'zones')" (clear)="onClear('zones')" (close)="onClose('zones')" appendTo="body"
                       [(ngModel)]="selectedZone" class="filter-item" [dropdownPosition]="'bottom'" appearance="outline">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input style="margin-right: 20px;" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>
                    {{item.label}}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="items.length > 0">
                        <span class="ng-value-label">{{items.length}} selected</span>
                    </div>
                </ng-template>
                <ng-template ng-header-tmp>
                    <button (click)="selectAll('zones')" class="btn btn-sm ">Select all</button>
                    <button (click)="unselectAll('zones')" class="btn btn-sm ">Unselect all</button>
                </ng-template>
            </ng-select>
            <ng-select [items]="filtredoperationals" [loading]="Opeloading" (change)="onChange($event,'operationals')" [searchable]="!isMobile"
                       (remove)="onRemove($event,'operationals')" (clear)="onClear('operationals')" (close)="onClose('operationals')"
                       placeholder="Operational direction" bindLabel="label_en" [multiple]="true" class="filter-item large-item" [closeOnSelect]="false"
                       bindValue="id" formControlName="operational_directions" appendTo="body" [(ngModel)]="selectedOpe" [dropdownPosition]="'bottom'"
                       appearance="outline">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input style="margin-right: 20px;" class="primary" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                           [ngModelOptions]="{standalone: true}"/>
                    {{item.label_en}}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="items.length > 0">
                        <span class="ng-value-label">{{items.length}} selected</span>
                    </div>
                </ng-template>
                <ng-template ng-header-tmp>
                    <button (click)="selectAll('operationals')" class="btn btn-sm">Select all</button>
                    <button (click)="unselectAll('operationals')" class="btn btn-sm">Unselect all</button>
                </ng-template>
            </ng-select>
             <ng-select [items]="filtredcountries" [loading]="Countriesloading" placeholder="Country" [multiple]="true"
                 bindLabel="label_en" bindValue="id" formControlName="countries" class="filter-item"
                 [searchable]="!isMobile" (change)="onChange($event,'countries')" appearance="outline"
                 (remove)="onRemove($event,'countries')" (clear)="onClear('countries')" (close)="onClose('countries')"
                 [closeOnSelect]="false" appendTo="body" [(ngModel)]="selectedCountry" [dropdownPosition]="'bottom'">
                 <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                     <input style="margin-right: 20px;" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                         [ngModelOptions]="{standalone: true}" />
                     {{item.label_en}}
                 </ng-template>
                 <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                     <div class="ng-value" *ngIf="items.length > 0">
                         <span class="ng-value-label">{{items.length}} selected</span>
                     </div>
                 </ng-template>
                 <ng-template ng-header-tmp>
                     <button (click)="selectAll('countries')" class="btn btn-sm ">Select all</button>
                     <button (click)="unselectAll('countries')" class="btn btn-sm ">Unselect all</button>
                 </ng-template>
             </ng-select>
           
            <ng-select [items]="filtredbusinesslines" [loading]="Businessloading" placeholder="Business line"
                bindLabel="label_en" bindValue="id" formControlName="business_lines" [multiple]="true" [searchable]="!isMobile"
                (change)="onChange($event,'businesslines')" (remove)="onRemove($event,'businesslines')" appearance="outline"
                (clear)="onClear('businesslines')" (close)="onClose('businesslines')" [closeOnSelect]="false"
                appendTo="body" [(ngModel)]="selectedBusinessline" class="filter-item" [dropdownPosition]="'bottom'">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input style="margin-right: 20px;" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>
                    {{item.label_en}}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="items.length > 0">
                        <span class="ng-value-label">{{items.length}} selected</span>
                    </div>
                </ng-template>
                <ng-template ng-header-tmp>
                    <button (click)="selectAll('businesslines')" class="btn btn-sm">Select all</button>
                    <button (click)="unselectAll('businesslines')" class="btn btn-sm">Unselect all</button>
                </ng-template>
            </ng-select>
            <app-month-year-picker class="filter-item" placeholder="Period"
                    [filldesign]="true" formControlName="period"
                    [startAt]="startAt" *ngIf="!mobile && periodFilter">
            </app-month-year-picker>
            <ng-select [items]="costs" placeholder="Cost" appearance="outline"
                       bindLabel="label" bindValue="id" formControlName="costs" [multiple]="true" [dropdownPosition]="'bottom'"
                       (change)="onChange($event,'costs')" (remove)="onRemove($event,'costs')" [searchable]="!isMobile"
                       (clear)="onClear('costs')" (close)="onClose('costs')" [closeOnSelect]="false"
                       appendTo="body" [(ngModel)]="selectedCosts" class="filter-item" *ngIf="!mobile && costFilter">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input style="margin-right: 20px;" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>
                    {{item.label}}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="items.length > 0">
                        <span class="ng-value-label">{{items.length}} selected</span>
                    </div>
                </ng-template>
                <ng-template ng-header-tmp>
                    <button (click)="selectAll('costs')" class="btn btn-sm">Select all</button>
                    <button (click)="unselectAll('costs')" class="btn btn-sm">Unselect all</button>
                </ng-template>
            </ng-select>
            <ng-select [items]="projectSteps" placeholder="Step" appearance="outline"
                       formControlName="steps" [multiple]="true" [closeOnSelect]="false" [searchable]="!isMobile"
                       (change)="onChange($event,'steps')" (remove)="onRemove($event,'steps')"
                       (clear)="onClear('steps')" (close)="onClose('steps')"
                       appendTo="body" [(ngModel)]="selectedSteps" class="filter-item"
                       *ngIf="stepFilter" [dropdownPosition]="'bottom'">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input style="margin-right: 20px;" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>{{item | uppercase}}</ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="items.length > 0">
                        <span class="ng-value-label">{{items.length}} selected</span>
                    </div>
                </ng-template>
                <ng-template ng-header-tmp>
                    <button (click)="selectAll('steps')" class="btn btn-sm">Select all</button>
                    <button (click)="unselectAll('steps')" class="btn btn-sm">Unselect all</button>
                </ng-template>
            </ng-select>
            <ng-select [items]="performances" placeholder="Cost performance" [searchable]="!isMobile" appearance="outline"
                       formControlName="cost_performances" [multiple]="true" [closeOnSelect]="false"
                       (change)="onChange($event,'cost_performances')" (remove)="onRemove($event,'cost_performances')"
                       (clear)="onClear('cost_performances')" (close)="onClose('cost_performances')"
                       appendTo="body" [(ngModel)]="selectedCostsPerf" bindLabel="label" bindValue="id"
                       *ngIf="!mobile && costPerfFilter" class="filter-item large-item" [dropdownPosition]="'bottom'">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input style="margin-right: 20px;" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>
                    <div
                        style="width: 20px;height: 20px;content: url('/assets/{{item.label === 'good' ? 'Smile.svg' : (item.label === 'medium' ? 'Neutral.svg' : (item.label === 'bad' ? 'Sad.svg' : ''))}}');"
                        class="{{item.label !== 'undefined' ? ('smiley ' + item.label) : ''}}" *ngIf="item.label !== 'undefined'"
                    ></div>
                    {{item.label === 'undefined' ? 'Undefined' : ''}}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="items.length > 0">
                        <span class="ng-value-label">{{items.length}} selected</span>
                    </div>
                </ng-template>
                <ng-template ng-header-tmp>
                    <button (click)="selectAll('cost_performances')" class="btn btn-sm">Select all</button>
                    <button (click)="unselectAll('cost_performances')" class="btn btn-sm">Unselect all</button>
                </ng-template>
            </ng-select>
            <ng-select [items]="performances" placeholder="Schedule performance" [dropdownPosition]="'bottom'" [searchable]="!isMobile"
                       formControlName="schedule_performances" [multiple]="true" class="filter-item large-item" [closeOnSelect]="false"
                       (change)="onChange($event,'schedule_performances')" (remove)="onRemove($event,'schedule_performances')"
                       (clear)="onClear('schedule_performances')" (close)="onClose('schedule_performances')" appearance="outline"
                       appendTo="body" [(ngModel)]="selectedSchedulePerf" bindLabel="label" bindValue="id" *ngIf="!mobile && schedulePerfFilter">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input style="margin-right: 20px;" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>
                    <div style="width: 20px;height: 20px;content: url('/assets/{{item.label === 'good' ? 'Smile.svg' : (item.label === 'medium' ? 'Neutral.svg' : (item.label === 'bad' ? 'Sad.svg' : ''))}}');"
                            class="{{item.label !== 'undefined' ? ('smiley ' + item.label) : ''}}" *ngIf="item.label !== 'undefined'"
                    ></div>
                    {{item.label === 'undefined' ? 'Undefined' : ''}}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="items.length > 0">
                        <span class="ng-value-label">{{items.length}} selected</span>
                    </div>
                </ng-template>
                <ng-template ng-header-tmp>
                    <button (click)="selectAll('schedule_performances')" class="btn btn-sm">Select all</button>
                    <button (click)="unselectAll('schedule_performances')" class="btn btn-sm">Unselect all</button>
                </ng-template>
            </ng-select>
            <ng-select [items]="jv_options" placeholder="Joint Ventures" [dropdownPosition]="'bottom'"
                       formControlName="joint_ventures" [multiple]="false" class="filter-item" [searchable]="!isMobile"
                       (close)="applyAllFilters()" (change)="onChange($event,'jv')" (remove)="onRemove($event,'jv')"
                       (clear)="onClear('jv')" appearance="outline"
                       appendTo="body" [(ngModel)]="selectedJV" bindLabel="label" bindValue="value"
                       *ngIf="jvFilter">
            </ng-select>
            <ng-select [items]="role_options" placeholder="Operated by TE" [dropdownPosition]="'bottom'"
                       formControlName="total_role" [multiple]="false" class="filter-item large-item" [searchable]="!isMobile"
                       (close)="applyAllFilters()" (change)="onChange($event,'role')" (remove)="onRemove($event,'role')"
                       (clear)="onClear('role')" appearance="outline" appendTo="body" [(ngModel)]="selectedRole"
                       bindLabel="label" bindValue="value" *ngIf="roleFilter">
            </ng-select>
            <ng-select [items]="next_mil_options" placeholder="CDMS/CODIR" [dropdownPosition]="'bottom'" [closeOnSelect]="false"
                       formControlName="next_milestone" [multiple]="true" class="filter-item" [searchable]="!isMobile"
                       (close)="applyAllFilters()" (change)="onChange($event,'next_milestone')" (remove)="onRemove($event,'next_milestone')"
                       (clear)="onClear('next_milestone')" appearance="outline"
                       appendTo="body" [(ngModel)]="selectedNextMil" bindLabel="label" bindValue="label"
                       *ngIf="nextMilFilter">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input style="margin-right: 20px;" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>
                    {{item.label}}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="items.length > 0">
                        <span class="ng-value-label">{{items.length}} selected</span>
                    </div>
                </ng-template>
                <ng-template ng-header-tmp>
                    <button (click)="selectAll('next_milestone')" class="btn btn-sm">Select all</button>
                    <button (click)="unselectAll('next_milestone')" class="btn btn-sm">Unselect all</button>
                </ng-template>
            </ng-select>
        </div>
    </mat-expansion-panel>
</mat-card>
