<mat-form-field appearance="outline" *ngIf="!filldesign; else filldesignBlock" class="col-12">
  <mat-label>{{ placeholder }}</mat-label>
  <input size="10" matInput [matDatepicker]="picker" [formControl]="dateControl" />
  <mat-placeholder class="placeholder">{{ placeholder }}</mat-placeholder>
  <mat-datepicker-toggle matSuffix [for]="picker">
    <div class="icon-override size-22 calendar-icon" matDatepickerToggleIcon></div>
  </mat-datepicker-toggle>
  <mat-datepicker #picker startView="year" (monthSelected)="chosenMonthHandler($event, picker)"
    (yearSelected)="chosenYearHandler($event)" [startAt]="startAt" disabled="false"></mat-datepicker>
</mat-form-field>

<ng-template #filldesignBlock>
  <mat-form-field appearance="outline" style="width: 100%;">
    <input size="10" matInput [matDatepicker]="picker" [formControl]="dateControl" />
    <mat-label class="placeholder">{{ placeholder }}</mat-label>
    <mat-datepicker-toggle matSuffix [for]="picker">
      <div class="icon-override size-22 calendar-icon" matDatepickerToggleIcon></div>
    </mat-datepicker-toggle>
    <mat-datepicker #picker startView="year" (monthSelected)="chosenMonthHandler($event, picker)"
      (yearSelected)="chosenYearHandler($event)" [startAt]="startAt" disabled="false"></mat-datepicker>
  </mat-form-field>
</ng-template>