import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {PROJECT_STEP_GROUP, PROJECT_STEP_ITEM} from 'src/app/modules/model/Monthly';
import {FormBuilder} from '@angular/forms';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import {isNil, isArray, flatten} from 'lodash';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import {LoaderService} from '@ff/loader';
import * as htmlToImage from 'html-to-image';
import {ProjectService} from 'src/app/modules/_services/project.service';
import {Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import { ProjectModalComponent} from '../../../shared/components/project-modal/project-modal.component';
import { loaderHide, loaderShow } from 'src/app/modules/shared/models/project';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  hidePrint = true;

  applyFilters = new Subject();
  expanded = true;
  percentage: number;
  percentageDeux: number;
  percentageTrois: number;
  width: number;
  height: number;
  data = [];
  selectedProject;
  step = 'all';
  needsArray = [];
  displayWarning = false;
  needs_points = [
    {cx: 95, cy: 327, tx: 95, ty: 359},
    {cx: 35, cy: 309, tx: 35, ty: 341},
    {cx: 95, cy: 265, tx: 95, ty: 297},
    {cx: 35, cy: 246, tx: 35, ty: 280},
    {cx: 95, cy: 387, tx: 95, ty: 419},
    {cx: 35, cy: 368, tx: 35, ty: 400},
    {cx: 95, cy: 203, tx: 95, ty: 235},
    {cx: 35, cy: 185, tx: 35, ty: 216},
    {cx: 95, cy: 449, tx: 95, ty: 481},
    {cx: 35, cy: 431, tx: 35, ty: 462},
    {cx: 95, cy: 141, tx: 95, ty: 173},
    {cx: 35, cy: 122, tx: 35, ty: 155},
    {cx: 95, cy: 508, tx: 95, ty: 540},
    {cx: 35, cy: 490, tx: 35, ty: 521},
  ];
  need_totalprojects: number;
  need_total_cost: number;

  feasibility_column_1 = [];
  feasibility_column_2 = [];
  feasibility_data_1 = [];
  feasibility_data_2 = [];

  feasibilty_points_column_1 = [
    {cx: 169, cy: 342, tx: 169, ty: 374},
    {cx: 169, cy: 279, tx: 169, ty: 313},
    {cx: 169, cy: 401, tx: 169, ty: 433},
    {cx: 169, cy: 218, tx: 169, ty: 249},
    {cx: 169, cy: 464, tx: 169, ty: 495},
    {cx: 169, cy: 155, tx: 169, ty: 188},
  ];
  feasibilty_points_column_2 = [
    {cx: 229, cy: 298, tx: 229, ty: 330},
    {cx: 229, cy: 360, tx: 229, ty: 392},
    {cx: 229, cy: 236, tx: 229, ty: 268},
    {cx: 229, cy: 420, tx: 229, ty: 452},
    {cx: 229, cy: 174, tx: 229, ty: 206},
    {cx: 229, cy: 482, tx: 229, ty: 514},
  ];

  feasibilty_totalprojects: number;
  feasibilty_total_cost: number;

  basicData = [];
  basic_point = [];
  total_cost_basic: number;
  total_cost_basic_projects: number;

  feedData = [];
  feed_point = [];
  total_cost_feed: number;
  total_cost_feed_projects: number;

  cftData = [];
  cft_point = [];
  total_cost_cft: number;
  total_cost_cft_projects: number;

  exeData = [];
  exe_point = [];
  total_cost_exe: number;
  total_cost_exe_projects: number;

  costs = [
    {id: 0, label: '<1M$'},
    {id: 1, label: '1-4M$'},
    {id: 2, label: '4-20M$'},
    {id: 3, label: '>20M$'},
  ];
  phases = [
    {id: 0, label: PROJECT_STEP_GROUP.DEV},
    {id: 1, label: PROJECT_STEP_GROUP.EXE},
  ];

  allProjects: [];
  Nbrglobalproject;
  Costglobalproject;
  @ViewChild('popup') popup: ElementRef;

  isMobile: boolean;
  issmallMobile: boolean;
  showCanceledProjects = false;
  showCompletedProjects = false;
  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth < 700;
    this.issmallMobile = window.innerWidth < 427;
  }
  constructor(private _loaderService: LoaderService,
              private _route: ActivatedRoute, private _fb: FormBuilder, private _router: Router,
              private _projectService: ProjectService, private matDialog: MatDialog
  ) {
    this.isMobile = window.innerWidth < 700;
    this.issmallMobile = window.innerWidth < 427;
    this._route.data.subscribe(data => {
      this.allProjects = data.projects;
      this.needsArray = this.allProjects
          .filter((el: any) => el.project_step === PROJECT_STEP_ITEM.NEEDS);
      this.need_totalprojects = this.needsArray.length;
      const costdata = this.allProjects
          .filter((el: any) => el.project_step === PROJECT_STEP_ITEM.NEEDS)
          .reduce((a: any, b: any) => (a + b.cost), 0);
      this.need_total_cost = costdata;

    });

    this.basic_point[0] = [
      {cx: 290, cy: 298, tx: 290, ty: 331},
      {cx: 290, cy: 361, tx: 290, ty: 394},
      {cx: 290, cy: 235, tx: 290, ty: 268},
      {cx: 290, cy: 421, tx: 290, ty: 454},
      {cx: 290, cy: 172, tx: 290, ty: 205},
      {cx: 290, cy: 484, tx: 290, ty: 517},
    ];
    this.basic_point[1] = [
      {cx: 333, cy: 288, tx: 333, ty: 321},
      {cx: 333, cy: 351, tx: 333, ty: 384},
      {cx: 333, cy: 224, tx: 333, ty: 258},
      {cx: 333, cy: 411, tx: 333, ty: 444},
      {cx: 333, cy: 160, tx: 333, ty: 194},
      {cx: 333, cy: 474, tx: 333, ty: 507},
    ];
    this.basic_point[2] = [
      {cx: 375, cy: 298, tx: 375, ty: 331},
      {cx: 375, cy: 361, tx: 375, ty: 394},
      {cx: 375, cy: 235, tx: 375, ty: 268},
      {cx: 375, cy: 421, tx: 375, ty: 454},
      {cx: 375, cy: 172, tx: 375, ty: 205},
      {cx: 375, cy: 484, tx: 375, ty: 517},
    ];
    /**
     * Feed Point Init
     */
    this.feed_point[0] = [
      {cx: 423, cy: 325, tx: 423, ty: 358},
      {cx: 423, cy: 261, tx: 423, ty: 294},
      {cx: 423, cy: 384, tx: 423, ty: 417},
      {cx: 423, cy: 199, tx: 423, ty: 232},
      {cx: 423, cy: 448, tx: 423, ty: 481},

    ];
    this.feed_point[1] = [
      {cx: 467, cy: 315, tx: 467, ty: 348},
      {cx: 467, cy: 251, tx: 467, ty: 284},
      {cx: 467, cy: 374, tx: 467, ty: 407},
      {cx: 467, cy: 189, tx: 467, ty: 222},
      {cx: 467, cy: 438, tx: 467, ty: 471},

    ];
    this.feed_point[2] = [
      {cx: 510, cy: 325, tx: 510, ty: 358},
      {cx: 510, cy: 261, tx: 510, ty: 294},
      {cx: 510, cy: 384, tx: 510, ty: 417},
      {cx: 510, cy: 199, tx: 510, ty: 232},
      {cx: 510, cy: 448, tx: 510, ty: 481},

    ];
    /**
     * CFT Point Init
     */
    this.cft_point[0] = [
      {cx: 555, cy: 325, tx: 555, ty: 358},
      {cx: 555, cy: 261, tx: 555, ty: 294},
      {cx: 555, cy: 384, tx: 555, ty: 417},
      {cx: 555, cy: 199, tx: 555, ty: 232},
      {cx: 555, cy: 448, tx: 555, ty: 481},

    ];
    this.cft_point[1] = [
      {cx: 599, cy: 315, tx: 599, ty: 348},
      {cx: 599, cy: 251, tx: 599, ty: 284},
      {cx: 599, cy: 374, tx: 599, ty: 407},
      {cx: 599, cy: 189, tx: 599, ty: 222},
      {cx: 599, cy: 438, tx: 599, ty: 471},

    ];
    this.cft_point[2] = [
      {cx: 642, cy: 325, tx: 642, ty: 358},
      {cx: 642, cy: 261, tx: 642, ty: 294},
      {cx: 642, cy: 384, tx: 642, ty: 417},
      {cx: 642, cy: 199, tx: 642, ty: 232},
      {cx: 642, cy: 448, tx: 642, ty: 481},

    ];
    /**
     * EXE DATA
     */

    this.exe_point[0] = [

      { cx: 688, cy: 354, tx: 688, ty: 387 },
      { cx: 688, cy: 289, tx: 688, ty: 322 },
      { cx: 688, cy: 224, tx: 688, ty: 257 },
      { cx: 688, cy: 417, tx: 688, ty: 450 },
      { cx: 688, cy: 164, tx: 688, ty: 192 },
      { cx: 688, cy: 99, tx: 688, ty: 127 },
    ];
    this.exe_point[1] = [

      { cx: 732, cy: 344, tx: 732, ty: 377 },
      { cx: 732, cy: 279, tx: 732, ty: 312 },
      { cx: 732, cy: 214, tx: 732, ty: 247 },
      { cx: 732, cy: 407, tx: 732, ty: 440 },
      { cx: 732, cy: 149, tx: 732, ty: 182 },
      { cx: 732, cy: 84, tx: 732, ty: 117 },
    ];
    this.exe_point[2] = [

      { cx: 774, cy: 354, tx: 774, ty: 387 },
      { cx: 774, cy: 289, tx: 774, ty: 322 },
      { cx: 774, cy: 224, tx: 774, ty: 257 },
      { cx: 774, cy: 417, tx: 774, ty: 450 },
      { cx: 774, cy: 159, tx: 774, ty: 192 },
      { cx: 774, cy: 94, tx: 774, ty: 127 },
    ];
    this.exe_point[3] = [

      { cx: 816, cy: 344, tx: 816, ty: 377 },
      { cx: 816, cy: 279, tx: 816, ty: 312 },
      { cx: 816, cy: 214, tx: 816, ty: 247 },
      { cx: 816, cy: 407, tx: 816, ty: 440 },
      { cx: 816, cy: 149, tx: 816, ty: 182 },
      { cx: 816, cy: 84, tx: 816, ty: 117 },
    ];

    this.exe_point[4] = [

      { cx: 858, cy: 354, tx: 858, ty: 387 },
      { cx: 858, cy: 289, tx: 858, ty: 322 },
      { cx: 858, cy: 224, tx: 858, ty: 257 },
      { cx: 858, cy: 417, tx: 858, ty: 450 },
      { cx: 858, cy: 159, tx: 858, ty: 192 },
      { cx: 858, cy: 94, tx: 858, ty: 127 },
    ];

    this.exe_point[5] = [

      { cx: 900, cy: 344, tx: 900, ty: 377 },
      { cx: 900, cy: 279, tx: 900, ty: 312 },
      { cx: 900, cy: 214, tx: 900, ty: 247 },
      { cx: 900, cy: 407, tx: 900, ty: 440 },
      { cx: 900, cy: 149, tx: 900, ty: 182 },
      { cx: 900, cy: 84, tx: 900, ty: 117 },
    ];
    this.exe_point[6] = [

      { cx: 944, cy: 354, tx: 944, ty: 387 },
      { cx: 944, cy: 289, tx: 944, ty: 322 },
      { cx: 944, cy: 224, tx: 944, ty: 257 },
      { cx: 944, cy: 417, tx: 944, ty: 450 },
      { cx: 944, cy: 159, tx: 944, ty: 192 },
      { cx: 944, cy: 94, tx: 944, ty: 127 },
    ];

    this.exe_point[7] = [

      { cx: 988, cy: 344, tx: 988, ty: 377 },
      { cx: 988, cy: 279, tx: 988, ty: 312 },
      { cx: 988, cy: 214, tx: 988, ty: 247 },
      { cx: 988, cy: 407, tx: 988, ty: 440 },
      { cx: 988, cy: 149, tx: 988, ty: 182 },
      { cx: 988, cy: 84, tx: 988, ty: 117 },
    ];

    this.exe_point[8] = [

      { cx: 1030, cy: 354, tx: 1030, ty: 387 },
      { cx: 1030, cy: 289, tx: 1030, ty: 322 },
      { cx: 1030, cy: 224, tx: 1030, ty: 257 },
      { cx: 1030, cy: 417, tx: 1030, ty: 450 },
      { cx: 1030, cy: 159, tx: 1030, ty: 192 },
      { cx: 1030, cy: 94, tx: 1030, ty: 127 },
    ];

    this.exe_point[9] = [

      { cx: 1071, cy: 344, tx: 1071, ty: 377 },
      { cx: 1071, cy: 279, tx: 1071, ty: 312 },
      { cx: 1071, cy: 214, tx: 1071, ty: 247 },
      { cx: 1071, cy: 407, tx: 1071, ty: 440 },
      { cx: 1071, cy: 149, tx: 1071, ty: 182 },
      { cx: 1071, cy: 84, tx: 1071, ty: 117 },
    ];

    this.exe_point[10] = [

      { cx: 1115, cy: 354, tx: 1115, ty: 387 },
      { cx: 1115, cy: 289, tx: 1115, ty: 322 },
      { cx: 1115, cy: 224, tx: 1115, ty: 257 },
      { cx: 1115, cy: 417, tx: 1115, ty: 450 },
      { cx: 1115, cy: 159, tx: 1115, ty: 192 },
      { cx: 1115, cy: 94, tx: 1115, ty: 127 },
    ];

  }

  ngOnInit() {
    // @ts-ignore
    window.html2canvas = html2canvas;
    this.initForm();
    this.globaldata(this.allProjects);
    this.initNeedsData(this.needsArray);
    this.initFeasibilityData(this.allProjects);
    this.initBasicData(this.allProjects);
    this.initFeedData(this.allProjects);
    this.initCftData(this.allProjects);
    this.initExeData(this.allProjects);

  }

  public globaldata(listprojects) {
    this.Costglobalproject = listprojects.filter(project => project.phase !== null)
        .reduce((a: any, b: any) => (a + b.cost), 0);
    this.Nbrglobalproject = listprojects.filter(project => project.phase !== null).length;

  }

  public generatePDF() {
    this.hidePrint = false;
    this._loaderService.show();
    htmlToImage.toPng(document.getElementById('funnel-export'))
        .then((blob) => {
          const pdf = new jspdf('l', 'cm', 'a4'); // A4 size page of PDF
          const position = 0;
          pdf.addImage(blob, 'PNG', 0, position, 27, 17);
          pdf.save('Funnel.pdf'); // Generated PDF
        })
        .then(() => {
          this.hidePrint = true;
          this._loaderService.hide();
        });
  }

  public generatePDF_old_aproche() {
    this._loaderService.show();

    const data = document.getElementById('funnel-svg');
    // console.log(data.innerHTML);
    html2canvas(data, {allowTaint: true}).then(canvas => {
      const HTML_Width = canvas.width;
      const HTML_Height = canvas.height;
      const top_left_margin = 15;
      const PDF_Width = HTML_Width + (top_left_margin * 2);
      const PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      const canvas_image_width = HTML_Width;
      const canvas_image_height = HTML_Height;
      const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext('2d');
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new jspdf('l', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save('Funnel.pdf');

      this._loaderService.hide();

    });
  }

  initExeData(listprojects) {
    const tempexeData = [];
    let display = false;
    this.displayWarning = false;
    this.exeData = [];
    let count = 0;

    this.exe_point.forEach((data, i) => {
      if (count < 90) {
        tempexeData[i] = listprojects
            ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.EXE)
            .filter((el: any) => (count <= el.progress && el.progress < count + 10));
        count += 10;
      } else {
        tempexeData[i] = listprojects
            ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.EXE)
            .filter((el: any) => (count <= el.progress && el.progress < count + 9));
        count += 9;
      }

    });
    this.total_cost_exe = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.EXE)
        .reduce((a: any, b: any) => (a + b.cost), 0);

    const total_cft_project = flatten(tempexeData);
    this.total_cost_exe_projects = total_cft_project.length;
    // console.log(display);
    // console.log(this.displayWarning);

    tempexeData.forEach((value, index) => {

      const tempData = value;
      tempData.forEach((data, i) => {
        //  console.log(i);
        //  console.log(this.exe_point[index].length);

         if (i < this.exe_point[index].length) {
          this.exeData.push({
            title: this.getTitle(data.title.toUpperCase()),
            dataProject: data,
            cx: this.exe_point[index][i].cx,
            cy: this.exe_point[index][i].cy,
            tx: this.exe_point[index][i].tx,
            ty: this.getTextY(this.exe_point[index][i].ty, data.cost),
            color: this.getColor(data.zone.label),
            r: this.getRadius(data.cost),
          });
        } else {
          display = true;
        }
      });

    });
    if (display) {
      this.displayWarning = true;
    }
  }

  initCftData(listprojects) {

    const tempcftData = [];
    this.cftData = [];
    tempcftData[0] = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.CFT)
        .filter((el: any) => el.progress < 95);
    tempcftData[1] = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.CFT)
        .filter((el: any) => (95 <= el.progress && el.progress < 99));
    tempcftData[2] = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.CFT)
        .filter((el: any) => (99 <= el.progress));
    this.total_cost_cft = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.CFT)
        .reduce((a: any, b: any) => (a + b.cost), 0);
    const total_feed_project = flatten(tempcftData);
    this.total_cost_cft_projects = total_feed_project.length;
    tempcftData.forEach((value, index) => {

      const tempData = value;
      tempData.forEach((data, i) => {
        if (i < this.cft_point[index].length) {
          this.cftData.push({
            title: this.getTitle(data.title.toUpperCase()),
            dataProject: data,
            cx: this.cft_point[index][i].cx,
            cy: this.cft_point[index][i].cy,
            tx: this.cft_point[index][i].tx,
            ty: this.getTextY(this.cft_point[index][i].ty, data.cost),
            color: this.getColor(data.zone.label),
            r: this.getRadius(data.cost),
          });
        }
      });

    });

  }

  initFeedData(listprojects) {

    const tempfeedData = [];
    this.feedData = [];
    tempfeedData[0] = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.FEED)
        .filter((el: any) => el.progress < 51);
    tempfeedData[1] = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.FEED)
        .filter((el: any) => (51 <= el.progress && el.progress < 67));
    tempfeedData[2] = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.FEED)
        .filter((el: any) => (67 <= el.progress));
    this.total_cost_feed = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.FEED)
        .reduce((a: any, b: any) => (a + b.cost), 0);
    const total_feed_project = flatten(tempfeedData);
    this.total_cost_feed_projects = total_feed_project.length;
    tempfeedData.forEach((value, index) => {

      const tempData = value;
      tempData.forEach((data, i) => {
        if (i < this.feed_point[index].length) {
          this.feedData.push({
            title: this.getTitle(data.title.toUpperCase()),
            dataProject: data,
            cx: this.feed_point[index][i].cx,
            cy: this.feed_point[index][i].cy,
            tx: this.feed_point[index][i].tx,
            ty: this.getTextY(this.feed_point[index][i].ty, data.cost),
            color: this.getColor(data.zone.label),
            r: this.getRadius(data.cost),
          });
        }
      });

    });

  }

  initBasicData(listprojects) {

    const tempbasicData = [];
    this.basicData = [];
    tempbasicData[0] = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.BASIC)
        .filter((el: any) => el.progress < 18);
    tempbasicData[1] = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.BASIC)
        .filter((el: any) => (18 <= el.progress && el.progress < 26));
    tempbasicData[2] = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.BASIC)
        .filter((el: any) => (26 <= el.progress));
    this.total_cost_basic = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.BASIC)
        .reduce((a: any, b: any) => (a + b.cost), 0);
    const total_basic_project = flatten(tempbasicData);
    this.total_cost_basic_projects = total_basic_project.length;

    tempbasicData.forEach((value, index) => {

      const tempData = value;
      tempData.forEach((data, i) => {
        if (i < this.basic_point[index].length) {
          this.basicData.push({
            title: this.getTitle(data.title.toUpperCase()),
            dataProject: data,
            cx: this.basic_point[index][i].cx,
            cy: this.basic_point[index][i].cy,
            tx: this.basic_point[index][i].tx,
            ty: this.getTextY(this.basic_point[index][i].ty, data.cost),
            color: this.getColor(data.zone.label),
            r: this.getRadius(data.cost),
          });
        }
      });

    });

  }

  initFeasibilityData(listprojects) {
    this.feasibility_data_1 = [];
    this.feasibility_data_2 = [];
    this.feasibility_column_1 = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.FEASIBILITY)
        .filter((el: any) => el.progress <= 6);
    this.feasibility_column_2 = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.FEASIBILITY)
        .filter((el: any) => el.progress >= 6);
    this.feasibilty_totalprojects = this.feasibility_column_1.length + this.feasibility_column_2.length;
    const costdataFeasibility = listprojects
        ?.filter((el: any) => el.project_step === PROJECT_STEP_ITEM.FEASIBILITY)
        .reduce((a: any, b: any) => (a + b.cost), 0);
    this.feasibilty_total_cost = costdataFeasibility;

    this.feasibility_column_1.forEach((value, index) => {
      if (index < this.feasibilty_points_column_1.length) {
        this.feasibility_data_1.push({
          title: this.getTitle(value.title.toUpperCase()),
          dataProject: value,
          cx: this.feasibilty_points_column_1[index].cx,
          cy: this.feasibilty_points_column_1[index].cy,
          tx: this.feasibilty_points_column_1[index].tx,
          ty: this.getTextY(this.feasibilty_points_column_1[index].ty, value.cost),
          color: this.getColor(value.zone.label),
          r: this.getRadius(value.cost),
        });
      }
    });

    this.feasibility_column_2.forEach((value, index) => {
      if (index < this.feasibilty_points_column_2.length) {
        this.feasibility_data_2.push({
          title: this.getTitle(value.title),
          dataProject: value,
          cx: this.feasibilty_points_column_2[index].cx,
          cy: this.feasibilty_points_column_2[index].cy,
          tx: this.feasibilty_points_column_2[index].tx,
          ty: this.getTextY(this.feasibilty_points_column_2[index].ty, value.cost),
          color: this.getColor(value.zone.label),
          r: this.getRadius(value.cost),
        });
      }
    });

  }

  getTextY(yCoordinate, cost) {
    const raduis = this.getRadius(cost);
    if (raduis === 7) {
      return yCoordinate - 10;
    } else {
      return yCoordinate;
    }

  }

  initNeedsData(listprojects) {
    this.data = [];
    const tempprojects = listprojects
        .filter((el: any) => el.project_step === PROJECT_STEP_ITEM.NEEDS);

    this.need_totalprojects = tempprojects.length;
    const costdata = tempprojects
        .filter((el: any) => el.project_step === PROJECT_STEP_ITEM.NEEDS)
        .reduce((a: any, b: any) => (a + b.cost), 0);
    this.need_total_cost = costdata;

    tempprojects.forEach((value, index) => {

      if (index < this.needs_points.length) {
      this.data.push({
        title: this.getTitle(value.title.toUpperCase()),
        dataProject: value,
        cx: this.needs_points[index].cx,
        cy: this.needs_points[index].cy,
        tx: this.needs_points[index].tx,
        ty: this.needs_points[index].ty,
        color: this.getColor(value.zone.label),
        r: this.getRadius(value.cost),
      });
         }

    });
  }

  getFID(project) {
    if (!isNil(project)) {
      const milestones = project.milestones;
      if (!isNil(milestones) && isArray(milestones)) {
        return !isNil(milestones[13].revised) ? moment.utc(milestones[13].revised).format('DD/MM/YYYY') : '';
      }
    }
    return '';
  }

  getRFSU(project) {
    if (!isNil(project)) {
      const milestones = project.milestones;
      if (!isNil(milestones) && !isArray(milestones)) {
        return !isNil(milestones.rfsu_forecast) ? moment.utc(milestones.rfsu_forecast).format('DD/MM/YYYY') : '';
      }
    }
    return '';
  }

  getLastUpdate(project) {
    if (!isNil(project?.period)) {
      return moment.utc(project.period).format('MMM YYYY');
    }
    return 'Draft';
  }

  getSmiley(percentage, phase) {
    return !isNil(percentage) ? (
        (percentage <= 0 ? 'good' :
                (0 < percentage && percentage <= (phase === 'EXE' ? 10 : 20) ? 'medium' : 'bad')
        )
    ) : '';
  }

  synthese() {
    this._router.navigate(['/synthese/dev/' + this.selectedProject.id + '/view']);
  }

  contacts() {
    this._router.navigate(['/project/consultation/' + this.selectedProject.id]);
  }

  getTitle(title: string) {
    const legnthTitle = title.length;
    return legnthTitle >= 14 ? title.substr(0, 14) + '...' : title;
  }

  getColor(zone: string) {
    let color;
    switch (zone) {
      case 'AFR - Africa':
        color = '#ED0000';
        break;
      case 'AME - Americas':
        color = '#FF3C3C';

        break;
      case 'APMO - Asia-Pacific - Middle-East':
        color = '#7D7064';

        break;
      case 'EUR - Europe':
        color = '#000000';
        break;
      default:
        color = '#FF0000';
    }

    return color;
  }

  getRadius(cost: string) {
    const projectcost = parseInt(cost, 10);
    let res;

    if (projectcost < 1000) {  // <1M$
      res = 7;
    } else if (1000 <= projectcost && projectcost < 4000) { // 1-4M$

      res = 10;
    } else if (4000 <= projectcost && projectcost < 20000) { // 4-20M$
      res = 15;
    } else if (projectcost >= 20000) {  // >20M$
      res = 20;
    } else {
      res = 20;
    }

    return res;
  }

  getCost(selectedProject: any) {

    if (selectedProject?.project_step === PROJECT_STEP_ITEM.NEEDS) {
       switch (selectedProject?.costs?.cost_range_at_need_step) {
          case '1M$':
            return '<' + selectedProject?.costs.cost_range_at_need_step;
            break;
          case '1-4M$':
             return selectedProject?.costs.cost_range_at_need_step;
             break;
          case '4-20M$':
            return selectedProject?.costs.cost_range_at_need_step;
            break;
          case '20M$':
             return '>' + selectedProject?.costs.cost_range_at_need_step;
             break;
         default: return 0;
          }

    } else {
          return (selectedProject?.cost ? selectedProject?.cost.toLocaleString('en-US') : 0) + ' k$';

    }
  }

  initForm() {

  }

  filterProjects(filteredProjects) {
    this.initNeedsData(filteredProjects);
    this.initFeasibilityData(filteredProjects);
    this.initBasicData(filteredProjects);
    this.initFeedData(filteredProjects);
    this.initCftData(filteredProjects);
    this.initExeData(filteredProjects);
    this.globaldata(filteredProjects);
  }

  getProjectsByDate(date) {
    this.allProjects = [];
    loaderShow(this._loaderService);
    this._projectService.datavizlist(date).subscribe(
        (projects: []) => {
          this.allProjects = projects;
          this.applyFilters.next(this.allProjects);
          loaderHide(this._loaderService);
        }
    );
  }

  onBubbleSelected(d: any, event = null) {
    this.selectedProject = d.dataProject;
    if (!this.isMobile) {
      // console.log(window.pageXOffset);
      // console.log(event.clientX);
      this.popup.nativeElement.style.visibility = 'visible';
      const leftDisplay = window.pageXOffset + (d.cx > 1000 ? event.clientX - 177 : event.clientX ) + 'px';
      this.popup.nativeElement.style.left = leftDisplay;
      this.popup.nativeElement.style.top = window.pageYOffset + event.clientY + 'px';
    } else {
      setTimeout(() => {
        this.matDialog.open(ProjectModalComponent,
            {
              minWidth: 200,
              closeOnNavigation: true,
              data: [this.selectedProject]
            }
        );
      }, 50); // TIMEOUT IS NEEDED OHTERWISE MODAL RANDOMLY CLOSES IMMEDIATELY ON MOBILE
    }
  }

  HidePopup(event) {
    if (!this.isMobile) {
      if (event.target.localName === 'polygon' || event.target.localName === 'rect' || event.target.localName === 'svg') {
        if (this.popup.nativeElement.style.visibility === 'visible') {
          this.popup.nativeElement.style.visibility = 'hidden';
        }
      }
    }
  }

    showCanceled() {
  /**
   * TODO
   */
      this.allProjects = [];
      loaderShow(this._loaderService);
      this._projectService.datavizlistByStatus('canceled').subscribe(
        (projects: []) => {
          this.showCanceledProjects = !this.showCanceledProjects;
          this.showCompletedProjects = false;
          this.allProjects = projects;
          this.applyFilters.next(this.allProjects);
          loaderHide(this._loaderService);
        }
      );
    }

  showAll() {
    this.allProjects = [];
    loaderShow(this._loaderService);
    this._projectService.datavizlist().subscribe(
      (projects: []) => {
        this.showCanceledProjects = !this.showCanceledProjects;
        this.showCompletedProjects = false;
        this.allProjects = projects;
        this.applyFilters.next(this.allProjects);
        loaderHide(this._loaderService);
      }
    );
  }

  showCompleted() {
   /**
    * TODO
    */

    this.allProjects = [];
    loaderShow(this._loaderService);
    this._projectService.datavizlistByStatus('completed').subscribe(
      (projects: []) => {
        this.showCompletedProjects = !this.showCompletedProjects;
        this.showCanceledProjects = false;
        this.allProjects = projects;
        this.applyFilters.next(this.allProjects);
        loaderHide(this._loaderService);
      }
    );
  }

}
