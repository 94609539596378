<div class='row'>
  <ng-select [items]="filtredzones" placeholder="Zone{{fieldsRequired ? ' *' : ''}}" [multiple]="multiple" [closeOnSelect]="!multiple"
             [formControl]="formControlZones" class="col-6" bindLabel="label" bindValue="id" (change)="onChange($event,'zones')"
             (remove)="onRemove($event,'zones')" (clear)="onClear('zones')" (close)="onClose('zones')" appendTo="body"
             [(ngModel)]="selectedZone" appearance="outline">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" *ngIf="multiple"/>
      {{item.label}}
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let item of items | slice:0:2">
        <span class="ng-value-label">{{item.label}}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </div>
      <div class="ng-value" *ngIf="items.length > 2">
        <span class="ng-value-label">{{items.length - 2}} more...</span>
      </div>
    </ng-template>
    <ng-template ng-header-tmp *ngIf="multiple">
      <button (click)="selectAll('zones')" class="btn btn-sm ">Select all</button>
      <button (click)="unselectAll('zones')" class="btn btn-sm ">Unselect all</button>
    </ng-template>
  </ng-select>
  <ng-select [items]="filtredcountries" [loading]="Countriesloading" placeholder="Country{{fieldsRequired ? ' *' : ''}}"
    [multiple]="multiple" bindLabel="label_en" [closeOnSelect]="!multiple" bindValue="id"
    [formControl]="formControlCountries" class="col-6" appendTo="body" [(ngModel)]="selectedCountry"
    (close)="onClose('countries')" (clear)="onClear('countries')" (remove)="onRemove($event,'countries')"
    *ngIf="fieldsRequired" appearance="outline"
    >
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"
        *ngIf="multiple" />
      {{item.label_en}}
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let item of items | slice:0:2">
        <span class="ng-value-label">{{item.label_en}}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </div>
      <div class="ng-value" *ngIf="items.length > 2">
        <span class="ng-value-label">{{items.length - 2}} more...</span>
      </div>
    </ng-template>
    <ng-template ng-header-tmp *ngIf="multiple">
      <button (click)="selectAll('countries')" class="btn btn-sm ">Select all</button>
      <button (click)="unselectAll('countries')" class="btn btn-sm ">Unselect all</button>
    </ng-template>
  </ng-select>
  <ng-select [items]="filtredoperationals" [loading]="Opeloading" (change)="onChange($event,'operationals')"
    (remove)="onRemove($event,'operationals')" (clear)="onClear('operationals')" (close)="onClose('operationals')"
    placeholder="Operational direction{{fieldsRequired ? ' *' : ''}}" [multiple]="multiple" bindLabel="label_en"
    [closeOnSelect]="!multiple" bindValue="id" [formControl]="formControlOperationals" class="col-6" appendTo="body"
    [(ngModel)]="selectedOpe" *ngIf="!fieldsRequired" appearance="outline">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input class="primary" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
        [ngModelOptions]="{standalone: true}" *ngIf="multiple" />
      {{item.label_en}}
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let item of items | slice:0:2">
        <span class="ng-value-label">{{item.label_en}}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </div>
      <div class="ng-value" *ngIf="items.length > 2">
        <span class="ng-value-label">{{items.length - 2}} more...</span>
      </div>
    </ng-template>
    <ng-template ng-header-tmp *ngIf="multiple">
      <button (click)="selectAll('operationals')" class="btn btn-sm">Select all</button>
      <button (click)="unselectAll('operationals')" class="btn btn-sm">Unselect all</button>
    </ng-template>
  </ng-select>
</div>
<div class='row'>
  <ng-select [items]="filtredoperationals" [loading]="Opeloading" (change)="onChange($event,'operationals')"
    (remove)="onRemove($event,'operationals')" (clear)="onClear('operationals')" (close)="onClose('operationals')"
    placeholder="Operational direction{{fieldsRequired ? ' *' : ''}}" [multiple]="multiple" bindLabel="label_en"
    [closeOnSelect]="!multiple" bindValue="id" [formControl]="formControlOperationals" class="col-6" appendTo="body"
    [(ngModel)]="selectedOpe" *ngIf="fieldsRequired" appearance="outline">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input class="primary" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
        [ngModelOptions]="{standalone: true}" *ngIf="multiple" />
      {{item.label_en}}
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let item of items | slice:0:2">
        <span class="ng-value-label">{{item.label_en}}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </div>
      <div class="ng-value" *ngIf="items.length > 2">
        <span class="ng-value-label">{{items.length - 2}} more...</span>
      </div>
    </ng-template>
    <ng-template ng-header-tmp *ngIf="multiple">
      <button (click)="selectAll('operationals')" class="btn btn-sm">Select all</button>
      <button (click)="unselectAll('operationals')" class="btn btn-sm">Unselect all</button>
    </ng-template>
  </ng-select>
  <ng-select [items]="filtredcountries" [loading]="Countriesloading" placeholder="Country{{fieldsRequired ? ' *' : ''}}"
    [multiple]="multiple" bindLabel="label_en" [closeOnSelect]="!multiple" bindValue="id"
    [formControl]="formControlCountries" class="col-6" appendTo="body" [(ngModel)]="selectedCountry"
    (close)="onClose('countries')" (clear)="onClear('countries')" (remove)="onRemove($event,'countries')"
    *ngIf="!fieldsRequired" appearance="outline"
    >
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"
        *ngIf="multiple" />
      {{item.label_en}}
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let item of items | slice:0:2">
        <span class="ng-value-label">{{item.label_en}}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </div>
      <div class="ng-value" *ngIf="items.length > 2">
        <span class="ng-value-label">{{items.length - 2}} more...</span>
      </div>
    </ng-template>
    <ng-template ng-header-tmp *ngIf="multiple">
      <button (click)="selectAll('countries')" class="btn btn-sm ">Select all</button>
      <button (click)="unselectAll('countries')" class="btn btn-sm ">Unselect all</button>
    </ng-template>
  </ng-select>
  <ng-select [items]="filtredbusinesslines" [loading]="Businessloading" placeholder="Business line{{fieldsRequired ? ' *' : ''}}"
             [multiple]="multiple" bindLabel="label_en" [closeOnSelect]="!multiple" bindValue="id" [formControl]="formControlBusinessLines"
             class="col-6" appendTo="body" [(ngModel)]="selectedBusinessline" appearance="outline">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" *ngIf="multiple"/>
      {{item.label_en}}
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let item of items | slice:0:2">
        <span class="ng-value-label">{{item.label_en}}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </div>
      <div class="ng-value" *ngIf="items.length > 2">
        <span class="ng-value-label">{{items.length - 2}} more...</span>
      </div>
    </ng-template>
    <ng-template ng-header-tmp *ngIf="multiple">
      <button (click)="selectAll('businesslines')" class="btn btn-sm">Select all</button>
      <button (click)="unselectAll('businesslines')" class="btn btn-sm">Unselect all</button>
    </ng-template>
  </ng-select>
</div>
