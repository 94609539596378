import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(private _apiService: ApiService) { }

  getAll<T>(resource: string): Observable<any[] | T> {
    return this._apiService.get(resource);
  }

  createResource<T>(url: string, data: T): Observable<any[] | T> {
     window.sessionStorage.clear();
     return this._apiService.post(url, data);
  }

  getResource<T>(url: string, id: number): Observable<any[] | T> {
    return this._apiService.get(url + '/' + id);
  }

  updateResource<T>(url: string, id: number, data: T): Observable<any[] | T> {
     window.sessionStorage.clear();
     return this._apiService.patch(url + '/' + id, data);
  }

  deleteResource<T>(url: string, id: number): Observable<any[] | T> {
    return this._apiService.delete(url + '/' + id);
  }
}
