import {IMilistones, IOthermilistones, IOthermilistonesExe, Excurve, Milistonestype} from './Milistones';
export interface IMonthly {
  id: number;
  period: Date;
  project_step: string;
  cut_off_date: string;
  progress_schedule_description: string;
  permits_description: string;
  hse: IHse | IHseExe;
  cost: ICost | ICostExe;
  progress_schedules: IProgressschedule | IProgresssExechedule;
  permits_authorisations: Ipermit;
  quality: IQuality | IQualityExe;
  highlights: IHighlight;
  isdraft: boolean;
  isvalid: boolean;
  pictures: [];
  lastValidatedReportDate: Date;
}
export interface IMonthlyExe {
  id: number;
  period: Date;
  project_step: string;
  cut_off_date: string;
  progress_schedule_description: string;
  permits_description: string;
  hse: IHse | IHseExe;
  cost: ICost | ICostExe;
  progress_schedules: IProgressschedule | IProgresssExechedule;
  permits_authorisations: Ipermit;
  quality: IQuality;
  highlights: IHighlight;
  isdraft: boolean;
  isvalid: boolean;
  pictures: [];
  lastValidatedReportDate: Date;
}
export interface IMonthlyversion {
  id: number;
  monthly_progress_id: number;
  project_step: string;
  cut_off_date: string;
}

export interface IHse {
  id: number;
  hazan: string;
  hazid: string;
  hazop: string;
  tra: string;
  ebs: string;
  eia: string;
  description: string;

}
export interface IHseExe {
  fat_this_month: string;
  fat_previous_month: string;
  fat_since_project_start: string;

  pdclti_this_month: string;
  pdclti_previous_month: string;
  pdclti_since_project_start: string;

  rwcmt_this_month: string;
  rwcmt_previous_month: string;
  rwcmt_since_project_start: string;

  fac_this_month: string;
  fac_previous_month: string;
  fac_since_project_start: string;

  hipo_this_month: string;
  hipo_previous_month: string;
  hipo_since_project_start: string;

  nearmisses_this_month: string;
  nearmisses_previous_month: string;
  nearmisses_since_project_start: string;

  anomalies_this_month: string;
  anomalies_previous_month: string;
  anomalies_since_project_start: string;

  nostl_this_month: string;
  nostl_previous_month: string;
  nostl_since_project_start: string;

  totalmanhours_this_month: string;
  totalmanhours_previous_month: string;
  totalmanhours_since_project_start: string;

  man_power_on_site: string;

  dateoflti_this_month: string;
  dateoflti_since_project_start: string;

  description: string;

}

export enum PROJECT_STEP_ITEM {
  NEEDS = 'Needs',
  FEASIBILITY = 'Feasibility',
  BASIC = 'Basic',
  FEED = 'Feed',
  CFT = 'CFT',
  EXE = 'EXE'
}

export enum PROJECT_STEP_GROUP {
  DEV = 'DEV',
  EXE = 'EXE'
}

export enum Accuracy {
  NOTIONALCOST = 'Notional cost',
  FEASIBILITY = '+/- 50% (Feasibility)',
  BASICS = '+/- 30% (Basics)',
  FEED = '+/- 15% (Feed)',
  CFT = '+/- 10% (CFT)'
}

export interface IProgressschedule {
  milistones?: IMilistones[];
  othermilistones?: IOthermilistones[];
  progress_schedule_description?: string;
}
export interface IProgresssExechedule {
  initials?: Excurve[];
  forecasts?: Excurve[];
  actuals?: Excurve[];
  gate_feasibility?: Date;
  pr1?: Date;
  gate_basic?: Date;
  gate_feed?: Date;
  gate_cft?: Date;
  pr2?: Date;
  fid?: Date;
  effective_date_at_fid?: Date;
  effective_date_as_per_contracts?: Date;
  effective_date_forecast?: Date;
  effective_date_actual?: Date;
  start_construction_at_fid?: Date;
  start_construction_as_per_contracts?: Date;
  start_construction_forecast?: Date;
  start_construction_actual?: Date;
  mechanical_completition_at_fid?: Date;
  mechanical_completition_as_per_contracts?: Date;
  mechanical_completition_forecast?: Date;
  mechanical_completition_actual?: Date;
  rfsu_at_fid?: Date;
  rfsu_as_per_contracts?: Date;
  rfsu_forecast?: Date;
  rfsu_actual?: Date;
  closure_rex_forecast?: Date;
  closure_rex_actual?: Date;
  othermilistones?: IOthermilistonesExe[];
  progress_schedule_description?: string;
  milistones?: IMilistones[];
}
export interface Ipermit {
  permits: IPermits[];
  permits_description: string;
}
export interface IPermits {

  id: number;
  monthly_progress_id: number;
  name: string;
  file_inssurance_forcast: string;
  file_inssurance_actual: string;
  aproval_actual: string;

}

export interface IQuality {
  issued: string;
  accepted: string;
  rejected: string;
  pending: string;
  description: string;

}
export interface IQualityExe {
  issued: string;
  accepted: string;
  rejected: string;
  pending: string;
  ncr_raised?: number;
  ncr_rejected?: number;
  description: string;

}
export interface IHighlight {

  id: number;
  monthly_progress_id: number;
  main_events: string;
  activity_summary: string;
  area_of_concern: string;
  month_look_ahead: string;
  next_steering_commitee: string;
  next_milistone_type: Milistonestype;
  next_milistone_date: Date;

}

export interface ICost {
  dsc_approved: number;
  dsc_estimated: number;
  dsc_performed: number;
  lpac_approved: number;
  lpac_estimated: number;
  lpac_performed: number;
  total_dev_cost_approved: number;
  total_dev_cost_estimated: number;
  total_dev_cost_performed: number;
  total_project_capex_estimated_amount: number;
  total_project_capex_estimated_accuracy: number;
  cost_range_at_need_step: number;
  description: string;
}

export interface ICostExe {
  total_dev_cost_performed: number;
  total_dev_cost_prefid: number;

  efc_this_month: number;
  efc_previous_month: number;
  efc_current_month: number;

  commited_this_month: number;
  commited_previous_month: number;
  commited_current_month: number;

  performed_this_month: number;
  performed_previous_month: number;
  performed_current_month: number;

  total_amount_requested: number;
  total_amount_aproved: number;
  description: string;
}

export enum PROJECT_STEP_COLOR {
  NEEDS = '#FEDB00',
  FEASIBILITY = '#55975B',
  BASIC = '#4B96CD',
  FEED = '#4B96CD',
  CFT = '#F59600',
  EXE = '#707173'
}
