<div class="d-flex flex-wrap">
    <div class="align-self-baseline  mr-auto ">
        <div class="d-flex flex-wrap">
            <div class="hstyle align-self-baseline">Projects funnel |</div>
            <div class="hstylecontent align-self-center"> {{Nbrglobalproject}} ACTIVE PROJECTS - TOTAL :
                {{Costglobalproject / 1000 | number:'1.0-2'}}M$
            </div>
        </div>

    </div>
    <div class="align-self-baseline mobile" *ngIf="false">
        <button (click)="generatePDF()" style="margin-right: 1vw;" mat-raised-button>
            <mat-icon>arrow_right</mat-icon>
            <span>{{ 'export_pdf' | translate }}</span>
        </button>
    </div>
</div>
<div class="space-before-filter"></div>
<app-project-filter [projects]="allProjects" (projectsChange)="filterProjects($event)"
    (periodFilterChange)="getProjectsByDate($event)" [applyFilterAlert]="applyFilters" *ngIf="allProjects">
</app-project-filter>
<div id="funnel-export">
    <mat-card style="margin-top: 30px">

        <mat-card-content id="funnel-svg">
            <svg width="100%" height="100%" viewBox="0 0 1139 40" xmlns="http://www.w3.org/2000/svg"
                xmlns:svg="http://www.w3.org/2000/svg">
                <g id="svg_Banner_needs" class="layer">
                    <svg id="svg_needs_1" viewBox="0 0 150 40" height="40px" width="150px"
                        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                        <g fill-rule="evenodd" fill="none" id="svg_needs_2">
                            <g fill="#FEDB00" transform="translate(-151.000000, -344.000000)" id="svg_2">
                                <path id="svg_needs_3" d="m276,384l-125,0l0,-40l125,0l25,20l-25,20l0,0z" />
                            </g>
                        </g>
                    </svg>
                </g>
                <g id="svg_Banner_feasibility" class="layer">
                    <svg x="130" id="svg_feasibility_1" viewBox="0 0 150 40" height="40px" width="150px"
                        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                        <g fill-rule="evenodd" fill="none" id="svg_feasibility_2">
                            <g id="Home" transform="translate(-284.000000, -344.000000)" fill="#55975B">
                                <path
                                    d="M409,344 L409,344.001 L435,364 L409,383.998 L409,384 L284,384 L284,384 L309,364 L284,344 L409,344 Z"
                                    id="Combined-Shape-Copy"></path>
                            </g>
                        </g>
                    </svg>
                </g>
                <g id="svg_Banner_basic" class="layer">
                    <svg x="260" id="svg_basic_1" viewBox="0 0 150 40" height="40px" width="150px"
                        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                        <g fill-rule="evenodd" fill="none" id="svg_basic_2">
                            <g id="basicHome" transform="translate(-550.000000, -344.000000)" fill="#4B96CD">
                                <path
                                    d="M675,344 L675,344.001 L701,364 L675,383.998 L675,384 L550,384 L550,384 L575,364 L550,344 L675,344 Z"
                                    id="basicCombined-Shape-Copy-6"></path>
                            </g>
                        </g>
                    </svg>
                </g>
                <g id="svg_feed_basic" class="layer">
                    <svg x="390" id="svg_feed_1" viewBox="0 0 150 40" height="40px" width="150px"
                        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                        <g fill-rule="evenodd" fill="none" id="svg_feed_2">
                            <g id="feedHome" transform="translate(-550.000000, -344.000000)" fill="#4B96CD">
                                <path
                                    d="M675,344 L675,344.001 L701,364 L675,383.998 L675,384 L550,384 L550,384 L575,364 L550,344 L675,344 Z"
                                    id="feedCombined-Shape-Copy-6"></path>
                            </g>
                        </g>
                    </svg>
                </g>
                <g id="svg_cft_basic" class="layer">
                    <svg x="520" id="svg_cft_1" viewBox="0 0 156 40" height="40px" width="156px"
                        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                        <g fill-rule="evenodd" fill="none" id="svg_cft_2">
                            <g id="cftHome" transform="translate(-683.000000, -344.000000)" fill="#F59600">
                                <path
                                    d="M813,344 L839,364 L813,384 L813,384 L683,384 L683,384 L709,364 L683,344 L813,344 Z"
                                    id="cftCombined-Shape-Copy-3"></path>
                            </g>
                        </g>
                    </svg>
                </g>
                <g id="svg_exe_basic" class="layer">
                    <svg x="656" id="svg_exe_1" viewBox="0 0 474 40" height="40px" width="474px"
                        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                        <g fill-rule="evenodd" fill="none" id="svg_exe_2">
                            <g id="exeHome" transform="translate(-821.000000, -344.000000)" fill="#707173">
                                <path
                                    d="M1269,344 L1295,364 L1269,384 L1269,384 L821,384 L821,384 L847,364 L821,344 L1269,344 Z"
                                    id="exeCombined-Shape-Copy-4"></path>
                            </g>
                        </g>
                    </svg>
                </g>
                <g class="layer">
                    <title>Text_Needs</title>
                    <text transform="matrix(1,0,0,1,0,0) " fill="#ffffff" stroke="#ffffff" stroke-width="0"
                        stroke-dasharray="null" stroke-linejoin="null" stroke-linecap="null" x="53" y="24.5" id="svg_49"
                        class="font-breadcump" text-anchor="middle" xml:space="preserve"
                        font-weight="normal">NEEDS</text>
                    <text fill="#ffffff" stroke="#ffffff" stroke-width="0" stroke-dasharray="null"
                        stroke-linejoin="null" stroke-linecap="null" x="207" y="24.5" id="svg_50" class="font-breadcump"
                        text-anchor="middle" xml:space="preserve" font-weight="normal">FEASIBILITY</text>
                    <text transform="matrix(1,0,0,1,0,0) " fill="#ffffff" stroke="#ffffff" stroke-width="0"
                        stroke-dasharray="null" stroke-linejoin="null" stroke-linecap="null" x="334" y="24.5"
                        id="svg_51" class="font-breadcump" text-anchor="middle" xml:space="preserve"
                        font-weight="normal">BASIC</text>
                    <text transform="matrix(1,0,0,1,0,0) " fill="#ffffff" stroke="#ffffff" stroke-width="0"
                        stroke-dasharray="null" stroke-linejoin="null" stroke-linecap="null" x="459" y="24.5"
                        id="svg_52" class="font-breadcump" text-anchor="middle" xml:space="preserve"
                        font-weight="normal">FEED</text>
                    <text transform="matrix(1,0,0,1,0,0) " fill="#ffffff" stroke="#ffffff" stroke-width="0"
                        stroke-dasharray="null" stroke-linejoin="null" stroke-linecap="null" x="598" y="24.5"
                        id="svg_53" class="font-breadcump" text-anchor="middle" xml:space="preserve"
                        font-weight="normal">CFT</text>
                    <text fill="#ffffff" stroke="#ffffff" stroke-width="0" stroke-dasharray="null"
                        stroke-linejoin="null" stroke-linecap="null" x="883" y="24.5" id="svg_54" class="font-breadcump"
                        text-anchor="middle" xml:space="preserve" font-weight="normal">EXECUTION</text>
                </g>
            </svg>
            <svg (click)="HidePopup($event)" width="100%" height="100%" viewBox="0 0 1139 600" version="1.1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g class="node" transform="translate(67,5)">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="156.000000pt" height="40.000000pt"
                        viewBox="0 0 156.000000 40.000000" preserveAspectRatio="xMidYMid meet">

                        <g transform="translate(0.000000,40.000000) scale(0.100000,-0.100000)" fill="#000000"
                            stroke="none">
                        </g>
                    </svg>
                </g>
                <g class="node" transform="translate(67,40)">
                    <text dy=".2em" class="funnel-header-title"
                        style="text-anchor: middle; fill: #535353">{{need_totalprojects}}
                        Projects
                    </text>
                    <text dy="1.3em" class="funnel-header-title"
                        style="text-anchor: middle; fill: #ED0000">{{need_total_cost / 1000 | number:'1.0-2'}}M$
                    </text>
                </g>
                <g class="node" transform="translate(201,40)">
                    <text dy=".2em" class="funnel-header-title"
                        style="text-anchor: middle; fill: #535353">{{feasibilty_totalprojects}}
                        Projects
                    </text>
                    <text dy="1.3em" class="funnel-header-title" style="text-anchor: middle; fill:
                     #ED0000">{{feasibilty_total_cost / 1000 | number:'1.0-2'}}M$
                    </text>
                </g>
                <g class="node" transform="translate(335,40)">
                    <text dy=".2em" class="funnel-header-title"
                        style="text-anchor: middle; fill: #535353">{{total_cost_basic_projects}}
                        Projects
                    </text>
                    <text dy="1.3em" class="funnel-header-title"
                        style="text-anchor: middle; fill: #ED0000">{{total_cost_basic / 1000 | number:'1.0-2'}}M$
                    </text>
                </g>
                <g class="node" transform="translate(469,40)">
                    <text dy=".2em" class="funnel-header-title"
                        style="text-anchor: middle; fill: #535353">{{total_cost_feed_projects}}
                        Projects
                    </text>
                    <text dy="1.3em" class="funnel-header-title"
                        style="text-anchor: middle; fill: #ED0000">{{total_cost_feed / 1000 | number:'1.0-2'}}M$
                    </text>
                </g>
                <g class="node" transform="translate(603,40)">
                    <text dy=".2em" class="funnel-header-title"
                        style="text-anchor: middle; fill: #535353">{{total_cost_cft_projects}}
                        Projects
                    </text>
                    <text dy="1.3em" class="funnel-header-title"
                        style="text-anchor: middle; fill: #ED0000">{{total_cost_cft / 1000 | number:'1.0-2'}}M$
                    </text>
                </g>
                <g class="node" transform="translate(907,40)">
                    <text dy=".2em" class="funnel-header-title"
                        style="text-anchor: middle; fill: #535353">{{total_cost_exe_projects}}
                        Projects
                    </text>

                    <text dy="1.3em" class="funnel-header-title"
                        style="text-anchor: middle; fill: #ED0000">{{total_cost_exe / 1000 | number:'1.0-2'}}M$
                    </text>
                </g>
                <g transform="translate(938,40)">

                    <path fill="#ff0000" id="svg_376"
                        d="m938,43.5l22,0l-11,-19l-11,19zm12,-3l-2,0l0,-2l2,0l0,2zm0,-4l-2,0l0,-4l2,0l0,4z" />
                </g>
                <g id="UX-V4'" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Home" transform="translate(-151.000000, -393.000000)">
                        <g id="Group-6" transform="translate(151.000000, 450.000000)">


                            <rect id="Rectangle" fill="#F4F4F4" x="665" y="138" width="474" height="265"></rect>
                            <!-- <g (click)="test()" class="node" transform="translate(700,200)"
                                style="fill: rgb(31, 119, 180);">

                                <circle x="82.64626869013529" y="200.69887869106407" r="30.830573699333472"
                                    style="fill: #FFCFC2">
                                </circle><text dy=".2em" font-family="Roboto Regular" font-size="10" fill="black"
                                    style="text-anchor: middle;">Project</text><text dy="1.3em" font-family="Gill Sans"
                                    font-size="10" style="text-anchor: middle;">12%</text>
                            </g>
                            <g (click)="test()" class="node" transform="translate(800,250)"
                                style="fill: rgb(31, 119, 180);">

                                <circle x="82.64626869013529" y="200.69887869106407" r="30.830573699333472"
                                    style="fill: #FFCFC2">
                                </circle><text dy=".2em" font-family="Roboto Regular" font-size="10"
                                    style="text-anchor: middle; fill: #535353">Project</text><text dy="1.3em"
                                    font-family="Gill Sans" font-size="10"
                                    style="text-anchor: middle; fill: #535353">18%</text>
                            </g>
                            <g (click)="test()" class="node" transform="translate(750,220)" style="fill: #EA504E">

                                <circle x="82.64626869013529" y="200.69887869106407" r="20" style="fill: #EA504E">
                                </circle><text dy=".2em" font-family="Roboto Regular" font-size="10" fill="black"
                                    style="text-anchor: middle; fill: #535353">Project</text><text dy="1.3em"
                                    font-family="Gill Sans" font-size="10" fill="black"
                                    style="text-anchor: middle; fill: #535353">16%</text>
                            </g>
                            <g (click)="test()" class="node" transform="translate(850,300)" style="fill: #EA504E">

                                <circle x="82.64626869013529" y="200.69887869106407" r="30" style="fill: #EA504E">
                                </circle><text dy=".2em" font-family="Roboto Regular" font-size="10" fill="black"
                                    style="text-anchor: middle;">Project</text><text dy="1.3em" font-family="Gill Sans"
                                    font-size="10" fill="black" style="text-anchor: middle;">20%</text>
                            </g> -->
                            <polygon id="Rectangle-1" fill="#F4F4F4" points="0 41 666 138 665 403 0 499" />
                            <polygon id="Rectangle-2" fill="#E3E2E2"
                                points="0 41 133 60.5449219 133 479.504883 0 499" />
                            <!-- <g (click)="test()" class="node" transform="translate(82.64626869013529,100.69887869106407)"
                                style="fill: rgb(31, 119, 180);">
                                <circle x="82.64626869013529" y="200.69887869106407" r="30.830573699333472"
                                    style="fill: #FFCFC2">
                                </circle><text dy=".2em" font-family="Roboto Regular" font-size="10" fill="black"
                                    style="text-anchor: middle;">Project</text><text dy="1.3em" font-family="Gill Sans"
                                    font-size="10" fill="black" style="text-anchor: middle;">1234</text>
                            </g> -->
                            <g class="node" transform="translate(664,445)">
                                <circle x="664" y="430" r="5" style="fill: #ED0000"></circle>


                            </g>
                            <g class="node" transform="translate(664,460)">
                                <text dy=".2em" font-family="Roboto Regular" font-size="10"
                                    style="fill:#ED0000;text-anchor: middle;">FID
                                </text>
                            </g>

                            <!-- <g transform="translate(0,50)">
                                <svg width="150" height="700">
                                    <circle style="fill: rgb(255, 127, 14);" cx="100" cy="470.76678973874243" r="50">
                                    </circle>
                                    <circle style="fill: rgb(44, 160, 44);" cx="50.98123056986115" cy="692.7056963169225"
                                        r="9.5"></circle>
                                    <circle style="fill: rgb(214, 39, 40);" cx="82.03179784237524" cy="678.8926017869793"
                                        r="9.5"></circle>
                                    <circle style="fill: rgb(148, 103, 189);" cx="100" cy="576.841999737099" r="14">
                                    </circle>
                                    <circle style="fill: rgb(140, 86, 75);" cx="50" cy="645.2744448735863" r="23"></circle>
                                    <circle style="fill: rgb(227, 119, 194);" cx="50" cy="596.0016046378478" r="10.85">
                                    </circle>
                                    <circle style="fill: rgb(127, 127, 127);" cx="50" cy="525.8677131562209" r="10.4">
                                    </circle>
                                    <circle style="fill: rgb(188, 189, 34);" cx="50" cy="340.28912046167216" r="20.75">
                                    </circle>
                                    <circle style="fill: rgb(255, 127, 14);" cx="100" cy="750" r="50"></circle>
                                    <circle style="fill: rgb(44, 160, 44);" cx="96.87017569965033" cy="397.5969565972381"
                                        r="9.5"></circle>
                                    <circle style="fill: rgb(214, 39, 40);" cx="50" cy="560.8052652884154" r="9.5">
                                    </circle>
                                    <circle style="fill: rgb(148, 103, 189);" cx="100" cy="645.5932919259245" r="14">
                                    </circle>
                                    <circle style="fill: rgb(140, 86, 75);" cx="50" cy="398.9819944670313" r="23"></circle>
                                    <circle style="fill: rgb(227, 119, 194);" cx="100" cy="363.2276444799404" r="10.85">
                                    </circle>
                                    <circle style="fill: rgb(127, 127, 127);" cx="82.53525859177535" cy="610.804522112367"
                                        r="10.4"></circle>
                                    <circle style="fill: rgb(188, 189, 34);" cx="100" cy="317.53286810659927" r="20.75">
                                    </circle>
                                    <circle style="fill: rgb(44, 160, 44);" cx="50" cy="295.0439006220701" r="9.5">
                                    </circle>
                                    <circle style="fill: rgb(214, 39, 40);" cx="79.71682615260086" cy="278.82074798871145"
                                        r="9.5"></circle>
                                    <circle style="fill: rgb(148, 103, 189);" cx="50" cy="256.46618368337784" r="14">
                                    </circle>
                                    <circle style="fill: rgb(140, 86, 75);" cx="100" cy="236.52470038842418" r="23">
                                    </circle>
                                    <circle style="fill: rgb(227, 119, 194);" cx="80.26549978002986" cy="542.9955641087088"
                                        r="10.85"></circle>
                                    <circle style="fill: rgb(127, 127, 127);" cx="84.08294584177813"
                                        cy="191.31239810433692" r="10.4"></circle>
                                    <circle style="fill: rgb(188, 189, 34);" cx="100" cy="148.24912515457507" r="20.75">
                                    </circle>
                                    <circle style="fill: rgb(44, 160, 44);" cx="57.05485405271786" cy="219.3593073881332"
                                        r="9.5"></circle>
                                    <circle style="fill: rgb(214, 39, 40);" cx="50" cy="186.63356684651635" r="9.5">
                                    </circle>
                                    <circle style="fill: rgb(148, 103, 189);" cx="50" cy="148.71302892969314" r="14">
                                    </circle>
                                    <circle style="fill: rgb(140, 86, 75);" cx="100" cy="50" r="23"></circle>
                                    <circle style="fill: rgb(227, 119, 194);" cx="100" cy="97.66817901811342" r="10.85">
                                    </circle>
                                    <circle style="fill: rgb(127, 127, 127);" cx="69.18097302189668"
                                        cy="114.68718880028734" r="10.4"></circle>
                                    <circle style="fill: rgb(188, 189, 34);" cx="50" cy="73.67220449335261" r="20.75">
                                    </circle>
                                </svg>
                            </g> -->
                            <!-- <g>
                                <svg x="0">
                                    <circle cx="25" cy="40" r="25" style="fill: #EA504E;" />
                                </svg>

                            </g>
                            <g>
                                <svg x="50">
                                    <circle cx="25" cy="150" r="15" style="fill: #EA504E;" />
                                </svg>

                            </g> -->

                            <!--
                            <circle cx="38" cy="300" r="25" style="fill: #FF3C3C;" />

                            <circle cx="90" cy="250" r="15" style="fill: #EA504E;" />
                            <circle cx="80" cy="280" r="10" style="fill: #EA504E;" />
                            <circle cx="100" cy="400" r="25" style="fill: #EA504E;" />
                            <circle cx="66" cy="190" r="10" style="fill: #EA504E;" /> -->


                            <polygon id="Rectangle-3" fill="#E3E2E2"
                                points="265 80 398 99.1330853 398 441.967595 265 461">
                            </polygon>
                            <polygon id="Rectangle-4" fill="#E3E2E2"
                                points="532 118 665 137.39219 665 402.465062 532 422">
                            </polygon>
                            <line x1="133" y1="0" x2="133" y2="479.206117" id="Line-Copy" stroke="#707173"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>
                            <line x1="266" y1="0" x2="266" y2="460.530839" id="Line-Copy-2" stroke="#707173"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>
                            <line x1="398" y1="0" x2="398" y2="440" id="Line-Copy-3" stroke="#707173" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8"></line>
                            <line x1="531" y1="0" x2="531" y2="421.253613" id="Line-Copy-4" stroke="#707173"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>

                            <line x1="664" y1="0" x2="664" y2="440" id="Line-Copy-5" stroke="#707173" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8"></line>


                            <line x1="703" y1="405" x2="703" y2="421.253613" id="10" stroke="#707173" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>
                            <line x1="747" y1="405" x2="747" y2="421.253613" id="20" stroke="#707173" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>
                            <line x1="789" y1="405" x2="789" y2="421.253613" id="30" stroke="#707173" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>
                            <line x1="831" y1="405" x2="831" y2="421.253613" id="40" stroke="#707173" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>
                            <line x1="873" y1="405" x2="873" y2="421.253613" id="50" stroke="#707173" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>
                            <line x1="915" y1="405" x2="915" y2="421.253613" id="60" stroke="#707173" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>
                            <line x1="959" y1="405" x2="959" y2="421.253613" id="70" stroke="#707173" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>
                            <line x1="1003" y1="405" x2="1003" y2="421.253613" id="80" stroke="#707173" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>
                            <line x1="1045" y1="405" x2="1045" y2="421.253613" id="90" stroke="#707173" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>
                            <line x1="1086" y1="405" x2="1086" y2="421.253613" id="99" stroke="#707173" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>
                            <line x1="1130" y1="405" x2="1130" y2="421.253613" id="99" stroke="#707173" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2,8">
                            </line>

                        </g>

                    </g>
                    <text fill="#000000" stroke="#000000" stroke-width="0" stroke-dasharray="null"
                        stroke-linejoin="null" stroke-linecap="null" x="703" y="486.61417" id="svg_24" font-size="9"
                        font-family="Roboto Regular" text-anchor="middle" xml:space="preserve">10%</text>
                    <text fill="#000000" stroke="#000000" stroke-width="0" stroke-dasharray="null"
                        stroke-linejoin="null" stroke-linecap="null" x="747" y="486.61417" id="svg_24" font-size="9"
                        font-family="Roboto Regular" text-anchor="middle" xml:space="preserve">20%</text>
                    <text fill="#000000" stroke="#000000" stroke-width="0" stroke-dasharray="null"
                        stroke-linejoin="null" stroke-linecap="null" x="789" y="486.61417" id="svg_24" font-size="9"
                        font-family="Roboto Regular" text-anchor="middle" xml:space="preserve">30%</text>
                    <text fill="#000000" stroke="#000000" stroke-width="0" stroke-dasharray="null"
                        stroke-linejoin="null" stroke-linecap="null" x="831" y="486.61417" id="svg_24" font-size="9"
                        font-family="Roboto Regular" text-anchor="middle" xml:space="preserve">40%</text>
                    <text fill="#000000" stroke="#000000" stroke-width="0" stroke-dasharray="null"
                        stroke-linejoin="null" stroke-linecap="null" x="873" y="486.61417" id="svg_24" font-size="9"
                        font-family="Roboto Regular" text-anchor="middle" xml:space="preserve">50%</text>
                    <text fill="#000000" stroke="#000000" stroke-width="0" stroke-dasharray="null"
                        stroke-linejoin="null" stroke-linecap="null" x="915" y="486.61417" id="svg_24" font-size="9"
                        font-family="Roboto Regular" text-anchor="middle" xml:space="preserve">60%</text>
                    <text fill="#000000" stroke="#000000" stroke-width="0" stroke-dasharray="null"
                        stroke-linejoin="null" stroke-linecap="null" x="959" y="486.61417" id="svg_24" font-size="9"
                        font-family="Roboto Regular" text-anchor="middle" xml:space="preserve">70%</text>
                    <text fill="#000000" stroke="#000000" stroke-width="0" stroke-dasharray="null"
                        stroke-linejoin="null" stroke-linecap="null" x="1003" y="486.61417" id="svg_24" font-size="9"
                        font-family="Roboto Regular" text-anchor="middle" xml:space="preserve">80%</text>
                    <text fill="#000000" stroke="#000000" stroke-width="0" stroke-dasharray="null"
                        stroke-linejoin="null" stroke-linecap="null" x="1045" y="486.61417" id="svg_24" font-size="9"
                        font-family="Roboto Regular" text-anchor="middle" xml:space="preserve">90%</text>
                    <text fill="#000000" stroke="#000000" stroke-width="0" stroke-dasharray="null"
                        stroke-linejoin="null" stroke-linecap="null" x="1086" y="486.61417" id="svg_24" font-size="9"
                        font-family="Roboto Regular" text-anchor="middle" xml:space="preserve">99%</text>
                    <text fill="#000000" stroke="#000000" stroke-width="0" stroke-dasharray="null"
                        stroke-linejoin="null" stroke-linecap="null" x="1130" y="486.61417" id="svg_24" font-size="9"
                        font-family="Roboto Regular" text-anchor="middle" xml:space="preserve">100%</text>

                </g>
                <g class="layer">
                    <title>NEEDS</title>
                    <g *ngFor="let d of data" class="hover-buble" (click)="onBubbleSelected(d, $event)">

                        <circle [attr.cx]="d.cx" [attr.cy]="d.cy" [attr.fill]="d.color" stroke="#000000"
                            stroke-width="0" [attr.r]="d.r" id="svg_29" />
                        <text class="title-size" [attr.x]="d.tx" [attr.y]="d.ty" xml:space="preserve"
                            text-anchor="middle" font-family="serif" font-size="9" stroke-linecap="null"
                            stroke-linejoin="null" stroke-dasharray="null" stroke-width="0" stroke="#000000"
                            fill="#000000">{{d.title}}</text>

                    </g>

                </g>
                <g class="layer">
                    <title>FEASIBILITY</title>
                    <g *ngFor="let d_one of feasibility_data_1" class="hover-buble"
                        (click)="onBubbleSelected(d_one, $event)">

                        <circle [attr.cx]="d_one.cx" [attr.cy]="d_one.cy" [attr.fill]="d_one.color" stroke="#000000"
                            stroke-width="0" [attr.r]="d_one.r" id="svg_29" />
                        <text class="title-size" [attr.x]="d_one.tx" [attr.y]="d_one.ty" xml:space="preserve"
                            text-anchor="middle" font-family="serif" font-size="9" stroke-linecap="null"
                            stroke-linejoin="null" stroke-dasharray="null" stroke-width="0" stroke="#000000"
                            fill="#000000">{{d_one.title}}</text>

                    </g>
                    <g *ngFor="let d_two of feasibility_data_2" class="hover-buble"
                        (click)="onBubbleSelected(d_two, $event)">

                        <circle [attr.cx]="d_two.cx" [attr.cy]="d_two.cy" [attr.fill]="d_two.color" stroke="#000000"
                            stroke-width="0" [attr.r]="d_two.r" id="svg_29" />
                        <text class="title-size" [attr.x]="d_two.tx" [attr.y]="d_two.ty" xml:space="preserve"
                            text-anchor="middle" font-family="serif" font-size="9" stroke-linecap="null"
                            stroke-linejoin="null" stroke-dasharray="null" stroke-width="0" stroke="#000000"
                            fill="#000000">{{d_two.title}}</text>

                    </g>

                </g>
                <g display="inline" class="layer">
                    <title>BASIC</title>
                    <g *ngFor="let basic of basicData" class="hover-buble" (click)="onBubbleSelected(basic, $event)">

                        <circle [attr.cx]="basic.cx" [attr.cy]="basic.cy" [attr.fill]="basic.color" stroke="#000000"
                            stroke-width="0" [attr.r]="basic.r" id="svg_29" />
                        <text class="title-size" [attr.x]="basic.tx" [attr.y]="basic.ty" xml:space="preserve"
                            text-anchor="middle" font-family="serif" font-size="9" stroke-linecap="null"
                            stroke-linejoin="null" stroke-dasharray="null" stroke-width="0" stroke="#000000"
                            fill="#000000">{{basic.title}}</text>

                    </g>

                </g>
                <g class="layer" display="inline">
                    <title>FEED</title>
                    <g *ngFor="let feed of feedData" class="hover-buble" (click)="onBubbleSelected(feed, $event)">

                        <circle [attr.cx]="feed.cx" [attr.cy]="feed.cy" [attr.fill]="feed.color" stroke="#000000"
                            stroke-width="0" [attr.r]="feed.r" id="svg_29" />
                        <text class="title-size" [attr.x]="feed.tx" [attr.y]="feed.ty" xml:space="preserve"
                            text-anchor="middle" font-family="serif" font-size="9" stroke-linecap="null"
                            stroke-linejoin="null" stroke-dasharray="null" stroke-width="0" stroke="#000000"
                            fill="#000000">{{feed.title}}</text>

                    </g>

                </g>
                <g class="layer" display="inline">
                    <title>CFT</title>
                    <g *ngFor="let cft of cftData" class="hover-buble" (click)="onBubbleSelected(cft, $event)">

                        <circle [attr.cx]="cft.cx" [attr.cy]="cft.cy" [attr.fill]="cft.color" stroke="#000000"
                            stroke-width="0" [attr.r]="cft.r" id="svg_29" />
                        <text class="title-size" [attr.x]="cft.tx" [attr.y]="cft.ty" xml:space="preserve"
                            text-anchor="middle" font-family="serif" font-size="9" stroke-linecap="null"
                            stroke-linejoin="null" stroke-dasharray="null" stroke-width="0" stroke="#000000"
                            fill="#000000">{{cft.title}}</text>

                    </g>

                </g>


                <g class="layer">
                    <title>EXE</title>
                    <g *ngFor="let exe of exeData" class="hover-buble" (click)="onBubbleSelected(exe, $event)">

                        <circle [attr.cx]="exe.cx" [attr.cy]="exe.cy" [attr.fill]="exe.color" stroke="#000000"
                            stroke-width="0" [attr.r]="exe.r" id="svg_29" />
                        <text class="title-size" [attr.x]="exe.tx" [attr.y]="exe.ty" xml:space="preserve"
                            text-anchor="middle" font-family="serif" font-size="9" stroke-linecap="null"
                            stroke-linejoin="null" stroke-dasharray="null" stroke-width="0" stroke="#000000"
                            fill="#000000">{{exe.title}}</text>

                    </g>

                </g>
                <path *ngIf="displayWarning" matTooltipClass="allow-cr" matTooltip="The number of projects for this step exceeds the funnel capacity and several projects
                    cannot display. Please use the filter function to reduce the target perimeter"
                    matTooltipPosition="right"
                    d="m939,41.5l22,0l-11,-19l-11,19zm12,-3l-2,0l0,-2l2,0l0,2zm0,-4l-2,0l0,-4l2,0l0,4z" id="svg_375"
                    fill="#ff0000" />
            </svg>


        </mat-card-content>
    </mat-card>
    <mat-card class="filters">

        <mat-card-content>
            <div class="row-flex width-100 bg-white legend-container flex-wrap">
                <div class="legend-title">{{ "legend" | translate }} :</div>
                <div class="col-flex" style="margin-right: 50px;">
                    <div class="row-flex align-items-end flex-wrap">
                        <div class="col-flex" style="margin-right: 20px;">
                            <div class="size-1 legend-bubble"></div>
                            <div class="legend-text">
                                <1M$</div> </div> <div class="col-flex" style="margin-right: 20px;">
                                    <div class="size-2 legend-bubble"></div>
                                    <div class="legend-text">1-4M$</div>
                            </div>
                            <div class="col-flex" style="margin-right: 20px;">
                                <div class="size-3 legend-bubble"></div>
                                <div class="legend-text">4-20M$</div>
                            </div>
                            <div class="col-flex">
                                <div class="size-4 legend-bubble"></div>
                                <div class="legend-text">>20M$</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-flex" style="padding-top: 20px;">
                        <div class="row-flex align-items-end flex-wrap">
                            <div class="col-flex" style="margin-right: 20px;">
                                <div class="size-2 zone afr"></div>
                                <div class="legend-text">AFR</div>
                            </div>
                            <div class="col-flex" style="margin-right: 20px;">
                                <div class="size-2 zone apmo"></div>
                                <div class="legend-text">APMO</div>
                            </div>
                            <div class="col-flex" style="margin-right: 20px;">
                                <div class="size-2 zone ame"></div>
                                <div class="legend-text">AME</div>
                            </div>
                            <div class="col-flex">
                                <div class="size-2 zone eur"></div>
                                <div class="legend-text">EUR</div>
                            </div>
                        </div>
                    </div>
                </div>
        </mat-card-content>
    </mat-card>
</div>
<!-- <div style="padding-bottom: 30px;" class="d-flex flex-row-reverse flex-wrap ">
    <button mat-stroked-button style="margin-left: 1em;"  (click)="!showCanceledProjects ? showCanceled() : showAll()">
        <mat-icon>arrow_right</mat-icon>
        <span>See {{showCanceledProjects ? 'active' : 'canceled'}} projects</span>
    </button>

    <button mat-stroked-button [ngStyle]="{'margin-top':issmallMobile ? '1em' : 'unset' }"     (click)="!showCompletedProjects ? showCompleted() : showAll() ">
        <mat-icon>arrow_right</mat-icon>
        <span>See {{showCompletedProjects ? 'active' : 'completed'}} projects</span>
    </button>

</div> -->
<div #popup class="popup">
    <div class="info-container">
        <div class="info-block">
            <div class="info-data project-title align-items-center">
                <label class="label-title">{{selectedProject?.title | uppercase}}</label>
                <app-project-bookmark style="margin-left: 5px;" [fontSize]="22" [projectId]="selectedProject?.id"></app-project-bookmark>
            </div>
            <div class="info-data"><label>{{ 'country' | translate }} : </label>{{selectedProject?.country.label_en}}
            </div>
            <div class="info-data">
                <label>{{ 'project.introduction.general_data.business_line' | translate }} :</label>
                {{selectedProject?.businessLine?.label_en}}
            </div>
            <div class="info-data">
                <label>{{ 'step' | translate }} : </label>{{selectedProject?.project_step}}
            </div>
            <div class="info-data">
                <label *ngIf="selectedProject?.phase === 'EXE'" matTooltipClass="allow-cr" matTooltip="Total project CAPEX performance for EXE phase only :
  - Green smiley : EFC ≤ Approved FID
  - Orange smiley : Approved FID < EFC ≤ Approved at FID +10%
  - Red smiley : EFC > Approved FID +10%
EFC : Estimated Final Cost
FID : Final Investment Decision" matTooltipPosition="right">{{ 'cost' | translate}} : </label>
                <label *ngIf="selectedProject?.phase === 'DEV'">{{ 'cost' | translate}} : </label>
                {{getCost(selectedProject)}}
                <div class="smiley {{getSmiley(selectedProject?.cost_perf, selectedProject?.phase)}}"
                    *ngIf="selectedProject?.phase === 'EXE'"></div>
            </div>
            <div class="info-data" *ngIf="selectedProject?.phase === 'DEV'">
                <label matTooltipClass="allow-cr" matTooltip="Schedule performance for DEV phase, from Gate FEASIBILITY to FID:
  - Green smiley : Actual duration ≤ Initial duration
  - Orange smiley : Initial duration < Actual duration ≤ Initial duration +20%
  - Red smiley : Actual duration > Initial duration +20%
FID : Final Investment Decision" matTooltipPosition="right">{{ 'fid' | translate }} : </label>

                {{getFID(selectedProject)}}
                <div class="smiley {{getSmiley(selectedProject?.schedule_perf, selectedProject?.phase)}}"></div>
            </div>
            <div class="info-data" *ngIf="selectedProject?.phase === 'EXE'">
                <label matTooltipClass="allow-cr" matTooltip="Schedule performance for EXE phase, from FID to RFSU:
  - Green smiley : Actual duration ≤ Initial duration
  - Orange smiley : Initial duration < Actual duration ≤ Initial duration +10%
  - Red smiley : Actual duration > Initial duration +10%
RFSU : Ready For Start Up" matTooltipPosition="right">{{ 'rfsu' | translate }} : </label>

                {{getRFSU(selectedProject)}}
                <div class="smiley {{getSmiley(selectedProject?.schedule_perf, selectedProject?.phase)}}"></div>
            </div>
            <div class="info-data">
                <label *ngIf="selectedProject?.phase === 'DEV'" matTooltipClass="allow-cr" matTooltip="From NEEDS to FID
FID : Final Investment Decision" matTooltipPosition="right">{{ ('progress_' + selectedProject?.phase) | translate}}
                    : </label>
                <label *ngIf="selectedProject?.phase === 'EXE'" matTooltipClass="allow-cr" matTooltip="From FID to RFSU
RFSU : Ready For Start Up" matTooltipPosition="right">{{ ('progress_' + selectedProject?.phase) | translate}}
                    : </label>
                {{selectedProject?.progress}}%
            </div>
            <div class="info-data"><label>{{ 'last_update' | translate}} : </label>{{getLastUpdate(selectedProject)}}
            </div>
        </div>
        <div class="action" (click)="synthese()">
            <div class="icon-override size-20 eye-icon" matPrefix style="margin-right: 12px"></div>
            <label><a>{{ "see_project" | translate}}</a></label>
        </div>
        <div class="action" (click)="contacts()">
            <div class="icon-override size-20 parrainage-icon" matPrefix style="margin-right: 12px"></div>
            <label><a>{{ "see_contacts" | translate}}</a></label>
        </div>
    </div>
</div>