import { LoaderService } from '@ff/loader';
import {User} from '../../model/user';
import {isNil} from 'lodash';
import { NgMultiLabelTemplateDirective } from '@ng-select/ng-select/lib/ng-templates.directive';

export class Project implements IProject {
  id;
  author;
  created_at;
  updated_at;
  title;

  location;
  affiliate;
  zone;
  operational_direction;
  country;
  business_line;
  jv;
  jv_partners;
  total_share;
  total_role;
  projectnumber_designoffice;
  progress;
  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class Projectdata implements IProject {
  id: number;
  author: User;
  created_at: Date;
  updated_at: Date;
  title: string;
  location: string;
  affiliate: string;
  zone: string;
  operational_direction: string;
  country: string;
  business_line: string;
  jv: boolean;
  jv_partners: string;
  total_share: number;
  total_role: ProjectTotalRole;
  architect_id: number;
  manager_id: number;
  monthly_progresses: [];
  projectnumber_designoffice: number;
  progress: number;

  constructor(fill?: IProject) {
    if (fill instanceof Object) {
      Object.assign(this, fill);

    }
  }
}

export interface IProject {
  id: number;
  author: User;
  created_at;
  updated_at;
  title: string;

  location: string;
  affiliate: string;
  zone;
  operational_direction;
  country;
  business_line;
  jv: boolean;
  jv_partners: string;
  total_share: number;
  total_role: ProjectTotalRole;
  projectnumber_designoffice: number;
  progress: number;
}

export enum ProjectTotalRole {

}

export function globaldata(projects, type = null) {
  return {
    // tslint:disable-next-line: max-line-length
    totalCost: !isNil(projects) && projects.length > 0 ? projects.filter(project => (project.phase !== null && type === null) || type !== null).reduce((a, b) => (a + b.cost), 0) : 0,
    // tslint:disable-next-line: max-line-length
    totalProjects: !isNil(projects) && projects.length > 0 ? projects.filter(project => (project.phase !== null && type === null) || type !== null).length : 0
  };
}

export function loaderShow(_loaderService: LoaderService) {
  if (!_loaderService.isVisible()) {
    _loaderService.show();
  }
}

export function loaderHide(_loaderService: LoaderService) {
  if (_loaderService.isVisible()) {
    _loaderService.hide();
  }
}

export function isFileTypeAllowed(file, result, allowedTypes = []) {
  if (file) {
    return new Promise(
        (resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onloadend = (e: any) => {
            const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
            let header = '';
            for (const byte of arr) {
              header += byte.toString(16);
            }
            result = allowedTypes.includes(header);
            resolve(result);
          };
          fileReader.readAsArrayBuffer(file);
        }
    );
  }
}

export function  stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement('div');
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
}

export function getContactFromField(params, post) {
  const project = params.data;
  return project[post] ? (project[post].first_name + ' ' + project[post].last_name) : '';
}

export function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
